// 仓储管理 仓储费用明细
<template>
    <div class="storagePriceDetail">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <el-button class="lightDeepStyle query" size="mini" @click="openStorageDialog(1)">新增仓储费</el-button>
          <p>
            <span>订单编号 : </span>
            <el-input v-model="storageMoneyData.orderNum" placeholder="请输入订单编号" type="text" size="small" maxlength="40"></el-input>
            <el-button class="lightDeepStyle query" size="mini" @click="getStorageMoneyList()">查询</el-button>
          </p>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="allMenuData" border style="width: 100%;">
              <el-table-column type="index" label="编号" sortable width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="orderNum" label="订单编号" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="cost" label="仓储费用(元)" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="createTime" label="创建日期" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button class="lightDeepStyle" size="mini" @click.stop="openStorageDialog(scope.row)">编辑</el-button>
                    <el-button class="rejectStyle" size="mini" @click.stop="delDetailsData(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
      </el-card>
      <el-dialog class="mouldDialog" :title="addStorageMoneyType === true ? '新增仓储费' : '修改仓储费'" :visible.sync="newMenuVisible">
        <div>
          <p>
            <span>订单编号 : </span>
            <el-input v-model="storageMoneyDetail.orderNum" placeholder="请输入订单编号" type="text" size="small" maxlength="40"></el-input>
          </p>
          <p>
            <span>仓储费用 : </span>
            <el-input v-model="storageMoneyDetail.cost" placeholder="请输入仓储费用" type="text" size="small" maxlength="40"></el-input>元
          </p>
        </div>
        <div class="dialogFooter">
          <el-button class="blueDeepStyle" size="mini" @click="keepDataBtn()">确定</el-button>
          <el-button class="cancelStyle" size="mini" @click="newMenuVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import { storageMoneyListApi, editStorageMoneyApi, delStorageMoneyApi, addStorageMoneyApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      storageMoneyData: {
        page: 1,
        size: 99999,
        orderNum: ''
      },
      // 所有菜单目录
      allMenuData: [],
      // 控制新增修改弹框显示隐藏
      newMenuVisible: false,
      // 获取接口地址
      headers: {
        'token': window.sessionStorage.getItem('token')
      },
      storageMoneyDetail: {
        orderNum: '',
        cost: ''
      },
      // 判断是新增还是修改
      addStorageMoneyType: true
    }
  },
  created() {
    this.getStorageMoneyList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取菜单内容列表
    async getStorageMoneyList() {
      const { data: res } = await storageMoneyListApi(this.storageMoneyData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allMenuData = res.data.page.list
    },
    // 打开银行弹框
    async openStorageDialog(result) {
      if (result === 1) {
        this.addStorageMoneyType = true
        this.storageMoneyDetail = {
          id: '',
          orderNum: '',
          cost: ''
        }
      } else {
        this.addStorageMoneyType = false
        this.storageMoneyDetail = {
          id: result.id,
          orderNum: result.orderNum,
          cost: result.cost
        }
      }
      this.newMenuVisible = true
    },
    // 新增/修改银行卡
    async keepDataBtn() {
      if (this.storageMoneyDetail.orderNum === '') return this.$message.error('订单编号不能为空!')
      if (this.storageMoneyDetail.cost === '') return this.$message.error('仓储费用不能为空!')
      if (this.addStorageMoneyType === true) {
        const { data: res } = await addStorageMoneyApi(this.storageMoneyDetail)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '新增成功!' })
      } else {
        const { data: res } = await editStorageMoneyApi(this.storageMoneyDetail)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '修改成功!' })
      }
      this.newMenuVisible = false
      // 新增输入框填入的值
      this.storageMoneyDetail = {
        id: '',
        orderNum: '',
        cost: ''
      }
      this.getStorageMoneyList()
    },
    // 删除当前银行卡
    delDetailsData(id, index) {
      this.$confirm('是否删除当前仓储信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delStorageMoneyApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getStorageMoneyList()
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 25px;
  width: 100%;
  p {
    position: absolute;
    top: 0;
    right: 145px;
    width: 200px;
    .el-input {
      margin-right: 10px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .priceQuotationDialog {
  .el-dialog {
    height: 600px;
    .el-dialog__body {
      height: 535px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
.priceQuotation>.el-table {
  margin-top: 60px;
}
.priceQuotation .dialogBody {
  height: 450px;
  overflow-y: auto;
}
.priceQuotation .el-dialog .el-table {
  margin-top: 0;
  text-align: center;
  .editBtn {
    background-color: rgba(42,49,59,.7);
    color: #fff;
  }
  .cancelBtn {
    background-color: #ccc;
    color: #fff;
  }
  .cancelBtn:hover {
    border: 1px solid #ccc;
  }
}
.priceQuotation .el-dialog .addText {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  .addValueInput {
    margin: 0 20px;
    margin-right: 10px;
    display: inline-block;
    width: 110px;
  }
  .addNameInput {
    margin: 0 20px;
    display: inline-block;
    width: 152px;
  }
  .addkeyInput {
    margin: 0 14px;
    display: inline-block;
  }
  .keepBtn {
    margin-left: 35px;
    background-color: #aacfcf;
    border: 0;
    color: #fff
  }
}
/deep/ .el-dialog {
  width: 480px!important;
  height: 700px!important;
  .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #000;
    color: #000;
    .el-dialog__title {
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    padding: 12px 16px;
    height: calc(100% - 60px);
    p {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      // height: 32px;
      line-height: 32px;
      >span {
        margin-right: 5px;
        color: #000;
        font-size: 14px;
        min-width: 80px;
        text-align: right;
      }
      .el-radio {
        line-height: 32px;
      }
      .el-textarea__inner {
        min-height: 100px!important;
      }
      .showInputBox {
        position: absolute;
        top: 0;
        left: 68px;
        width: calc(100% - 100px);
        z-index: 9999;
      }
      .el-cascader, .el-tag {
        margin-top: 30px;
        display: inline-block;
        width: calc(100% - 100px);
        .el-input__inner {
          height: 32px;
        }
      }
    }
  }
  .dialogFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 14px 20px;
    height: 56px;
    width: 100%;
    border-top: 1px solid #cccccc;
  }
}
/deep/ .mouldDialog .el-dialog {
  height: 400px!important ;
}
.avatar-uploader{
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultPicture.png') no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}
</style>
