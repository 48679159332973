// 订单管理 订单详情
<template>
    <div class="orderList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <button @click="routerJump()">返回</button>
        <section>
          <h6>基本信息</h6>
          <div><p>订单编号</p><span>{{orderDetail.orderNum}}</span><p>合同编号</p><span>{{orderDetail.contractNum}}</span></div>
          <div><p>创建时间</p><span>{{orderDetail.createTime}}</span><p>采购日期</p><span>{{orderDetail.createTime}}</span></div>
          <div><p>付款状态</p><span>{{orderDetail.pay === 0 ? '未付款': (orderDetail.pay === 1 ? '部分付款': '已付款') }}</span><p>发货天数</p><span>{{orderDetail.sendVal}}天内</span></div>
          <div><p>客户名称</p><span>{{orderDetail.companyName}}</span><p>入库状态</p><span>{{orderDetail.wareinStatus === 0 ? '未入库' : '已入库'}}</span></div>
          <div><p>货物金额</p><span>{{orderDetail.amount}}元</span><p>出库状态</p><span>{{orderDetail.wareoutStatus === 0 ? '未出库' : orderDetail.wareoutStatus === 1 ? '部分出库' : '全部出库'}}</span></div>
          <div><p>服务费</p><span>{{orderDetail.serveMoney}}元</span><p>合同开始时间</p><span>{{orderDetail.beginTime}}</span></div>
          <div><p>付款方式</p><span>{{orderDetail.bondRate === 0 ? '全款' : '保证金' + orderDetail.bondRate + '%'}}</span><p>合同结束时间</p><span>{{orderDetail.endTime}}</span></div>
          <div><p>是否使用第三方库</p><span>{{orderDetail.isThird === 0 ? '否' : '是'}}</span><p></p><span></span></div>
        </section>
        <section class="section2">
          <h6>收货信息</h6>
          <div><p>收货人</p><span>{{orderDetail.receiveName}}</span></div>
          <div><p>收货地址</p><span>{{orderDetail.address}}</span></div>
          <div><p>收货手机</p><span>{{orderDetail.phone}}</span></div>
        </section>
        <section class="section2">
          <h6>货物信息</h6>
          <div><p>图片</p><span>{{orderDetail.pic}}</span></div>
          <div><p>品名</p><span>{{orderDetail.cateName}}</span></div>
          <div v-for="(item, index) in orderDetail.valList" :key="index"><p>{{item.attrName}}</p><span>{{item.attrVal}}</span></div>
          <div><p>数量</p><span>{{orderDetail.num}}{{orderDetail.unit}}</span></div>
          <div><p>单价</p><span>{{orderDetail.price}}元</span></div>
        </section>
        <footer>
          <h6>附件管理</h6>
          <el-table :data="orderDetail.fileList" style="width: 100%"  max-height="250" border>
            <el-table-column show-overflow-tooltip prop="name" label="附件名称" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip label="创建日期" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">{{scope.row.createTime === null || scope.row.createTime === '' || scope.row.createTime === undefined ? '' : (scope.row.createTime).substring(0, 10)}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="附件格式" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.name">{{(scope.row.name).substring((scope.row.name).length - 3, (scope.row.name).length)}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="size" label="附件大小" align="center">
              <template slot-scope="scope">
                <span>{{towNumber(scope.row.size/1024)}}KB</span>
              </template>
            </el-table-column>
            <el-table-column  prop="deliverQuantity" label="操作" align="center">
              <template slot-scope="scope">
                <div>
                  <a @click.stop="preview(scope.row)"><span>下载</span></a>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </footer>
      </el-card>
    </div>
</template>
<script>
import { orderDetailApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderId: this.$route.query.id,
      orderDetail: ''
    }
  },
  created() {
    this.getContractDetail()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单详情
    async getContractDetail() {
      const { data: res } = await orderDetailApi(this.orderId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderDetail = res.data.detail
    },
    // 重置搜索条件
    rejectSearch() {
      this.contractData = {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      }
      this.getContractList()
    },
    // 路由跳转
    routerJump() {
      this.$router.back()
    },
    // 将获取到的值保留4位小数
    towNumber(val) {
      return val.toFixed(4)
    },
    // 预览触发事件
    preview(result) {
      console.log(result, '玛卡巴卡')
      // if (result.attachmentType === 'jpeg' || result.attachmentType === 'png' || result.attachmentType === 'jpg' || result.attachmentType === 'gif') {
      window.open(result.url)
      // } else {
      //   this.$message.error('该文件类型暂不支持预览')
      // }
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  button {
    width: 76px;
    height: 38px;
    border: 1px solid rgba(220, 220, 220, 1);
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.45);
  }
  section {
    width: 100%;
    color: rgba(49, 55, 61, 1);
    h6 {
      margin: 30px 0 13px;
      font-size: 16px;
    }
    div {
      margin-top: -1px;
      display: flex;
      width: 100%;
      min-height: 36px;
      border: 1px solid rgba(230, 232, 235, 1);
      line-height: 36px;
      p {
        flex: 1;
        margin-left: -1px;
        padding-left: 16px;
        background-color: rgba(247, 249, 250, 1);
        border-left: 1px solid rgba(230, 232, 235, 1);
        border-right: 1px solid rgba(230, 232, 235, 1);
      }
      span {
        width: 25%;
        padding-left: 16px;
      }
    }
  }
  .section2 {
    span {
      width: 75%;
    }
  }
  footer {
    width: 100%;
    color: rgba(49, 55, 61, 1);
    h6 {
      margin: 30px 0 13px;
      font-size: 16px;
    }
    /deep/ .el-table th {
      background-color: rgba(247, 249, 250, 1);
    }
  }
}
</style>
