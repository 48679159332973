<template>
  <div class='frameWork'>
    <div class="frameWorkLeft">
      <div class="leftHeader">公司组织架构<i @click="addFrame()">新增部门</i></div>
      <span @click="handleNodeClick({ id: ''})" style="display:block; width: 100%; cursor: pointer; padding-left: 26px;">全部</span>
      <el-tree :data="deptList" :props="props"  @node-click="handleNodeClick">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          {{ data.name }}
          <i @click.stop="editFrame(data)" style="font-size: 16px; margin-left: 50px;" class="iconfont icon-set"></i>
          <i @click.stop="delFrame(data.id)" style="font-size: 16px; margin-left: 5px; color: red;" class="el-icon-delete"></i>
        </span>
      </el-tree>
    </div>
    <div class="frameWorkRight">
      <el-button class="wechartBtn" style="float: right;" @click="getWeChatDept()">同步企业微信</el-button>
      <el-table ref="tableHeight" :height="tableHeight" :data="userList" border style="width: 100%;">
        <el-table-column prop="nickName" label="名称" min-width="80px" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.nickName}}</span>
              <i class="showImp" v-if="scope.row.ifPrincipal === true">负责人</i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="deptName" label="部门" min-width="60px" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.ifPrincipal === true">{{scope.row.deptName}}(负责人)</span>
              <span v-else>{{scope.row.deptName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="roleName" label="角色权限" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="username" label="手机号" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="openDialog(scope.row)">编辑</span>
              <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="delUser(scope.row.id)">删除</span>
              <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="resetPassword(scope.row)">重置</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页功能 -->
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        :page-size="userListData.size"
        :total="total"
        >
      </el-pagination>
    </div>
    <template>
      <el-dialog :title="controlAdd === true ? '新建部门' : '修改部门' " :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog">
        <el-form :model="addDept" :rules="rules" ref="ruleForm" :default-sort="{prop: 'orderNum', order: 'ascending'}">
          <el-form-item prop="name">
            <p class="p_name">部门名称: </p><el-input v-model="addDept.name" placeholder="请输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="type">
            <p>上级部门:</p>
            <!-- <el-cascader :options="deptList" :props="defaultData" clearable v-model="addDept.parentId" placeholder="请选择"></el-cascader> -->
            <el-select style="width: 230px;" v-model="addDept.parentId" placeholder="请选择(不选则为一级部门)" size="mini" clearable>
              <el-option v-for="(item, index) in deptList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="controlAdd === false">
            <p>负责人: </p>
            <el-select style="width: 230px;" v-model="addDept.principalUserId" placeholder="请选择" size="mini" clearable>
              <el-option v-for="(item, index) in userList" :key="index" :label="item.nickName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="controlAdd === true" class="keepBtn" @click="changeDept()">新 建</el-button>
          <el-button v-else class="keepBtn" @click="changeDept()">修 改</el-button>
          <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </template>
    <!-- 编辑角色  -->
    <template>
      <el-dialog title="编辑角色" :visible.sync="editDialog" :close-on-click-modal="false" class="editDialog">
        <el-form :model="editUserData" :rules="rules" ref="ruleForm" :default-sort="{prop: 'orderNum', order: 'ascending'}">
          <el-form-item prop="name">
            <p class="p_name">姓名: </p>
            <el-input v-model="editUserData.nickName" placeholder="请输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <p>手机号: </p>
            <el-input v-model="editUserData.username" placeholder="请输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="type">
            <p>部门:</p>
            <el-cascader :options="deptList" :props="defaultData" clearable v-model="editUserData.deptId" placeholder="请选择"></el-cascader>
            <!-- <el-select style="width: 230px;" v-model="editUserData.deptId" placeholder="请选择(不选则为一级部门)" size="mini" clearable>
              <el-option v-for="(item, index) in deptList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item>
            <p>职位: </p>
            <el-input v-model="editUserData.job" placeholder="请输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <p>角色权限: </p>
            <el-select v-model="editUserData.roleIds" placeholder="全部" size="mini" multiple collapse-tags>
              <el-option
                v-for="(item, index) in roleList"
                :key="index"
                :label="item.roleName"
                :value="item.roleId"
                :value-key="item.roleId">
              </el-option>
            </el-select>
            <!-- <el-select style="width: 230px;" v-model="editUserData.roleIds" placeholder="请选择" size="mini" clearable>
              <el-option v-for="(item, index) in roleList" :key="index" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item>
            <p>新密码: </p>
            <el-input v-model="editUserData.password" placeholder="请输入(不修改原密码则不填)" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <p>确认密码: </p>
            <el-input v-model="editUserData.activePassword" placeholder="请再次输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="keepBtn" @click="editUserInfo()">修 改</el-button>
          <el-button class="defaultStyle" @click="editDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { deptListApi, changeDeptApi, delDeptApi, getDeptUserApi, roleListApi, editUserInfoApi, delUserApi, getWeChatDeptApi } from '@/api'
export default {
  data() {
    return {
      roleList: [],
      editUserData: {
        id: '',
        nickName: '',
        username: '',
        deptId: '',
        job: '',
        roleIds: '',
        password: '',
        activePassword: ''
      },
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'subList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false
      },
      editDialog: false,
      tableHeight: 650,
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      userListData: {
        page: 1,
        size: 10,
        id: ''
      },
      userList: [],
      createDialog: false,
      controlAdd: true,
      addDept: {
        id: '',
        name: '',
        parentId: '',
        principalUserId: ''
      },
      rules: {
        // name: [
        //   { required: true, message: '请输入部门名称', trigger: 'blur' },
        //   { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        // ]
      },
      deptList: [],
      props: {
        children: 'bakDeptEntityList',
        label: 'name'
      }
    }
  },
  created() {
    this.getDeptList()
    this.getRoleList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
      })()
    }
  },
  methods: {
    // 获取企业部门
    async getWeChatDept() {
      const { data: res } = await getWeChatDeptApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('同步成功!')
      this.getRoleList()
    },
    // 重置密码
    resetPassword(result) {
      this.$confirm('是否确认将密码重置为123456', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.editUserData = {
          id: result.id,
          nickName: result.nickName,
          username: result.username,
          deptId: result.deptId,
          job: result.job,
          roleIds: result.roleIds,
          password: '123456',
          activePassword: '123456'
        }
        const { data: res } = await editUserInfoApi(this.editUserData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('重置成功!')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 删除角色
    delUser(id) {
      this.$confirm('是否确认删除该角色?', '删除角色确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delUserApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getDeptUser()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 修改角色信息
    async editUserInfo() {
      let newData = JSON.parse(JSON.stringify(this.editUserData))
      newData.roleIds = (newData.roleIds).join(',')
      if (this.editUserData.password !== this.editUserData.activePassword) return this.$message.error('两次密码不一致!')
      const { data: res } = await editUserInfoApi(newData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改成功!')
      this.editDialog = false
      this.getDeptUser()
    },
    // 获取角色列表
    async getRoleList() {
      const { data: res } = await roleListApi({ page: 1, size: 9999, search: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.roleList = res.data.page.list
    },
    // 打开编辑弹框
    openDialog(result) {
      this.editDialog = true
      this.editUserData = {
        id: result.id,
        nickName: result.nickName,
        username: result.username,
        deptId: result.deptId,
        job: result.job,
        roleIds: ((result.roleIds).split(',')).map(x => Number(x)),
        password: '',
        activePassword: '',
        createTime: result.createTime
      }
      console.log(this.editUserData, '7778888')
      console.log(this.editUserData.roleIds, '6666')
      if (this.editUserData.roleIds[0] === 0) {
        this.editUserData.roleIds = []
      }
    },
    // 点击部门触发
    handleNodeClick(data) {
      this.userListData.id = data.id
      this.userListData.page = 1
      this.getDeptUser()
    },
    // 删除部门
    delFrame(id) {
      this.$confirm('是否确认删除该部门及下级所有部门?', '删除部门确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delDeptApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getDeptList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 新增部门
    addFrame() {
      this.createDialog = true
      this.controlAdd = true
      this.addDept = {
        id: '',
        name: '',
        parentId: '',
        principalUserId: ''
      }
    },
    // 修改部门
    editFrame(value) {
      this.createDialog = true
      this.controlAdd = false
      this.addDept = {
        id: value.id,
        name: value.name,
        parentId: value.parentId,
        principalUserId: value.principalUserId
      }
      if (this.addDept.principalUserId === 0) {
        this.addDept.principalUserId = ''
      }
      if (value.parentId === 0) {
        this.addDept.parentId = ''
      }
      this.userListData.id = value.id
      this.getDeptUser(1)
      this.getDeptList()
    },
    // 根据部门获取用户列表
    async getDeptUser(key) {
      if (key === 1) {
        const { data: res } = await getDeptUserApi({ id: this.userListData.id, page: 1, size: 10 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.userList = res.data.list
      } else {
        const { data: res } = await getDeptUserApi(this.userListData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.userList = res.data.list
        // 获取当前页面
        this.currentPage = res.data.currPage
        // 获取页面总页数
        this.totalNum = res.data.totalPage
        // 获取总条数
        this.total = res.data.totalCount
      }
    },
    // 获取部门列表
    async getDeptList() {
      const { data: res } = await deptListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.deptList = res.data
      let newDeptList = res.data
      newDeptList.forEach((item, index) => {
        if (item.bakDeptEntityList.length !== 0) {
          item.subList = item.bakDeptEntityList
          item.subList.forEach((items, index) => {
            if (items.bakDeptEntityList.length !== 0) {
              items.subList = items.bakDeptEntityList
            }
          })
        }
      })
      console.log(this.deptList)
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.userListData.page = val
      this.currentPage = val
      this.getDeptUser()
    },
    // 新增修改部门
    async changeDept() {
      if (this.addDept.parentId === '') {
        this.addDept.parentId = 0
      }
      const { data: res } = await changeDeptApi(this.addDept)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增成功!')
      this.getDeptList()
      this.createDialog = false
      this.addDept = {
        id: '',
        name: '',
        parentId: '',
        principalUserId: ''
      }
    }
  }
}
</script>
<style scoped lang='less'>
.frameWork {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .frameWorkLeft {
    padding: 0 10px;
    width: 25%;
    height: calc(100vh - 210px);
    background:rgba(255,255,255,1);
    border-radius:3px;
    box-sizing: border-box;
    overflow: auto;
    .leftHeader {
      padding: 0 20px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;
      i {
        margin-left: 140px;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
  .frameWorkRight {
    padding: 0 20px;
    width: 74%;
    height: calc(100vh - 210px);
    background:rgba(255,255,255,1);
    border-radius:3px;
    box-sizing: border-box;
    overflow: auto;
    .wechartBtn {
      margin: 10px 0;
      background-color: #1890FF;
      color: #fff;
    }
    .showImp {
      margin-left: 5px;
      display: inline-block;
      width: 50px;
      height: 20px;
      border: 1px solid #ccc;
    }
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 180px!important;
  width: 472px;
  height: 334px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 220px;
    width: 972px;
    .el-select {
      height: 40px;
      .el-input__inner {
        height: 40px;
      }
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
/deep/ .editDialog .el-dialog {
  margin-top: 130px!important;
  width: 472px;
  height: 584px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 470px;
    width: 972px;
    .el-select {
      height: 40px;
      .el-input__inner {
        height: 40px;
      }
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
