// 库存管理
<template>
  <div class="outManage">
    <nav>
      <h5>企业审核</h5>
      <div>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(2, '')"><i class="el-icon-plus"></i>产品入库</el-button> -->
      </div>
    </nav>
    <main>
       <div class="top">
        <div class="left">
            <h6>企业信息</h6>
            <p>企业名称：<span>{{storeHouseListData.name}}</span></p>
            <p>统一社会信用代码：<span>{{storeHouseListData.businessNo}}</span></p>
            <p>住所：<span>{{storeHouseListData.address}}</span></p>
            <p>法人代表：<span>{{storeHouseListData.legalPerson}}</span></p>
            <p>认证日期<span>{{storeHouseListData.updateTime}}</span></p>
        </div>
        <div class="right">
            <span>营业执照:</span>
            <el-image
            style="width: 100px; height: 100px"
            :src="url"
            :preview-src-list="srcList"
            >
          </el-image>
            <!-- <img :src="storeHouseListData.businessLicense" alt="" width="190px" height="120px"> -->
        </div>
       </div>
       <div class="down">
        <h6>认证记录</h6>
        <div v-for="(item, index) in storeHouseListData.logEntityList" :key="index" class="oneBox">
            <p>审核时间：<span>{{item.auditTime}}</span></p>
            <p>状态：<span>{{item.auditStatus===0?'重新审核':item.auditStatus===1?'审核通过':item.auditStatus===3?'审核不通过':'其他'}}</span></p>
            <p>审核人：<span>{{ item.userName }}</span></p>
            <p>备注：<span>{{item.remark}}</span></p>
            <p>认证提交时间：<span>{{item.createTime}}</span></p>
        </div>
       </div>
    </main>
  </div>
</template>
<script>
import { auditDetailApi } from '@/api'
export default {
  name: 'outManage',
  data() {
    return {
      url: '',
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      chooseTime: '',
      // 获取仓库列表参数
      storeHouseListData: {},
      // 列表动态高度
      tableHeight: '',
      srcList: []
    }
  },
  created() {
    this.ids = this.$route.query.id
    this.getWarehouseList()
  },
  mounted() {
  },
  methods: {
    // 获取库存列表
    async getWarehouseList() {
      const { data: res } = await auditDetailApi(this.ids)
      this.storeHouseListData = res.data
      this.url = res.data.businessLicense
      this.srcList.push(res.data.businessLicense)
    }
  }
}
</script>
<style scoped lang='less'>
.outManage {
    height: 100%;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  nav {
    padding: 20px;
    display: flex;
    // height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 25px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 59px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  main {
    // flex: 1;
    display: flex;
    flex-direction: column;
    h6{
            font-size: 17px;
            margin: 0px;
            line-height: 55px;
            font-weight: normal;
        }
    .top{
        width: 100%;
        display: flex;
        // justify-content: space-around;
        .left{
            width: 50%;
            padding-left: 20px;
            p{
                line-height: 45px;
                font-size: 14px;
                padding-left: 20px;
                span{
                    display: inline-block;
                    padding-left: 10px;
                }
            }
        }
        .right{
            font-size: 14px;
            padding-top: 40px;
            padding-left: 20px;
            width: 50%;
            display: flex;
            span{
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    .down{
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        .oneBox{
            padding-left: 20px;
            display: flex;
            flex-wrap: wrap;
            p{
                width: 23%;
                line-height: 45px;
                font-size: 14px;
                padding-left: 20px;
                span{
                    display: inline-block;
                    // padding-left: 10px;
                }
            }
        }
    }
  }
}
</style>
