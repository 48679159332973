// 用户管理 标签用户详情
<template>
    <div class="labelUser">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <div class="tableBox1"><p>企业名称 : </p><span>{{labelUserDetail.name}}</span><p>入驻时间 : </p><span>{{labelUserDetail.createTime}}</span><p></p>
          <div class="imgBox">
            <el-image :src="labelUserDetail.logo"></el-image>
            <el-image :src="labelUserDetail.licensePic"></el-image>
          </div>
        </div>
        <div class="tableBox2"><p>统一信用代码 : </p><span>{{labelUserDetail.regNumber}}</span><p></p></div>
        <div class="tableBox2"><p>企业地区 : </p><span>{{labelUserDetail.provinceName + labelUserDetail.cityName + labelUserDetail.areaName + labelUserDetail.address}}</span><p></p></div>
        <div class="tableBox1"><p>行业类型 : </p><span>{{labelUserDetail.industryName}}</span><p>经营模式 : </p><span>{{labelUserDetail.modesName}}</span><p></p></div>
        <div class="tableBox1"><p>主营产品 : </p><span>{{labelUserDetail.mainProducts}}</span><p>企业规模 : </p><span>{{labelUserDetail.scaleDescribe}}</span><p></p></div>
        <div class="tableBox1"><p>注册资金 : </p><span>{{labelUserDetail.capital}}万</span><p>年营业额 : </p><span>{{labelUserDetail.turnoverDescribe}}</span><p></p></div>
        <div class="tableBox1"><p>联系人 : </p><span>{{labelUserDetail.contact}}</span><p>联系电话 : </p><span>{{labelUserDetail.mobile}}</span><p></p></div>
        <div class="tableBox3"><p>企业简介 : </p><span>{{labelUserDetail.companyProfile}}</span></div>
        <div class="tableBox3"><p>经营属性 : </p><span><i v-for="(item, index) in labelUserDetail.businessAttributeString" :key="index">{{item}}</i></span></div>
      </el-card>
    </div>
</template>
<script>
import { } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight2: '',
      labelUserDetail: JSON.parse(this.$route.query.result)
    }
  },
  created() {
    console.log(this.labelUserDetail, '996')
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
  }
}
</script>
<style scoped lang='less'>
.el-card {
  display: flex;
  justify-content: center;
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  .tableBox1, .tableBox2, .tableBox3 {
    display: flex;
    width: 1000px;
    height: 52px;
    border-bottom: 1px solid #ECECEC;
    line-height: 52px;
    color: #333333;
    p {
      padding: 0 20px;
      width: 20%;
      border-left: 1px solid #ECECEC;
      border-right: 1px solid #ECECEC;
      background-color: #F9F9F9;
      box-sizing: border-box;
      text-align: right;
    }
    span {
      padding: 0 20px;
      width: 20%;
      box-sizing: border-box;
      text-align: left;
    }
  }
  .tableBox1:nth-child(1) {
    position: relative;
    border-top: 1px solid #ECECEC;
    .imgBox {
      position: absolute;
      top: 0;
      right: 1px;
      display: flex;
      flex-direction: column;
      width: 198px;
      height: 370px;
      background-color: #FFFFFF;
      .el-image:nth-child(1) {
        height: 158px;
        border-bottom: 1px solid #ECECEC;
      }
      .el-image:nth-child(2) {
        flex: 1;
      }
    }
  }
  .tableBox2, .tableBox3 {
    p {
      width: 20%;
    }
    span {
      width: 60%;
    }
  }
  .tableBox3 {
    span {
      width: 80%;
      border-right: 1px solid #ECECEC;
      i {
        margin-right: 11px;
        padding: 2px 7px;
        display: inline-block;
        height: 20px;
        background-color: #3AABC2;
        border-radius: 2px 2px 2px 2px;
        color: #FFFFFF;
        line-height: 20px;
      }
    }
  }
}
</style>
