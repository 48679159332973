// 系统配置 消息配置
<template>
    <div class="messageConfigure">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <!-- 操作按钮 -->
        <div v-if="activeName === 'first'" class="showBtn">
          <el-button class="blueStyle" size="mini" @click="getMessageList()">查询</el-button>
          <el-button class="cancelStyle" size="mini" @click="resetSearch()">重置</el-button>
          <el-button class="lightDeepStyle" size="mini" @click="openMessageDialog()">添加消息</el-button>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="消息列表" name="first">
            <header>
              <p>
                <span>推送标题/推送内容 : </span>
                <el-input v-model="searchData.content" placeholder="请输入推送标题/推送内容" type="text" size="small"></el-input>
              </p>
              <p>
                <span>推送用户 : </span>
                <el-input v-model="searchData.username" placeholder="请输入用户手机号码" type="text" size="small"></el-input>
              </p>
              <p>
                <span>推送公司 : </span>
                <el-input v-model="searchData.companyName" placeholder="请输入用户公司名称" type="text" size="small"></el-input>
              </p>
              <p>
                <span>推送方式 : </span>
                <el-select style="width: 207px;" v-model="searchData.pushType" placeholder="请选择推送方式" size="mini" clearable>
                  <el-option label="单推" value="1"></el-option>
                  <el-option label="全部" value="2"></el-option>
                </el-select>
              </p>
              <p>
                <span>推送时间: </span>
                <el-date-picker
                  v-model="searchData.startTime"
                  type="date"
                  placeholder="请选择"
                  size="mini"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </p>
              <p>
                <span>至</span>
                <el-date-picker
                  v-model="searchData.endTime"
                  type="date"
                  placeholder="请选择"
                  size="mini"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </p>
            </header>
            <!-- 菜单管理表格区 -->
            <el-row>
                <el-table ref="tableHeight" :height="tableHeight" :data="messageList" border style="width: 100%;">
                  <el-table-column type="index" label="编号" width="50" align="center"></el-table-column>
                  <el-table-column label="通知分类" width="80px" align="center">
                    <template>
                      <div>
                        <p>系统通知</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="title" label="推送标题" min-width="100px" show-overflow-tooltip align="center"></el-table-column>
                  <el-table-column prop="content" label="推送内容" min-width="100px" show-overflow-tooltip align="center"></el-table-column>
                  <el-table-column prop="companyName" label="推送用户" min-width="150px" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <div>
                        <p v-if="scope.row.pushType == '1'">{{scope.row.companyName + ',' + scope.row.username}}</p>
                        <p v-else>全部用户</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="isAuto" label="发送类型" min-width="80px" align="center">
                    <template slot-scope="scope">
                      <div>
                        <p v-if="scope.row.isAuto === 1">手动</p>
                        <p v-else>自动</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="pushPlatform" label="推送平台" min-width="80px" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <div>
                        <p v-if="scope.row.pushPlatform == '1'">站内信</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="pushChannel" label="推送渠道" min-width="80px" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <div>
                        <p v-if="scope.row.pushChannel == '1'">小程序</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="pushType" label="推送方式" min-width="80px" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <div>
                        <p v-if="scope.row.pushType == '1'">单推</p>
                        <p v-else>全部</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="createTime" label="推送时间" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
                  <el-table-column label="操作" width="160px" align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-button class="lightDeepStyle" size="mini" @click.stop="openEditMessageDialog(scope.row)">修改</el-button>
                        <el-button class="rejectStyle" size="mini" @click.stop="delSystemMessage(scope.row.id)">删除</el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
            </el-row>
            <el-row class="layout_row">
              <el-pagination
                layout="total, prev, pager, next, jumper"
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                :page-size="searchData.size"
                :total="total"
                >
              </el-pagination>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="模板通知" name="second">
            <!-- 菜单管理表格区 -->
            <el-row>
              <el-table ref="tableHeight" :height="tableHeight" :data="noticeTempList" border style="width: 100%;">
                <el-table-column type="index" label="编号" width="50" align="center"></el-table-column>
                <el-table-column prop="remark" label="触发条件" min-width="180px" align="center"></el-table-column>
                <el-table-column label="通知分类" min-width="40px" show-overflow-tooltip align="center">
                  <template>
                    <div>系统通知</div>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="模板标题" min-width="50px" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="content" label="模板内容" min-width="200px" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="jumpUrl" label="跳转链接" min-width="80px" align="center"></el-table-column>
                <el-table-column prop="pushPlatform" label="推送方式" min-width="40px" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="pushPlatform" label="状态" min-width="50px" show-overflow-tooltip align="center">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isOpen"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="rgba(51, 158, 191, 1)"
                      inactive-color="#eee"
                      @input="controlSwitch(scope.row)">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" align="center">
                  <template slot-scope="scope">
                    <div>
                      <span style="cursor: pointer; color: rgba(51, 158, 191, 1); font-weight: 500" @click.stop="openEditMouldDialog(scope.row)">编辑</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <el-dialog class="messageDialog" title="添加消息" :visible.sync="addMessageDialog">
        <div>
          <p>
            <span>推送标题 : </span>
            <el-input v-model="addMessageData.title" placeholder="请输入标题" type="text" size="small" show-word-limit maxlength="20"></el-input>
          </p>
          <p>
            <span>推送内容 : </span>
            <el-input v-model="addMessageData.content" placeholder="请输入内容" type="textarea" size="small" show-word-limit maxlength="50"></el-input>
          </p>
          <p>
            <span>推送时间 : 立即推送</span>
          </p>
          <p>
            <span>推送平台 : 站内信</span>
          </p>
          <p>
            <span>推送渠道 : 小程序</span>
          </p>
          <p>
            <span>推送方式 : </span>
            <el-radio v-model="addMessageData.pushType" label="0">全推</el-radio>
            <el-radio v-model="addMessageData.pushType" label="1">单推</el-radio>
          </p>
          <p v-if="addMessageData.pushType === '1'">
            <span>推送用户 : </span>
            <el-input class="showInputBox" v-model="searchPhone" placeholder="请输入手机号选择推送用户" type="text" size="small" show-word-limit maxlength="11" @focus="openCascader()" @input="userSearch()"></el-input>
            <el-cascader
              ref="elcascader"
              v-model="value"
              :options="userList"
              @change="handleChange">
            </el-cascader>
          </p>
        </div>
        <div class="dialogFooter">
          <el-button class="blueDeepStyle" size="mini" @click="addSystemMessage()">确定</el-button>
          <el-button class="cancelStyle" size="mini" @click="cancelMessageDialog()">取消</el-button>
        </div>
      </el-dialog>
      <el-dialog class="messageDialog" title="修改消息" :visible.sync="editMessageDialog">
        <div>
          <p>
            <span>推送标题 : </span>
            <el-input v-model="editMessageData.title" placeholder="请输入标题" type="text" size="small" show-word-limit maxlength="20"></el-input>
          </p>
          <p>
            <span>推送内容 : </span>
            <el-input v-model="editMessageData.content" placeholder="请输入内容" type="textarea" size="small" show-word-limit maxlength="50"></el-input>
          </p>
          <p>
            <span>推送时间 : 立即推送</span>
          </p>
          <p>
            <span>推送平台 : 站内信</span>
          </p>
          <p>
            <span>推送渠道 : 小程序</span>
          </p>
          <p>
            <span v-if="editMessageData.pushType == '0'">推送方式 : 全推</span>
            <span v-if="editMessageData.pushType == '1'">推送方式 : 单推</span>
          </p>
        </div>
        <div class="dialogFooter">
          <el-button class="blueDeepStyle" size="mini" @click="editSystemMessage()">确定</el-button>
          <el-button class="cancelStyle" size="mini" @click="cancelMessageDialog()">取消</el-button>
        </div>
      </el-dialog>
      <el-dialog class="mouldDialog" title="编辑模板" :visible.sync="editMouldDialog">
        <div>
          <p>
            <span>模板标题 : </span>
            <el-input v-model="noticeTempData.title" placeholder="请输入标题" type="text" size="small" show-word-limit maxlength="20"></el-input>
          </p>
          <p>
            <span>模板内容 : </span>
            <el-input v-model="noticeTempData.content" placeholder="请输入内容" type="textarea" size="small" show-word-limit maxlength="80"></el-input>
          </p>
        </div>
        <div class="dialogFooter">
          <el-button class="blueDeepStyle" size="mini" @click="editNoticeTmplate()">确定</el-button>
          <el-button class="cancelStyle" size="mini" @click="editMouldDialog = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import { systemMessageListApi, addSystemMessageApi, addMessageUserSearchApi, editSystemMessageApi, delSystemMessageApi, noticeTemplateApi, editNoticeTmplateApi } from '@/api'
export default {
  data() {
    return {
      // ---------------------------模板通知
      noticeTempList: [],
      noticeTempData: {
        id: '',
        title: '',
        content: '',
        isOpen: 0
      },
      // ---------------------------消息列表
      value: [],
      // 传入的手机号
      searchPhone: '',
      userList: [],
      // 添加消息传参
      addMessageData: {
        userId: '',
        companyId: '',
        title: '',
        content: '',
        pushPlatform: '1',
        pushChannel: '1',
        isAuto: '1',
        pushType: '0'
      },
      // 修改消息传参
      editMessageData: {
        id: '',
        title: '',
        content: '',
        pushType: '0'
      },
      // 当前选中tab
      activeName: 'first',
      // 搜索传参
      searchData: {
        page: 1,
        size: 7,
        username: '',
        companyName: '',
        content: '',
        startTime: '',
        endTime: '',
        pushType: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      messageList: [],
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 新增消息弹框
      addMessageDialog: false,
      // 修改消息弹框
      editMessageDialog: false,
      // 修改模板内容弹框
      editMouldDialog: false
    }
  },
  created() {
    this.getMessageList()
    this.noticeTemplate()
    this.userSearch()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 500
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 500
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // -------------------------------------通知模板
    // 打开模板
    openEditMouldDialog(result) {
      this.editMouldDialog = true
      this.noticeTempData = {
        id: result.id,
        title: result.title,
        content: result.content,
        isOpen: result.isOpen
      }
    },
    // 修改状态
    controlSwitch(result) {
      this.noticeTempData = {
        id: result.id,
        title: result.title,
        content: result.content,
        isOpen: result.isOpen
      }
      this.editNoticeTmplate(1)
    },
    // 通知模板列表
    async noticeTemplate() {
      const { data: res } = await noticeTemplateApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.noticeTempList = res.data.list
    },
    // 修改通知模板
    async editNoticeTmplate(key) {
      const { data: res } = await editNoticeTmplateApi(this.noticeTempData)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (key === 1) {
        this.$message.success('状态更改成功!')
      } else {
        this.$message.success('修改成功!')
      }
      this.editMouldDialog = false
      this.noticeTemplate()
    },
    // --------------------------------------消息列表
    // 删除系统消息
    delSystemMessage(id) {
      this.$confirm('是否确认删除当前消息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delSystemMessageApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getMessageList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消'
        })
      })
    },
    // 打开修改消息弹框
    openEditMessageDialog(result) {
      this.editMessageDialog = true
      this.editMessageData = {
        id: result.id,
        title: result.title,
        content: result.content,
        pushType: result.pushType
      }
    },
    // 确认修改消息
    async editSystemMessage() {
      if (this.editMessageData.title === '') return this.$message.error('推送标题不能为空!')
      if (this.editMessageData.content === '') return this.$message.error('推送内容不能为空!')
      const { data: res } = await editSystemMessageApi(this.editMessageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改成功!')
      this.editMessageDialog = false
      this.getMessageList()
    },
    // 取消弹框
    cancelMessageDialog() {
      this.addMessageDialog = false
      this.editMessageDialog = false
    },
    // 展示搜索内容
    openCascader() {
      let that = this
      setTimeout(() => {
        that.$refs.elcascader.dropDownVisible = true
      }, 100)
    },
    handleChange(value) {
      this.addMessageData.userId = value[0]
      this.addMessageData.companyId = value[1]
      this.$message.success('选择成功!')
    },
    // 用户搜索
    async userSearch() {
      const { data: res } = await addMessageUserSearchApi(this.searchPhone)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userList = res.data.list
      this.userList.forEach((item, index) => {
        item.children = item.companyList
        item.value = item.userId
        item.label = item.username
        item.children.forEach((item2, index2) => {
          item2.value = item2.companyId
          item2.label = item2.companyName
        })
      })
    },
    // 确认新增消息
    async addSystemMessage() {
      if (this.addMessageData.title === '') return this.$message.error('推送标题不能为空!')
      if (this.addMessageData.content === '') return this.$message.error('推送内容不能为空!')
      const { data: res } = await addSystemMessageApi(this.addMessageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增成功!')
      this.addMessageDialog = false
      this.getMessageList()
    },
    // 打开消息弹框
    openMessageDialog() {
      this.addMessageDialog = true
      // 添加消息传参
      this.addMessageData = {
        userId: '',
        companyId: '',
        title: '',
        content: '',
        pushPlatform: '1',
        pushChannel: '1',
        isAuto: '1',
        pushType: '0'
      }
    },
    // 重置消息
    resetSearch() {
      // 搜索传参
      this.searchData = {
        page: 1,
        size: 10,
        username: '',
        companyName: '',
        content: '',
        startTime: '',
        endTime: '',
        pushType: ''
      }
      this.getMessageList()
    },
    // 获取消息列表
    async getMessageList() {
      if (this.searchData.startTime === null) this.searchData.startTime = ''
      if (this.searchData.endTime === null) this.searchData.endTime = ''
      const { data: res } = await systemMessageListApi(this.searchData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.messageList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.searchData.page = val
      this.currentPage = val
      this.getMessageList()
    }
  }
}
</script>
<style scoped lang='less'>
.messageConfigure {
  position: relative;
  header {
    display: flex;
    flex-wrap: wrap;
    p {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-around;
      span {
        margin: 0 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
  .showBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 230px;
    z-index: 999;
    .el-bottom {
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog {
  width: 480px!important;
  height: 700px!important;
  .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #000;
    color: #000;
    .el-dialog__title {
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    padding: 12px 16px;
    height: calc(100% - 60px);
    p {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      // height: 32px;
      line-height: 32px;
      >span {
        margin-right: 5px;
        color: #000;
        font-size: 14px;
      }
      .el-radio {
        line-height: 32px;
      }
      .el-textarea__inner {
        min-height: 100px!important;
      }
      .showInputBox {
        position: absolute;
        top: 0;
        left: 68px;
        width: calc(100% - 100px);
        z-index: 9999;
      }
      .el-cascader, .el-tag {
        margin-top: 30px;
        display: inline-block;
        width: calc(100% - 100px);
        .el-input__inner {
          height: 32px;
        }
      }
    }
  }
  .dialogFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 14px 20px;
    height: 56px;
    width: 100%;
    border-top: 1px solid #cccccc;
  }
}
/deep/ .mouldDialog .el-dialog {
  height: 400px!important ;
}
// ----------------------------------
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 50px;
  .el-button {
    position: absolute;
    top: 0;
    left: 0;
  }
  >div {
    position: absolute;
    right: 70px;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
  >div:nth-child(2) {
    right: 300px;
  }
  .query {
    position: absolute;
    left: calc(100% - 60px);
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table {
  margin-top: 0;
}
</style>
