// 岗位权限
<template>
  <!-- 算账 -->
  <div class="roleManageEdit">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/settingVip' }">会员配置</el-breadcrumb-item>
      <el-breadcrumb-item  class="nowPage" style="color: rgba(18, 62, 131, 1)">{{$route.query.id===''?'新增会员等级':'配置会员等级'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="roleManageEditContent">
      <section>
        <div class="sectionHeader">
          <h5>{{$route.query.id===''?'新增会员等级':'配置会员等级'}}</h5>
          <ul>
            <li><span><i>*</i>会员等级名称 : </span><el-input type="text" v-model="roleData.name" placeholder="请输入"></el-input></li>
            <li><span><i>*</i>会员价格 : </span><el-input type="text" v-model="roleData.price" placeholder="请输入"></el-input></li>
            <li><span><i>*</i>会员有效期 : </span><el-input type="text" v-model="roleData.expDay" placeholder="请输入（天）"></el-input></li>
            <li><span><i>*</i>用户人数 : </span><el-input type="text" v-model="roleData.userCount" placeholder="请输入"></el-input></li>
            <li><span>折扣(%) : </span><el-input type="text" v-model="roleData.rebate" placeholder="请输入1到99" onkeyup="value=value.replace(/^(\d{0,2}).*$/,'$1')" @input="rebateFn">
            </el-input></li>
            <li><span>折扣后价格 : </span><el-input type="text" v-model="roleData.rebatePrice" placeholder="请输入"></el-input></li>
            <li><span>续费价格 : </span><el-input type="text" v-model="roleData.renewPrice" placeholder="请输入"></el-input></li>
          </ul>
        </div>
        <div style="margin: 25px;">
          <el-button @click="handleSubmit" type="primary">提交</el-button>
        </div>
      </section>
      <main>
    <table class="tableMenu">
        <thead>
          <tr>
            <th>一级菜单</th>
            <th>二级菜单</th>
            <th>三级菜单</th>
          </tr>
        </thead>
        <tbody>
          <h5>{{'采购端&供应端'}}</h5>
          <tr v-for="firstMenu in allMenuData3" :key="firstMenu.menuId">
            <td>  <el-checkbox v-model="firstMenu.isTrue" @change="handleFirstMenuChange(firstMenu)">
                    {{ firstMenu.name }}
                  </el-checkbox></td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId" class="secoundLi">
                  <el-checkbox v-model="secondMenu.isTrue" @change="handleSecondMenuChange(firstMenu, secondMenu)">
                    {{ secondMenu.name }}
                  </el-checkbox>
                </li>
              </ul>
            </td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId">
                  <ul class="custom-ul2">
                    <li v-for="thirdMenu in secondMenu.children" :key="thirdMenu.menuId">
                      <el-checkbox v-model="thirdMenu.isTrue">
                        {{ thirdMenu.name }}
                      </el-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <h5>采购端</h5>
          <tr v-for="firstMenu in allMenuData1" :key="firstMenu.menuId">
            <td>  <el-checkbox v-model="firstMenu.isTrue" @change="handleFirstMenuChange(firstMenu)">
                    {{ firstMenu.name }}
                  </el-checkbox></td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId" class="secoundLi">
                  <el-checkbox v-model="secondMenu.isTrue" @change="handleSecondMenuChange(firstMenu, secondMenu)">
                    {{ secondMenu.name }}
                  </el-checkbox>
                </li>
              </ul>
            </td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId">
                  <ul class="custom-ul2">
                    <li v-for="thirdMenu in secondMenu.children" :key="thirdMenu.menuId">
                      <el-checkbox v-model="thirdMenu.isTrue">
                        {{ thirdMenu.name }}
                      </el-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <h5>供应端</h5>
          <tr v-for="firstMenu in allMenuData2" :key="firstMenu.menuId">
            <td>  <el-checkbox v-model="firstMenu.isTrue" @change="handleFirstMenuChange(firstMenu)">
                    {{ firstMenu.name }}
                  </el-checkbox></td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId" class="secoundLi">
                  <el-checkbox v-model="secondMenu.isTrue" @change="handleSecondMenuChange(firstMenu, secondMenu)">
                    {{ secondMenu.name }}
                  </el-checkbox>
                </li>
              </ul>
            </td>
            <td>
              <ul class="custom-ul">
                <li v-for="secondMenu in firstMenu.children" :key="secondMenu.menuId">
                  <ul class="custom-ul2">
                    <li v-for="thirdMenu in secondMenu.children" :key="thirdMenu.menuId">
                      <el-checkbox v-model="thirdMenu.isTrue">
                        {{ thirdMenu.name }}
                      </el-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      </main>
    </div>
  </div>
</template>
<script>
import { getVipMenuApi, getRoleListApi, detailVip, updateVipApi, addRoleInfoApi } from '@/api'
function editData(array) {
  const updatedArray = array.map(item => {
    const updatedItem = { ...item }
    if (updatedItem.list && updatedItem.list.length > 0) {
      updatedItem.children = editData(updatedItem.list)
    }
    delete updatedItem.list
    return updatedItem
  })
  return updatedArray.sort((a, b) => a.orderNum - b.orderNum)
}
export default {
  data() {
    return {
      twoDigitPattern: /^(?:[1-9]|[1-9][0-9])$/,
      selectedMenuIds: [],
      allMenuIds: [],
      // 选择的菜单id
      checkedTree: [[], [], [], [], [], [], [], []],
      // -----------
      // 列表动态高度
      tableHeight: '',
      // 存储上级岗位的数组
      superArr: [],
      // 上级岗位列表传参
      roleManageData: {
      // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        // 合同名称
        search: ''
      },
      // 数据权限列表
      otherList: [],
      // 所有菜单
      allMenuData1: [],
      allMenuData2: [],
      allMenuData3: [],
      // ---------------------------------------------
      roleData: {
      // 当前角色id
        // 备注
        expDay: '',
        // 上级岗位id
        parentId: null,
        // 角色名称
        name: '',
        // 部门名称
        rebate: '',
        // 权限菜单
        menuIds: [],
        // 存储权限菜单
        isOpen: '',
        renewPrice: '',
        userCount: '',
        price: '',
        rebatePrice: ''
      },
      // 初始开启的开关
      btnValueA: [],
      // 初始未开启的开关
      btnValueB: 0,
      maxItemHeights: {}, // 记录一级菜单项和对应的最大高度
      selectedSecondMenus: []
    }
  },
  created() {
    if (this.$route.query.id !== '') {
      this.getDetailData()
      this.fetchMenuData()
      // this.rebateFn()
    } else {
      this.fetchData()
    }
    this.getRoleList()
    // 等待数据请求完成
  },
  computed: {
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
      })()
    }
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.setFirstLevelHeights) // 移除窗口大小变化的监听
  // },

  methods: {
    // 三级联动
    handleFirstMenuChange(firstMenu) {
      // When the first menu is changed, update the second and third menus accordingly
      if (firstMenu.isTrue) {
        firstMenu.children.forEach((secondMenu) => {
          secondMenu.isTrue = true
          if (secondMenu.children && secondMenu.children.length > 0) {
            secondMenu.children.forEach((thirdMenu) => {
              thirdMenu.isTrue = true
            })
          }
        })
      } else {
        firstMenu.children.forEach((secondMenu) => {
          secondMenu.isTrue = false
          if (secondMenu.children && secondMenu.children.length > 0) {
            secondMenu.children.forEach((thirdMenu) => {
              thirdMenu.isTrue = false
            })
          }
        })
      }
    },
    handleSecondMenuChange(firstMenu, secondMenu) {
      if (secondMenu.isTrue && !firstMenu.isTrue) {
        firstMenu.isTrue = true
      }
      if (secondMenu.children && secondMenu.children.length > 0) {
        secondMenu.children.forEach((thirdMenu) => {
          thirdMenu.isTrue = secondMenu.isTrue
        })
      }
    },
    rebateFn() {
      let rebatePrices = null
      let convertedValue = this.roleData.rebate ? (parseInt(this.roleData.rebate) / 100).toFixed(2) : ''
      rebatePrices = convertedValue ? this.roleData.price * convertedValue : this.roleData.price
      this.roleData.rebatePrice = parseInt(rebatePrices)
    },
    // 提交
    async handleSubmit() {
      const arr = []
      if (this.$route.query.id === '') {
        // 1
        this.allMenuData1.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        // 2
        this.allMenuData2.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        // 3
        this.allMenuData3.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        this.roleData.menuIds = arr
        const { data: res } = await addRoleInfoApi(this.roleData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '新增会员等级成功!' })
      } else {
        // 1
        this.allMenuData1.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        // 2
        this.allMenuData2.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        // 3
        this.allMenuData3.forEach((firstMenu) => {
          if (firstMenu.isTrue) {
            arr.push(firstMenu.menuId)
            firstMenu.children.forEach((secondMenu) => {
              if (secondMenu.isTrue) {
                arr.push(secondMenu.menuId)
                if (secondMenu.children && secondMenu.children.length > 0) {
                  secondMenu.children.forEach((thirdMenu) => {
                    if (thirdMenu.isTrue) {
                      arr.push(thirdMenu.menuId)
                    }
                  })
                }
              }
            })
          }
        })
        this.roleData.menuIds = arr
        const { data: res } = await updateVipApi(this.roleData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '修改会员等级成功!' })
      }
      this.$router.back()
    },
    // 获取详情数据
    async getDetailData() {
      const { data: res } = await detailVip(this.$route.query.id)
      this.roleData = res.data.detail
      this.allMenuIds = res.data.detail.menuIds
      const a = +this.roleData.rebate ? (parseInt(this.roleData.rebate) / 100).toFixed(2) : ''
      let a2 = res.data.detail.price * a
      this.roleData.rebatePrice = parseInt(a2)
    },
    // 复选框数据回填
    async fetchMenuData() {
      const { data: res } = await getVipMenuApi()
      let data1 = editData(res.data.buyList[0].list)
      let data2 = editData(res.data.sellList[0].list)
      let data3 = editData(res.data.commonList[0].list)
      const { data: res2 } = await detailVip(this.$route.query.id)
      this.allMenuIds = res2.data.detail.menuIds
      // 1
      data1.forEach((firstMenu) => {
        firstMenu.isTrue = this.allMenuIds.includes(firstMenu.menuId)
        firstMenu.children.forEach((secondMenu) => {
          secondMenu.isTrue = this.allMenuIds.includes(secondMenu.menuId)
          if (secondMenu.children && secondMenu.children.length > 0) {
            secondMenu.children.forEach((thirdMenu) => {
              thirdMenu.isTrue = this.allMenuIds.includes(thirdMenu.menuId)
            })
          }
        })
      })
      // 2
      data2.forEach((firstMenu) => {
        firstMenu.isTrue = this.allMenuIds.includes(firstMenu.menuId)
        firstMenu.children.forEach((secondMenu) => {
          secondMenu.isTrue = this.allMenuIds.includes(secondMenu.menuId)
          if (secondMenu.children && secondMenu.children.length > 0) {
            secondMenu.children.forEach((thirdMenu) => {
              thirdMenu.isTrue = this.allMenuIds.includes(thirdMenu.menuId)
            })
          }
        })
      })
      // 3
      data3.forEach((firstMenu) => {
        firstMenu.isTrue = this.allMenuIds.includes(firstMenu.menuId)
        firstMenu.children.forEach((secondMenu) => {
          secondMenu.isTrue = this.allMenuIds.includes(secondMenu.menuId)
          if (secondMenu.children && secondMenu.children.length > 0) {
            secondMenu.children.forEach((thirdMenu) => {
              thirdMenu.isTrue = this.allMenuIds.includes(thirdMenu.menuId)
            })
          }
        })
      })
      this.allMenuData1 = data1
      this.allMenuData2 = data2
      this.allMenuData3 = data3
      console.log(this.allMenuData1, 'gggg1')
    },
    // --------------------------------------------------------------------------------------------
    routerJump() {
      this.$router.back()
    },
    // 获取所有岗位信息
    async getRoleList() {
      const { data: res } = await getRoleListApi(this.roleManageData)
      // 获取表单数据
      res.data.page.list.forEach(item => {
        let roleObj = { roleId: item.roleId, roleName: item.roleName }
        this.superArr.push(roleObj)
      })
    },
    // 获取所有菜单
    async fetchData() {
      const { data: res } = await getVipMenuApi()
      let data1 = res.data.buyList[0].list
      let data2 = res.data.sellList[0].list
      let data3 = res.data.commonList[0].list
      this.allMenuData1 = editData(data1)
      this.allMenuData2 = editData(data2)
      this.allMenuData3 = editData(data3)
      console.log(this.allMenuData1, 'gagaga')
    }
  }
}

</script>
<style lang="less" scoped>
.roleManageEdit {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .roleManageEditContent {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 260px);
    overflow-y: auto;
    section {
      width: 33%;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        span {
          float: right;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.60);
        }
      }
      .sectionHeader {
        padding: 20px;
        border-bottom: 2px solid #F8F9FA;
        ul {
          width: 100%;
          li {
            margin-top: 20px;
            display: flex;
            width: 100%;
            span {
              width: 130px;
              line-height: 32px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              i {
                margin-right: 3px;
                color: rgba(242, 90, 90, 1);
              }
            }
            .el-input {
              width: calc(100% - 112px);
            }
            p {
              padding-left: 10px;
              width: calc(100% - 112px);
              height: 32px;
              background-color: rgba(0, 0, 0, 0.05);
              border-radius: 4px;
              box-sizing: border-box;
              line-height: 32px;
              font-size: 14px;
            }
            .el-select {
              width: calc(100% - 112px);
            }
          }
        }
      }
      .sectionBody {
        padding: 20px;
        .el-table {
          height: calc(100vh - 550px);
          overflow-y: auto;
        }
      }
    }
    main {
      padding: 20px;
      width: calc(67% - 20px);
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      overflow-y: scroll;
      .tableMenu{
        border: 1px solid #eee;
        width: 100%;
        border-spacing: 0;
        table-layout: fixed;
        thead tr{
          background: #eee;
          height: 40px;
        }
        .custom-ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          .secoundLi{
            border-bottom: 1px solid #ccc;
          }
          .secoundLi:last-child{
            border-bottom: none;
          }
          li{
            display: inline-block;
            width: 100%;
            line-height: 40px;
                .custom-ul2{
                border-bottom: 1px solid #ccc;
              }
          }
          li:last-child{
            .custom-ul2{
                border-bottom: none;
              }
          }
        }
        tbody td{
          border: 1px solid #ccc;
  // padding: 8px 0px;
  text-align: center;
        }
      }

      h5 {
        margin: 20px 20px;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
      }
      .mainHeader {
        margin-top: 20px;
        width: 100%;
        height: 48px;
        background-color: rgba(247, 247, 247, 1);
        line-height: 48px;
        span {
          padding-left: 20px;
          display: inline-block;
          width: 260px;
          box-sizing: border-box;
        }
        span:nth-child(2) {
          width: calc(100% - 260px);
        }
      }
      .mainContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 320px);
        overflow-y: auto;
        li {
          border-bottom: 1px solid #ccc;
          .liLeft {
            position: relative;
            padding-left: 20px;
            display: inline-block;
            width: 260px;
            height: 100%;
            box-sizing: border-box;
            background-color: rgba(18, 62, 131, 0.05);
            vertical-align: top;
            .leftBox {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .liRight {
            padding-left: 20px;
            display: inline-block;
            box-sizing: border-box;
            width: calc(100% - 260px);
            height: 100%;
            background-color: rgba(18, 62, 131, 0.02);
            .liBox {
              padding: 13px 0;
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
}
</style>
