import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import Home from './components/home.vue'
import Index from './components/index.vue'
import PersonalCenter from './components/personalCenter/personalCenter.vue'
import RegisterExaminManage from './components/enterpriseManage/registerExaminManage.vue'
import ClientAudit from './components/enterpriseManage/clientAudit.vue'
import QuoteExaminManage from './components/enterpriseManage/quoteExaminManage.vue'
import MenuManage from './components/systemManage/menuManage.vue'
import BackMenuManage from './components/systemManage/backMenuManage.vue'
import RoleManage from './components/systemManage/roleManage.vue'
import RoleManageEdit from './components/systemManage/roleManageEdit.vue'
import HelpMenu from './components/helpMenu/helpMenu.vue'
import PurchaseRequirementReview from './components/enterpriseManage/purchaseRequirementReview.vue'
import HelpMenuList from './components/helpMenu/helpMenuList.vue'
import HelpMenuDetail from './components/helpMenu/helpMenuDetail.vue'
import ManualManage from './components/operationManual/manualManage.vue'
import ManualManage2 from './components/operationManual/manualManage2.vue'
import ManualDetail from './components/operationManual/manualDetail.vue'
import TemplateUpload from './components/templateUpload/templateUploadList.vue'
import PriceQuotation from './components/quotationManage/priceQuotation.vue'
import ClassifyManageList from './components/classifyManage/classifyManageList.vue'
import ClassifyAttribute from './components/classifyManage/classifyAttribute.vue'
import ClassifyManageDetail from './components/classifyManage/classifyManageDetail.vue'
import MessageConfigure from './components/systemConfigure/messageConfigure.vue'
import BankCardManage from './components/financeManage/bankCardManage.vue'
import StoragePriceDetail from './components/storageManage/storagePriceDetail.vue'
import TemplateList from './components/templateManage/templateList.vue'
import UploadBaseTemplate from './components/templateManage/uploadBaseTemplate.vue'
import AddTemplate from './components/templateManage/addTemplate.vue'
import ContractList from './components/contractManage/contractList.vue'
import ContractDetail from './components/contractManage/contractDetail.vue'
import OrderList from './components/orderManage/orderList.vue'
import OrderDetail from './components/orderManage/orderDetail.vue'
import CollectManage from './components/financeManage/collectManage.vue'
import CollectDetail from './components/financeManage/collectDetail.vue'
import CollectPaylogDetail from './components/financeManage/collectPaylogDetail.vue'
import OutBoundList from './components/outBound/outBoundList.vue'
import AdminNature from './components/userManage/adminNature.vue'
import LabelUser from './components/userManage/labelUser.vue'
import LabelUserDetail from './components/userManage/labelUserDetail.vue'
import InfoList from './components/webInfo/infoList.vue'
import EntryBack from './components/webInfo/entryBack.vue'
import FrameWork from './components/groundPush/frameWork.vue'
import PermissionManage from './components/groundPush/permissionManage.vue'
import HighSeas from './components/groundPush/highSeas.vue'
import HighSeasDetail from './components/groundPush/highSeasDetail.vue'
import UploadSeas from './components/groundPush/uploadSeas.vue'
// 客户账号管理
// 客户列表
import CustomerList from './components/customerManage/customerList.vue'
import CustomerDetail from './components/customerManage/customerDetail.vue'
import AddCustomer from './components/customerManage/addCustomer.vue'
import EditCustomer from './components/customerManage/editCustomer.vue'
// 会员管理
import settingVip from './components/vipSettingPage/settingVip.vue'
import addSettingVip from './components/vipSettingPage/addSettingVip.vue'
import corporateMember from './components/vipSettingPage/corporateMember.vue'
// 配置管理
import informManage from './components/informPage/informManage.vue'
import addInform from './components/informPage/addInform.vue'
// 企业审核
import enterpriseAudit from './components/enterpriseAudit/enterpriseAudit.vue'
import enterpriseAuditDetail from './components/enterpriseAudit/enterpriseAuditDetail.vue'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const router = new Router({
  routes: [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // 主页
  {
    path: '/home',
    component: Home,
    redirect: '/index',
    children: [{
      path: '/index',
      component: Index
    },
    // 个人中心
    {
      path: '/personalCenter',
      component: PersonalCenter
    },
    // 注册审核管理
    {
      path: '/registerExaminManage',
      component: RegisterExaminManage
    },
    // 报表审核管理
    {
      path: '/quoteExaminManage',
      component: QuoteExaminManage
    },
    // 客户审核
    {
      path: '/clientAudit',
      component: ClientAudit
    },
    // 用户需求审核
    {
      path: '/purchaseRequirementReview',
      component: PurchaseRequirementReview
    },
    // 系统管理
    // 1.菜单管理
    {
      path: '/menuManage',
      component: MenuManage
    },
    // 2.后台菜单管理
    {
      path: '/backMenuManage',
      component: BackMenuManage
    },
    // 3.角色管理
    {
      path: '/roleManage',
      component: RoleManage
    },
    // 4.角色管理编辑页面
    {
      path: '/roleManageEdit',
      component: RoleManageEdit
    },
    // 帮助管理
    // 1.帮助目录
    {
      path: '/helpMenuList',
      component: HelpMenuList
    },
    // 2.帮助菜单
    {
      path: '/helpMenu',
      component: HelpMenu
    },
    // 3.帮助菜单详情
    {
      path: '/helpMenuDetail',
      component: HelpMenuDetail
    },
    // 操作手册
    // 1.手册一级菜单
    {
      path: '/manualManage',
      component: ManualManage
    },
    // 2.手册二级菜单
    {
      path: '/manualManage2',
      component: ManualManage2
    },
    // 3.手册详情
    {
      path: '/manualDetail',
      component: ManualDetail
    },
    // 电子签模板
    // 1.模板上传列表
    {
      path: '/templateUpload',
      component: TemplateUpload
    },
    // 行情管理
    // 1.价格行情
    {
      path: '/priceQuotation',
      component: PriceQuotation
    },
    // 分类管理
    // 1.分类行情
    {
      path: '/classifyManageList',
      component: ClassifyManageList
    },
    // 2.属性配置
    {
      path: '/classifyAttribute',
      component: ClassifyAttribute
    },
    // 2.详情设置
    {
      path: '/classifyManageDetail',
      component: ClassifyManageDetail
    },
    // 系统配置
    // 1.消息配置
    {
      path: '/messageConfigure',
      component: MessageConfigure
    },
    // 财务管理
    // 1.银行卡管理
    {
      path: '/bankCardManage',
      component: BankCardManage
    },
    // 2.收款管理
    {
      path: '/collectManage',
      component: CollectManage
    },
    // 3.收款详情
    {
      path: '/collectDetail',
      component: CollectDetail
    },
    // 4.收款明细详情
    {
      path: '/collectPaylogDetail',
      component: CollectPaylogDetail
    },
    // 仓储管理
    // 1.仓储费用明细
    {
      path: '/storagePriceDetail',
      component: StoragePriceDetail
    },
    // 合同管理
    // 1.合同列表
    {
      path: '/contractList',
      component: ContractList
    },
    // 2.合同详情
    {
      path: '/contractDetail',
      component: ContractDetail
    },
    // 模板管理
    // 1.模板列表
    {
      path: '/templateList',
      component: TemplateList
    },
    // 2.新建模板
    {
      path: '/addTemplate',
      component: AddTemplate
    },
    // 3.上传基础模板
    {
      path: '/uploadBaseTemplate',
      component: UploadBaseTemplate
    },
    // 订单管理
    // 1.订单列表
    {
      path: '/orderList',
      component: OrderList
    },
    // 2.订单详情
    {
      path: '/orderDetail',
      component: OrderDetail
    },
    // 出库管理
    // 出库列表
    {
      path: '/outBoundList',
      component: OutBoundList
    },
    // 用户管理
    // 经营属性
    {
      path: '/adminNature',
      component: AdminNature
    },
    // 标签用户
    {
      path: '/labelUser',
      component: LabelUser
    },
    // 标签用户详情
    {
      path: '/labelUserDetail',
      component: LabelUserDetail
    },
    // 官网资讯
    // 资讯列表
    {
      path: '/infoList',
      component: InfoList
    },
    // 报名后台
    {
      path: '/entryBack',
      component: EntryBack
    },
    // 组织架构
    {
      path: '/frameWork',
      component: FrameWork
    },
    // 权限管理
    {
      path: '/permissionManage',
      component: PermissionManage
    },
    // 公海客户
    {
      path: '/highSeas',
      component: HighSeas
    },
    // 公海客户详情
    {
      path: '/highSeasDetail',
      component: HighSeasDetail
    },
    // 公海客户导入
    {
      path: '/uploadSeas',
      component: UploadSeas
    },
    // -----------------------------
    // 客户管理
    // 1.客户列表
    {
      path: '/customerList',
      component: CustomerList
    },
    // 2.客户详情
    {
      path: '/customerDetail',
      component: CustomerDetail
    },
    // 3.新增客户
    {
      path: '/addCustomer',
      component: AddCustomer
    },
    // 4.修改客户
    {
      path: '/editCustomer',
      component: EditCustomer
    },
    // 会员管理
    // 会员配置
    {
      path: '/settingVip',
      component: settingVip
    },
    // 新增会员等级
    {
      path: '/addSettingVip',
      component: addSettingVip
    },
    // 企业会员
    {
      path: '/corporateMember',
      component: corporateMember
    },
    // 配置管理
    // 通知配置
    {
      path: '/informManage',
      component: informManage
    },
    {
      path: '/addInform',
      component: addInform
    },
    // 企业审核
    {
      path: '/enterpriseAudit',
      component: enterpriseAudit
    },
    {
      path: '/enterpriseAuditDetail',
      component: enterpriseAuditDetail
    }
    ]
  }
  ]
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/register' || to.path === '/resetPassword') return next()
  const Token = window.sessionStorage.getItem('token')
  if (!Token) return next('/login')
  next()
})
export default router
