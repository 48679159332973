<template>
    <!-- 登录页面 -->
    <div class="login_container">
        <!-- 登录页面中间部分(包括登录框) -->
        <div class="login_body">
            <div class="login_box">
              <h1>供应链权限系统</h1>
              <!-- 登录框 -->
              <div class="tabs">
                <header>Hello Again</header>
                <!-- 密码登录表单 -->
                <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" >
                    <el-form-item prop="mobile">
                        <el-input v-model="ruleForm1.mobile" placeholder="请输入管理员账号" type="text" prefix-icon="el-icon-mobile-phone" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="ruleForm1.password" placeholder="请输入登录密码" type="password" prefix-icon="iconfont icon-iconmm" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="checkBox">
                        <el-checkbox v-model="ruleForm1.choose">记住密码</el-checkbox>
                        <el-checkbox v-model="ruleForm1.auto">自动登录</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="submitForm('ruleForm1')">登录</el-button>
                    </el-form-item>
                </el-form>
              </div>
              <p>供应链管理平台 版权©所有2019</p>
            </div>
        </div>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
import { loginApi } from '@/api'
import Utils from '@/utils'
export default {
  data() {
    return {
      // 登录框默认选中
      activeName: 'first',
      // 存储当前域名
      host: '',
      // 表单数据
      ruleForm1: {
        mobile: '',
        password: '',
        // 记住登录勾选状态
        choose: false,
        // 自动登录状态
        auto: false
      },
      // 登录框校验
      rules: {
        // mobile: [
        //   { required: true, message: '请输入手机号码', trigger: 'blur' },
        //   { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        // ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        //   { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/, message: '密码长度应为6~16个字符且包含字母和数字' }
        // ]
      },
      // 控制验证码按钮的禁用状态
      controlBtn: false
    }
  },
  created() {
    this.host = window.location.host
    if (this.$cookies.isKey('_mobile')) {
      this.ruleForm1.mobile = this.$cookies.get('_mobile')
    }
    if (this.$cookies.isKey('_password')) {
      this.ruleForm1.password = Utils.decrypt(this.$cookies.get('_password'), 'hAw6eqnFLKxpsDv3')
    }
    if (this.$cookies.isKey('_choose')) {
      this.ruleForm1.choose = true
    }
  },
  mounted() {
    // 自动登录调用功能
    this.autoSubmitForm()
    // 绑定回车实现登录
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    // 跳转至登录页面
    routerJump(path) {
      this.$router.push(path)
    },
    format(percentage) {
      return percentage === 100 ? '满' : `${percentage}%`
    },
    // 登录校验及调用密码登录接口
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        let encryPassword = 'hAw6eqnFLKxpsDv3' + Base64.encode(this.ruleForm1.password)
        const { data: res } = await loginApi({ username: this.ruleForm1.mobile, password: encryPassword })
        if (res.code !== 0) return this.$message.error('您输入的账号或者密码不正确')
        if (this.ruleForm1.auto === true) {
          this.ruleForm1.choose = true
        }
        if (this.ruleForm1.choose === true) {
          // 设置cookies保存时间
          var date = new Date()
          date.setDate(date.getDate() + 15)
          // 对cookies中的密码进行crypt-js加密
          let psd = Utils.encrypt(this.ruleForm1.password, 'hAw6eqnFLKxpsDv3')
          this.$cookies.set('_mobile', this.ruleForm1.mobile, date)
          this.$cookies.set('_password', psd, date)
          this.$cookies.set('_choose', this.ruleForm1.choose, date)
          this.$cookies.set('_auto', this.ruleForm1.auto, date)
          this.$cookies.set('token', res.data.token)
        } else {
          this.$cookies.remove('_mobile')
          this.$cookies.remove('_password')
          this.$cookies.remove('_choose')
          this.$cookies.remove('_auto')
        }
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('info', JSON.stringify(res.data.info))
        this.$router.push('/home')
      })
    },
    // 自动登录
    async autoSubmitForm() {
      if (this.$cookies.get('auto') === 'true') {
        const { data: res } = await loginApi({ username: this.ruleForm1.mobile, password: this.ruleForm1.password })
        if (res.code !== 0) return this.$message.error('登录请求超时')
        window.sessionStorage.setItem('token', res.data.token)
        this.$router.push('/home')
      }
    },
    // 判断按下回车键实现登录
    keyDown(e) {
      if (e.keyCode === 13) {
        this.submitForm2('ruleForm2')
        this.submitForm('ruleForm1')
      }
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>
<style scoped lang='less'>
.login_container {
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.login_body {
    position: relative;
    height: 100%;
    background-size: 100% 100%;
    background-image: url('../assets/img/backBg.jpg')
}
.login_box {
    position: absolute;
    top: 50%;
    right: 50%;
    height: 380px;
    width: 430px;
    background-color:rgba(0,0,0,.3);
    border-radius: 8px;
    transform: translate(50%,-50%);
    z-index: 1;
}
.login_body .login_box h1 {
  position: absolute;
  top: -20%;
  left: 50%;
  width: 327px;
  height: 36px;
  transform: translate(-50%);
  font-size: 36px;
  color: #fff;
}
.login_body .login_box p {
  position: absolute;
  bottom: -12%;
  left: 50%;
  transform: translate(-50%);
  font-size: 14px;
  color: #fff;
}
.login_box .tabs {
    box-sizing: border-box;
    padding: 20px 40px;
    height: 360px;
    width: 430px;
    border-radius: 8px;
    background-color:rgba(0,0,0,.2);
}
.login_box header {
    margin: 20px 0;
    // background-color:rgba(0,0,0,.5);
    color: #fff;
    font-size: 26px;
    text-align: center;
    font-weight: 500;
}
.el-form-item {
    height: 50px;
}
.el-checkbox {
    color: #99979A;
}
.el-input {
    background-color: rgba(255,255,255,.8)!important;
}
.el-input__inner {
    background-color: rgba(255,255,255,.8)!important;
}
.el-button {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.8);
    border: 0;
}
.el-button:hover {
    background-color: rgba(0,0,0,.6);
    color: #fff;
}
.el-button:active {
    color: #000;
}
.el-button:focus {
    color: #000;
    background-color: rgba(255,255,255,.8);
}
</style>
