// 公海客户导入
<template>
    <div class="collectManage">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <div class="searchBox">
            <el-button @click="routerJump()" class="lightDeepStyle addBtn" size="mini">返回列表</el-button>
            <p>
              <span>导入标准以企业名称为唯一标识,需要导入模板导入</span>
              <span span style="cursor: pointer;"><a style="color: rgba(0, 199, 199);" href="http://zloss.bmxgj.cn/upload174/2023-04-19/4f4a2f05d25d45bbb2bd69eb743a8b44/地推模版.xlsx"><i class="iconfont icon-mobanxiazai"></i>模板下载</a></span>
            </p>
          </div>
          <div>
            <!-- 测试服 'http://adminapi.bmxgj.cn' -->
            <!-- 正式服 'https://adminapi.z-srm.com' -->
            <el-upload
              ref="uploadOrder"
              class="upload-demo"
              action="https://adminapi.z-srm.com/backend/DT/importMsg"
              :headers="headers"
              multiple
              name='file'
              :limit="1"
              :on-success="uploadSuccess"
              :before-upload="beforeAvatarUpload"
              :show-file-list="true"
              :file-list="fileList">
              <el-button class="lightDeepStyle addBtn" size="mini">数据导入</el-button>
            </el-upload>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="createTime" label="导入时间" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="state" label="处理进度" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.status === 0 ? '处理中' : '已完成'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="number" label="处理总数" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="successNum" label="成功条数" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="errorNum" label="失败条数" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="200px" align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.errorNum > 0" style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="highSeasError(scope.row.id)">下载</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { highSeasLoadListApi, highSeasErrorApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      headers: {
        'token': window.sessionStorage.getItem('token')
      },
      fileList: [],
      // 所有菜单目录
      orderList: [],
      orderData: {
        page: 1,
        size: 11
      },
      // 订单详情
      remarkList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 11,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0
    }
  },
  created() {
    this.getHighSeasLoadList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    async highSeasError(id) {
      const { data: res } = await highSeasErrorApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res, '666')
    },
    // 上传文件成功回调
    uploadSuccess(res) {
      this.$message.success({ duration: 1000, message: '上传成功!' })
      this.$refs.uploadOrder.clearFiles()
      setInterval(() => {
        this.getHighSeasLoadList()
      }, 500)
    },
    // 上传前大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 导入页面跳转
    routerJump() {
      this.$router.back()
    },
    // 获取订单列表
    async getHighSeasLoadList() {
      const { data: res } = await highSeasLoadListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.getHighSeasLoadList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    .addBtn {
      margin-right: 30px;
      width: 106px;
      height: 28px;
    }
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  // .el-switch {
  //   margin-left: 50%;
  //   transform: translateX(-16%);
  // }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 10px!important;
  width: 972px;
  height: 834px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 720px;
    width: 972px;
    h6 {
      margin: 20px 0 20px 15px;
      font-size: 16px;
      font-weight: 500;
    }
    ul {
      margin: 30px 0;
      height: 300px;
      overflow-y: auto;
      li {
        margin-bottom: 10px;
        padding: 0 0 0 20px;
        position: relative;
        width: 90%;
        border-bottom: 1px solid #ccc;
        line-height: 30px;
        i {
          display: inline-block;
          width: 250px;
        }
        p {
          display: inline-block;
          font-size: 20px;
          width: 566px;
          overflow: hidden;
          vertical-align: text-top;
          white-space: pre-wrap;
        }
        span {
          display: block;
          font-size: 16px;
        }
      }
    }
    .el-textarea__inner {
      height: 200px;
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
