<template>
    <div class='vipContent'>
        <!-- 卡片视图区 -->
        <el-card :style="'height:' + tableHeight2 + 'px'">
            <el-row style="margin-top: 10px;">
                <el-col v-for="(item, index) in list" :key="index" :span="6">
                        <div @click="vipAddFn(item.id)">
                            <h3>{{ item.name }}</h3>
                        </div>
                </el-col>
            </el-row>
        </el-card>
      </div>
    </template>

<script>
import { informListApi, deleteVip, updateVipApi } from '@/api'
export default {
  components: {},
  props: {},
  data() {
    return {
      name: '',
      // 会员列表
      list: [],
      roleData: {
        id: '',
        // 当前角色id
        // 备注
        expDay: '',
        // 上级岗位id
        parentId: null,
        // 角色名称
        name: '',
        // 部门名称
        rebate: '',
        // 权限菜单
        menuIds: [],
        // 存储权限菜单
        isOpen: '',
        renewPrice: '',
        userCount: '',
        price: '',
        rebatePrice: ''
      }
    }
  },
  computed: {},
  methods: {
    // 会员配置列表请求
    async getVipListFn() {
      const { data: res } = await informListApi()
      if (res.code === 0) {
        this.list = res.data
      } else {
        this.$message({
          type: 'error', // success error warning
          message: '列表获取失败',
          duration: 200
        })
      }
    },
    // 跳转新增
    vipAddFn(pointCode) {
      this.$router.push({
        path: '/addInform',
        query: {
          pointCode: pointCode
        }
      })
    },
    // 删除会员
    async deleteFn(id) {
      const { data: res } = await deleteVip({ id: id })
      if (res.code === 0) {
        this.getVipListFn()
        return this.$message({
          type: 'success', // success error warning
          message: '删除成功',
          duration: 1000
        })
      }
    },
    updateVip(id) {
      this.$router.push({
        path: '/addSettingVip',
        query: {
          configId: id
        }
      })
    },
    async toggleIsOpen(item, value) {
      item.isOpen = value ? 1 : 0
      this.roleData.isOpen = item.isOpen
      this.roleData.id = item.id
      this.roleData.name = item.name
      const { data: res } = await updateVipApi(this.roleData)
      if (res.code === 0) {
        return this.$message({
          type: 'success', // success error warning
          message: '修改成功',
          duration: 1000
        })
      }
      this.getVipListFn()
    }
  },
  created() {
    this.getVipListFn()
  },
  mounted() {

  }
}
</script>
    <style scoped lang="less">
    .vipContent {
      width: 100%;
      .el-card {
        padding: 26px;
        background:rgba(255,255,255,1);
        border-radius:3px;
        overflow: auto;
        .el-col-6{
            width: 24%;
            margin-right: 5px;
            border: 1px solid #ccc;
           box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
           padding: 10px;
            div:nth-child(1){
                display: flex;
                line-height: 3.125rem;
                align-items: center;
                justify-content: space-between;
                h3{
                    font-size: 14px;
                    font-weight: bold;
                    width: 50%;
                }
                p{
                    font-weight: bold;
                    font-size: 12px;
                    color: #5ebdbe;
                }
            }
            .unitiveClass{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            span{
                display: inline-block;
                line-height: 3rem;
            }
           }
           .unitiveClass2{
            display: flex;
            align-items: center;
            justify-content: space-between;
           }
        }
      }
    }
    </style>
