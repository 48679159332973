//  注册审核管理
<template>
    <div class="registReview_content">
      <!-- 卡片视图区 -->
      <el-card>
        <!-- 状态选择区 -->
        <el-row>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1" @click="switchData('')">全部</el-menu-item>
            <el-menu-item index="2" @click="switchData(0)">待审核</el-menu-item>
            <el-menu-item index="3" @click="switchData(1)">通过审核</el-menu-item>
            <el-menu-item index="4" @click="switchData(2)">已升级</el-menu-item>
          </el-menu>
        </el-row>
        <!-- 内容搜索区 -->
        <el-row :gutter="20">
          <el-col :span="2">
            <p>企业名称:</p>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="请输入企业名称" clearable v-model="userListData.name" @input="getUserList()" @keyup.enter.native="getUserList()">
            </el-input>
          </el-col>
        </el-row>
        <!-- 物料定义表格区 -->
        <el-row>
            <el-table tooltip-effect="dark" ref="multipleTable" :data="tableData" border style="width: 100%" show-overflow-tooltip :default-sort = "{prop: 'date', order: 'descending'}">
              <el-table-column prop="company" label="企业名称" min-width="140px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="provinceName" label="地区" min-width="200px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    {{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="scaleDescribe" label="企业规模" sortable min-width="120px"  show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="capital" label="注册资金" min-width="120px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.capital !== null ? scope.row.capital + '万元' : '-'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="申请时间" sortable min-width="140px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="level" label="等级" min-width="120px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.level === 1">普通企业</div>
                  <div v-else-if="scope.row.level === 2">VIP企业</div>
                </template>
              </el-table-column>
              <el-table-column label="状态" min-width="120px" show-overflow-tooltip align="center"  sortable>
                <template slot-scope="scope">
                  <div v-if="scope.row.ifCancel === true"><p style="background-color: red;"></p>已注销</div>
                  <div v-else-if="scope.row.status === 0"><p style="background-color: #F56C6C;"></p>未审核</div>
                  <div v-else-if="scope.row.status === 1"><p style="background-color: #409EFF;"></p>已审核</div>
                  <div v-else-if="scope.row.status === 2"><p style="background-color: yellowgreen;"></p>已升级</div>
                  <div v-else-if="scope.row.status === 3"><p style="background-color: #ccc;"></p>已驳回</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="320px">
                <template slot-scope="scope">
                  <el-button type="info" size="mini" @click="displayInfo(scope.row)"><a>查看</a></el-button>
                  <el-button v-if="scope.row.status === 0" class="passBtn" size="mini" @click="passConfirm(scope.row, 1)"><a>通过</a></el-button>
                  <el-button v-if="scope.row.status === 0" type="danger" size="mini" @click="passReject(scope.row, 3)"><a>驳回</a></el-button>
                  <el-button v-if="scope.row.status === 1 && scope.row.ifCancel !== true" type="warning" size="mini" @click="passPromotion(scope.row, 2)"><a>企业升级</a></el-button>
                  <el-button v-if="scope.row.ifCancel !== true && (scope.row.status === 1 || scope.row.status === 2)" style="background-color: #ccc;" size="mini" @click="cancelUser(scope.row)"><a>注销</a></el-button>
                  <el-button v-if="scope.row.ifCancel === true" style="background-color: #49CAD5;" size="mini" @click="recoveryUser(scope.row)"><a>恢复</a></el-button>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="userListData.size"
            :total="total">
          </el-pagination>
        </el-row>
      </el-card>
      <template>
        <div>
          <el-dialog title="企业信息" :visible.sync="dialogVisible" width="30%">
            <ul>
                <li>企业名称 : <span class="company_name">{{enterInfo.company}}</span></li>
                <li>所在地区 : <span>{{enterInfo.provinceName}}-{{enterInfo.cityName}}-{{enterInfo.areaName}}</span></li>
                <li>企业地址 : <span>{{enterInfo.address}}</span></li>
            </ul>
            <div class="float_l">
              <ul>
                <li>企业规模 : <span>{{enterInfo.scaleDescribe}}</span></li>
                <li>注册资金 : <span>{{enterInfo.capital}}万元</span></li>
              </ul>
            </div>
            <div class="float_r">
              <ul>
                <li>行业类型 : <span>{{enterInfo.industryDescribe}}</span></li>
                <li>经营模式 : <span>{{enterInfo.modesDescribe}}</span></li>
              </ul>
            </div>
            <p>联系人信息</p>
            <div class="float_l">
              <ul>
                <li>联系人 : <span>{{enterInfo.contact}}</span></li>
                <li>职位 : <span>{{enterInfo.position}}</span></li>
                <li>手机 : <span>{{enterInfo.mobile}}</span></li>
              </ul>
            </div>
            <div class="float_l">
              <ul>
                <li>性别 : <span>{{enterInfo.sex === '1'? '男': '女'}}</span></li>
                <li>电子邮箱 : <span>{{enterInfo.email}}</span></li>
                <!-- <li>联系电话 : <span>{{enterInfo.tel}}</span></li> -->
              </ul>
            </div>
          </el-dialog>
        </div>
      </template>
    </div>
</template>
<script>
import { cancelUserApi, sendVerificationApi, getUserListApi, passExaminApi, recoveryUserApi } from '@/api'
export default {
  name: 'App',
  data() {
    return {
      // 获取分页数据
      getUserListData: [],
      // 获取自定义分类数据
      matterCustomData: [],
      // 获取表格数据
      tableData: [],
      // 当前激活状态栏
      activeIndex: '1',
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      userListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 7,
        name: '',
        status: ''
      },
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        // 不关联父子节点
        checkStrictly: true
      },
      // 获取当前浏览器的宽度
      screenWidth: '',
      // 控制弹框弹出
      dialogVisible: false,
      // 要查看的企业信息
      enterInfo: {
        company: '',
        provinceName: '',
        cityName: '',
        areaName: ''
      }
    }
  },
  created() {
    this.getUserList()
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    if (this.screenWidth >= 1600) {
      this.userListData.size = 8
    } else if (this.screenWidth >= 1440) {
      this.userListData.size = 6
    } else {
      this.userListData.size = 4
    }
    this.getUserList()
  },
  methods: {
    // 注销当前企业
    async cancelUser(result) {
      this.$confirm('此操作将注销 ' + result.company, '再次确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await cancelUserApi(result.companyId)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('注销成功!')
        this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消'
        })
      })
    },
    // 注销恢复企业
    async recoveryUser(result) {
      this.$confirm('此操作将恢复被注销的' + result.company, '再次确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await recoveryUserApi(result.companyId)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('恢复成功!')
        this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消'
        })
      })
    },
    // 获取企业展示数据
    async getUserList() {
      const { data: res } = await getUserListApi(this.userListData)
      console.log(res)
      if (res.code !== 0) return this.$message.error('获取用户企业失败')
      // 获取表单数据
      this.tableData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.userListData.page = val
      this.currentPage = val
      this.getUserList()
    },
    // 点击切换展示数据
    switchData(status) {
      this.userListData.status = status
      this.getUserList()
    },
    // 通过申请提示
    passConfirm(row, status) {
      this.$confirm('此操作将通过 ' + row.company + ' 的审核', '再次确认', {
        confirmButtonText: '通过',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await passExaminApi({ companyId: row.companyId, status: status })
        if (res.code !== 0) return this.$message.error('获取用户企业失败')
        this.$message({
          type: 'success',
          message: '审核通过!'
        })
        // 通过后发短信通知用户
        const { data: val } = await sendVerificationApi({ type: 7, mobile: row.mobile })
        if (val.code !== 0) return this.$message.error(val.msg)
        this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消审核'
        })
      })
    },
    // 通过申请提示
    passReject(row, status) {
      this.$confirm('此操作将驳回 ' + row.company + ' 的审核', '再次确认', {
        confirmButtonText: '驳回',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await passExaminApi({ companyId: row.companyId, status: status })
        if (res.code !== 0) return this.$message.error('获取用户企业失败')
        this.$message({
          type: 'success',
          message: '驳回成功!'
        })
        // 驳回成功后发短信通知用户
        const { data: val } = await sendVerificationApi({ type: 3, mobile: row.mobile })
        if (val.code !== 0) return this.$message.error(val.msg)
        this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消审核'
        })
      })
    },
    // 进行升级提示
    passPromotion(row, status) {
      this.$confirm('此操作将对 ' + row.company + ' 进行升级', '再次确认', {
        confirmButtonText: '通过',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await passExaminApi({ companyId: row.companyId, status: status })
        if (res.code !== 0) return this.$message.error('获取用户企业失败')
        this.$message({
          type: 'success',
          message: '升级成功!'
        })
        // 升级成功后发短信通知用户
        const { data: val } = await sendVerificationApi({ type: 8, mobile: row.mobile })
        if (val.code !== 0) return this.$message.error(val.msg)
        this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消升级'
        })
      })
    },
    // 显示企业信息
    async displayInfo(result) {
      this.dialogVisible = true
      this.enterInfo = result
      console.log(this.enterInfo, '嗷嗷待哺')
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 620px;
  }
  @media screen and (max-width: 1440px) {
    height: 500px;
  }
}
.el-card p {
  margin-right: -20px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.el-card .el-col>.el-button {
  width:70px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .el-button--info {
  background-color: rgba(0,0,0,.8);
}
.el-card .el-button--info:hover {
  background-color: rgba(0,0,0,.7);
}
.el-card .el-button--info:active {
  background-color: rgba(0,0,0,.9);
}
.el-card /deep/ .el-input__inner:focus {
  border: 1px solid #000;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  padding: 12px 0;
  width: 80px;
  height: 34px;
  background: rgba(255,255,255,1);
  border-radius: 3px;
  color: #666;
  line-height: 0
}
.el-card .add_btn {
  float: right;
}
.el-card .cancelExamine_btn {
  padding:12px 0;
  width:80px;
  height:34px;
  background:rgba(255,255,255,1);
  border-radius:3px;
  color: #666;
  line-height: 0;
}
.el-card .el-menu--horizontal {
    height: 60px;
    border-bottom: 1px solid #ccc!important;
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: 30px;
  right: 0;
}
.el-table .cell div {
  display: flex;
}
.el-table .cell p {
  margin: 8px 10px;
  width: 8px;
  height: 8px;
  background-color: #B7B7B7;
  border-radius: 50%;
  box-shadow: 1px 1px 1px black,-1px -1px 1px white;
}
.el-table .cell .el-button {
  border: 0;
}
.el-table .cell .passBtn {
  background-color: #009688;
}
.el-table .cell .passBtn:hover {
  background-color: rgba(0,150,136,.7)
}
.el-table .cell .passBtn:active {
  background-color: rgba(0,150,136,.9)
}
.el-table .cell a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.el-table .cell img {
  margin: 0 auto;
  display: block;
  width: 33px;
  height: 33px;
}
.el-menu-demo {
  margin: -20px 0 20px 0;
  font-weight: 400;
}
// 弹出查看窗口样式
.el-dialog__body li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.el-dialog__body li .company_name {
  font-size: 20px;
  color: #01519B;
}
.el-dialog__body p {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.el-dialog__body .float_l {
  display: inline-block;
  width: 265px;
}
.el-dialog__body .float_r {
  display: inline-block;
  width: 265px;
}
</style>
