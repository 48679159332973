// 用户管理 经营属性
<template>
    <div class="businessList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <el-button type="success" class="examine_btn" @click="addModel()">新增</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="businessList" border style="width: 100%;">
              <el-table-column prop="name" label="经营属性" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="sort" label="排序编号" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-around">
                    <el-button type="primary" size="mini" @click="editModel(scope.row)">修改</el-button>
                    <el-button v-if="scope.row.del === false" type="danger" size="mini" @click="delModel(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="businessData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <!-- 菜单模板 -->
      <template>
        <div>
          <el-dialog class="miniDialog" title="新增经营属性" :visible.sync="menuVisible" width="30%" :close-on-click-modal="false">
            <p><span>经营属性 : </span><el-input v-model="detailData.name" placeholder="请输入经营属性" type="text" clearable autocomplete="off"></el-input></p>
            <p><span>排序编号 : </span><el-input v-model="detailData.sort" placeholder="请输入排序编号" type="number" clearable autocomplete="off"></el-input></p>
            <div slot="footer">
              <el-button size="mini" @click="menuVisible = false">返 回</el-button>
              <el-button v-if="detailData.id !== ''" size="mini" type="primary" @click="saveBusiness(2)">修 改</el-button>
              <el-button v-else size="mini" type="primary" @click="saveBusiness(1)">添 加</el-button>
            </div>
          </el-dialog>
        </div>
      </template>
    </div>
</template>
<script>
import { businessListApi, saveBusinessApi, delBusinessApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      businessData: {
        page: 1,
        size: 10
      },
      detailData: {
        id: '',
        name: '',
        sort: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      businessList: [],
      // 控制新增菜单的弹出框
      menuVisible: false
    }
  },
  created() {
    this.getBusinessList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    addModel() {
      this.detailData = {
        id: '',
        name: '',
        sort: ''
      }
      this.menuVisible = true
    },
    editModel(result) {
      this.detailData = {
        id: result.id,
        name: result.name,
        sort: result.sort
      }
      this.menuVisible = true
    },
    // 删除
    delModel(id) {
      this.$confirm('此操作将删除该经营模式', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delBusinessApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getBusinessList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取订单列表
    async getBusinessList() {
      const { data: res } = await businessListApi(this.businessData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.businessList = res.data.data.records
      // 获取当前页面
      this.currentPage = res.data.data.current
      // 获取页面总页数
      this.totalNum = res.data.data.pages
      // 获取总条数
      this.total = res.data.data.total
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.businessData.page = val
      this.currentPage = val
      this.getBusinessList()
    },
    // 保存经营属性
    async saveBusiness(val) {
      if (this.detailData.name === '') return this.$message.error('经营属性不能为空!')
      if (this.detailData.sort === '') return this.$message.error('排序不能不能为空!')
      const { data: res } = await saveBusinessApi(this.detailData)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (val === 1) {
        this.$message.success('新增成功!')
      } else {
        this.$message.success('修改成功!')
      }
      this.menuVisible = false
      this.getBusinessList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  width: 100%;
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
/deep/ .miniDialog .el-dialog {
  width: 440px!important;
  height: 290px;
  .el-dialog__header {
    height: 28px;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(60,141,188,.1);
    .el-dialog__title {
      color: #000000;
    }
    .el-dialog__close::before {
      color: #000000;
    }
  }
  .el-dialog__body {
    height: 165px;
    p {
      display: flex;
      justify-content: space-between;
      span {
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
  .el-dialog__footer {
    height: 66px;
    >div {
      height: 100%;
      line-height: 100%;
      .el-button {
        margin-top: 10px;
      }
    }
  }
}
</style>
