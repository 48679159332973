// 财务管理 收款管理 查看详情
<template>
    <div class="collectDetail">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <!-- 菜单管理表格区 -->
        <el-row>
          <h6>还款记录<el-button class="backBtn lightDeepStyle query" size="mini" @click="routerPush()">返回</el-button></h6>
          <el-table ref="tableHeight" :max-height="tableHeight" :data="collectDetail.payList" border style="width: 100%;">
            <el-table-column type="index" label="序号" width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="createTime" label="日期" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="业务类型" min-width="60px" show-overflow-tooltip align="center">
              <template>
                <div>对公转账出</div>
              </template>
            </el-table-column>
            <el-table-column prop="paylogNum" label="单据编码" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="typeName" label="费用类型" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="money" label="付款金额(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="remainMoney" label="剩余本金(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="bankCompany" label="汇款人" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="collectBankCompany" label="收款人" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="remainAmount" label="款项状态" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 0">未审核</span>
                  <span v-else-if="scope.row.status === 1">审核通过</span>
                  <span v-else-if="scope.row.status === 2">拒绝</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="remainAmount" label="驳回原因" min-width="60px" show-overflow-tooltip align="center">
              <template>
                <div>暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 0" style="color: rgba(250, 173, 20, 1); cursor: pointer" @click="routerJump(scope.row)">确认明细</span>
                  <span v-else style="color: #339EBF; cursor: pointer" @click="routerJump(scope.row)">查看明细</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <div class="infoBox"><span>已付金额: {{collectDetail.payMoney}}元</span><span>剩余金额: {{collectDetail.remainMoney}}元</span><span>总金额: {{collectDetail.totalMoney}}元</span></div>
        </el-row>
        <!-- <el-row>
          <h6>合同信息</h6>
          <div class="contractBox">
            <div class="priceBox">
              <i></i>
              <span>应收总金额: {{collectDetail.totalMoney}}元</span>
            </div>
            <div><p>合同编号</p><span>{{collectDetail.contractNum}}</span><p>公司名称</p><span>{{collectDetail.companyName}}</span></div>
            <div><p>合同名称</p><span>{{collectDetail.fileName}}</span><p>保证金</p><span>{{collectDetail.bondMoney === null ? '0.00' : collectDetail.bondMoney}}</span></div>
            <div><p>合同周期</p><span>{{collectDetail.contractTime}}</span><p>服务费</p><span>{{collectDetail.serveMoney}}</span></div>
            <div><p>创建日期</p><span>{{collectDetail.beginTime}}</span><p>货物总值</p><span>{{collectDetail.amount}}</span></div>
          </div>
        </el-row> -->
        <el-row>
          <h6>发票等单证</h6>
          <div class="imgBox">
            <div v-for="(item, index) in collectDetail.fileList" :key="index">
              <img :src="item.url">
              <span class="textHidden">{{item.name}}</span>
            </div>
          </div>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { payMentDetailApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderNum: this.$route.query.orderNum,
      // 收款详情
      collectDetail: ''
    }
  },
  created() {
    this.getOrderList()
    console.log(this.orderNum)
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 750
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 750
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单列表
    async getOrderList() {
      const { data: res } = await payMentDetailApi(this.orderNum)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.collectDetail = res.data.detail
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'collectPaylogDetail', query: { id: result.id } })
    },
    // 路由返回
    routerPush() {
      this.$router.back()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
h6 {
  margin: 0 0 20px;
  font-size: 16px;
  .backBtn {
    float: right;
  }
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.infoBox {
  margin-bottom: 20px;
  height: 40px;
  line-height: 40px;
  span {
    float: right;
    margin-left: 50px;
    color: rgba(51, 158, 191, 1);
    font-size: 16px;
    font-weight: 500;
  }
}
.contractBox {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  .priceBox {
    position: absolute;
    top: -38px;
    right: 0;
    height: 38px;
    width: 290px;
    background-color: rgba(51, 158, 191, 1);
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    i {
      position: absolute;
      height: 0;
      width: 0;
      border: 16px solid #FFFFFF;
      border-right-color: rgba(51, 158, 191, 1);
      border-bottom-color: rgba(51, 158, 191, 1);
    }
    span {
      border: 0;
      font-size: 16px;
      color: #ffffff;
    }
  }
  >div {
    margin-top: -1px;
    display: flex;
    p {
      margin-left: -1px;
      padding-left: 16px;
      flex: 1;
      height: 36px;
      background-color: rgba(247, 249, 250, 1);
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
    span {
      margin-left: -1px;
      padding-left: 16px;
      flex: 1;
      height: 36px;
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
  }
}
.imgBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  >div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    img {
      margin: 0 20px 20px;
      width: 280px;
      height: 120px;
    }
    span {
      margin-top: 10px;
      font-size: 14px;
      display: inline-block;
      width: 280px;
    }
  }
}
</style>
