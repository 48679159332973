<template>
  <div class='highSeasDetail'>
    <nav>
      <el-button size="mini" class="defaultStyle" @click="backJump()">返 回</el-button>
      <el-button size="mini" class="lightDeepStyle" @click="openDialog()">编 辑</el-button>
      <el-button size="mini" class="rejectStyle" @click="delSeas()">删 除</el-button>
    </nav>
    <header>
      <p>客户名称 : <span>{{seasDetail.name}}</span></p>
      <p>负责人 : <span>{{seasDetail.nickName}}</span></p>
      <p>捞取状态 : <span>{{seasDetail.state === 0 ? '未捞取' : '已捞取'}}</span></p>
      <p>创建时间 : <span>{{seasDetail.createTime}}</span></p>
      <p>更新时间 : <span>{{seasDetail.createTime}}</span></p>
      <p>最新跟进时间 : <span>{{seasDetail.createTime}}</span></p>
      <p style="width: 70%">企业地址 : <span>{{seasDetail.addressAll}}</span></p>
    </header>
    <main>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item :index="0" @click="switchData(0)">企业状况</el-menu-item>
        <el-menu-item :index="1" @click="switchData(1)">跟进记录</el-menu-item>
        <el-menu-item :index="2" @click="switchData(2)">联系人</el-menu-item>
        <el-menu-item :index="3" @click="switchData(3)">采购产品</el-menu-item>
        <el-menu-item :index="4" @click="switchData(4)">供应产品</el-menu-item>
      </el-menu>
      <div class="mainBox" v-if="activeIndex === 0">
        <h4><i></i>基本信息</h4>
        <p><span>客户名称</span>{{seasDetail.name}}<span></span></p>
        <p><span>客户类型</span>{{seasDetail.typeName}}<span></span></p>
        <p><span>客户行业</span>{{seasDetail.industryName}}<span></span></p>
        <p><span>年营业额(万元)</span>{{seasDetail.turnover}}<span></span></p>
        <p><span>企业员工数</span>{{seasDetail.staffNumber}}<span></span></p>
        <p><span>企业经营状况</span>{{seasDetail.manageName}}<span></span></p>
        <h4><i></i>企业信息</h4>
        <p><span>法人代表</span>{{seasDetail.legalPerson}}<span></span></p>
        <p><span>统一信用代码</span>{{seasDetail.code}}<span></span></p>
        <p><span>主营产品</span>{{seasDetail.product}}<span></span></p>
        <p><span>地址</span>{{seasDetail.addressAll}}<span></span></p>
        <p><span>企业营业执照</span><img :src="seasDetail.businessLicense"></p>
      </div>
      <div class="mainBox" v-if="activeIndex === 1">
        <el-table ref="tableHeight" :height="tableHeight" :data="seasDetail.dtVisitEntityList" border style="width: 100%;">
          <el-table-column prop="contactName" label="拜访人" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="contactPhone" label="联系方式" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="visitTime" label="拜访时间" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="visitTypeName" label="拜访方式" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="remark" label="拜访内容" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
        </el-table>
      </div>
      <div class="mainBox" v-if="activeIndex === 2">
        <el-table ref="tableHeight" :height="tableHeight" :data="seasDetail.dtCompanyContactEntityList" border style="width: 100%;">
          <el-table-column prop="name" label="联系人" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="phone" label="手机号" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="remark" label="性格" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
        </el-table>
      </div>
      <div class="mainBox" v-if="activeIndex === 3">
        <el-table ref="tableHeight" :height="tableHeight" :data="seasDetail.dtBuyEntityList" border style="width: 100%;">
          <el-table-column prop="name" label="产品名称" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="period" label="采购周期" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="number" label="最低采购量" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="specs" label="规格" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
        </el-table>
      </div>
      <div class="mainBox" v-if="activeIndex === 4">
        <el-table ref="tableHeight" :height="tableHeight" :data="seasDetail.dtSellEntityList" border style="width: 100%;">
          <el-table-column prop="name" label="产品名称" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="period" label="供应周期" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="number" label="最低供应量" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="specs" label="规格" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
        </el-table>
      </div>
    </main>
    <template>
      <el-dialog title="客户编辑" :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog">
        <div class="dialogBox">
          <p><span>客户名称 : </span><el-input v-model="editDetail.name" placeholder="请输入客户名称" type="text" clearable autocomplete="off"></el-input></p>
          <p>
            <span>客户类型 : </span>
            <el-select v-model="editDetail.typeName" filterable placeholder="请选择所属行业类型" size="mini">
              <el-option label="普通客户" value="普通客户"></el-option>
              <el-option label="跟进客户" value="跟进客户"></el-option>
              <el-option label="成交客户" value="成交客户"></el-option>
            </el-select>
          </p>
          <p>
            <span>客户行业 : </span>
            <el-cascader :options="industryList" :props="defaultData" clearable v-model="editDetail.industry" placeholder="请选择"></el-cascader>
          </p>
          <p><span>年营业额(万元) : </span><el-input v-model="editDetail.turnover" placeholder="请输入年营业额" type="number" clearable autocomplete="off"></el-input></p>
          <p><span>企业员工数 : </span><el-input v-model="editDetail.staffNumber" placeholder="请输入员工数" type="number" clearable autocomplete="off"></el-input></p>
          <p>
            <span>企业经营状况 : </span>
            <el-select v-model="editDetail.manage" filterable placeholder="请选择所属行业类型" size="mini">
              <el-option label="正常" :value="0"></el-option>
              <el-option label="注销" :value="1"></el-option>
              <el-option label="停业" :value="2"></el-option>
              <el-option label="解散" :value="3"></el-option>
            </el-select>
          </p>
          <p>
            <span>负责人 : </span>
            <el-select v-model="editDetail.wxUserId" filterable placeholder="请选择负责人" size="mini">
              <el-option v-for="(item, index) in userList" :key="index" :label="item.nickName" :value="item.wxUserId"></el-option>
            </el-select>
          </p>
          <p><span>法人代表 : </span><el-input v-model="editDetail.legalPerson" placeholder="请输入法人代表" type="text" clearable autocomplete="off"></el-input></p>
          <p><span>统一信用代码 : </span><el-input v-model="editDetail.code" placeholder="请输入统一信用代码" type="text" clearable autocomplete="off"></el-input></p>
          <p>
            <span>选择省市区</span>
            <el-select class="showAddress" v-model="editDetail.province" placeholder="请选择省份" size="mini" clearable @change="getCityList('1')">
              <el-option :label="item.name" :value="item.code" v-for="(item, index) in provinceList" v-bind:key="index"></el-option>
            </el-select>
            <el-select class="showAddress" style="margin: 0 10px;" v-model="editDetail.city" placeholder="请选择城市" size="mini" clearable @change="getAreaList('1')">
              <el-option :label="item.name" :value="item.code" v-for="(item, index) in cityList" v-bind:key="index"></el-option>
            </el-select>
            <el-select class="showAddress" v-model="editDetail.area" placeholder="请选择地区" size="mini" clearable>
              <el-option :label="item.name" :value="item.code" v-for="(item, index) in areaList" v-bind:key="index"></el-option>
            </el-select>
          </p>
          <p><span>主营产品 : </span><el-input v-model="editDetail.product" placeholder="请输入主营产品" type="text" clearable autocomplete="off"></el-input></p>
          <p><span>详细地址 : </span><el-input v-model="editDetail.address" placeholder="请输入详细地址" type="text" clearable autocomplete="off"></el-input></p>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="keepBtn" @click="editHighSeas()">保 存</el-button>
          <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { highSeasDetailApi, delSeasApi, seasIndustryApi, editHighSeasApi, getDeptUserApi, getProvinceApi } from '@/api'
export default {
  data() {
    return {
      // 当前用户的deptId 企业id
      deptId: JSON.parse(window.sessionStorage.getItem('info')).deptId,
      // 行业类型
      industryList: [],
      createDialog: false,
      activeIndex: 0,
      seasDetail: {},
      editDetail: {},
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'sysIndustryEntityList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false
      },
      // 用户列表
      userList: [],
      // 省市区下拉框的pcode
      pcode: '000000',
      // 存储省列表
      provinceList: [],
      // 存储市列表
      cityList: [],
      // 存储区列表
      areaList: []
    }
  },
  created() {
    this.seasIndustry()
    this.getDeptUser()
    this.highSeasDetail(this.$route.query.id)
  },
  methods: {
    // 获取省份信息
    async getProvince() {
      const { data: res } = await getProvinceApi(this.pcode)
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.code === this.editDetail.province) {
          this.editDetail.provinceName = item.name
        }
      })
      this.getCityList()
    },
    // 获取城市信息
    async getCityList(key, e) {
      console.log(e, '7777777')
      if (key !== undefined) {
        this.editDetail.city = ''
        this.editDetail.area = ''
      }
      const { data: res } = await getProvinceApi(this.editDetail.province)
      if (res.code !== 0) return this.$message.error('获取城市失败!')
      this.cityList = res.data.list
      this.cityList.forEach((item, index) => {
        if (item.code === this.editDetail.city) {
          this.editDetail.cityName = item.name
        }
      })
      this.getAreaList()
    },
    // 获取地区信息
    async getAreaList(key) {
      if (key !== undefined) {
        this.editDetail.area = ''
      }
      const { data: res } = await getProvinceApi(this.editDetail.city)
      if (res.code !== 0) return this.$message.error('获取地区失败!')
      this.areaList = res.data.list
      this.areaList.forEach((item, index) => {
        if (item.code === this.editDetail.area) {
          this.editDetail.areaName = item.name
        }
      })
    },
    // 根据部门获取用户列表
    async getDeptUser() {
      const { data: res } = await getDeptUserApi({ page: 1, size: 99, id: this.deptId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userList = res.data.list
    },
    // 获取公海客户行业类型
    async seasIndustry() {
      const { data: res } = await seasIndustryApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.industryList = res.data.data
    },
    // 打开编辑弹框
    openDialog() {
      this.createDialog = true
      this.editDetail = this.seasDetail
      this.getProvince()
    },
    // 修改公海客户
    async editHighSeas() {
      const { data: res } = await editHighSeasApi(this.editDetail)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '保存成功!' })
      this.createDialog = false
      this.highSeasDetail(this.$route.query.id)
    },
    // 删除公海客户
    delSeas() {
      this.$confirm('是否确认删除当前公海客户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delSeasApi(this.$route.query.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '删除成功!' })
        this.backJump()
      }).catch(() => {
      })
    },
    backJump() {
      this.$router.push('./highSeas')
    },
    // 标签切换
    switchData(val) {
      this.activeIndex = val
    },
    // 客户详情
    async highSeasDetail(id) {
      const { data: res } = await highSeasDetailApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.seasDetail = res.data
    }
  }
}
</script>
<style scoped lang='less'>
.highSeasDetail {
  nav {
    padding: 10px;
    background-color: #fff;
  }
  header {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 160px;
    width: calc(100% - 30px);
    background-color: #fff;
    p {
      width: 33%;
      line-height: 30px;
    }
  }
  main {
    .el-menu-demo {
      margin-top: -20px;
      padding: 0 20px;
      font-weight: 400;
    }
    /deep/ .el-menu--horizontal {
      border-bottom: 0!important;
    }
    .mainBox {
      padding: 0 20px;
      height: 400px;
      background-color: #fff;
      overflow-y: auto;
      h4 {
        margin: 20px 0;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        i {
          margin: 0 10px;
          display: inline-block;
          width: 4px;
          height: 14px;
          background-color: #D7D7D7;
          vertical-align: 10;
        }
      }
      p {
        span {
          display: inline-block;
          width: 200px;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
  /deep/ .createDialog .el-dialog {
    margin-top: 80px!important;
    width:550px;
    height: 724px;
    line-height: 16px;
    .el-dialog__header {
      background-color: #fff;
      span{
        color: #000;
      }
      .el-icon-close:before {
        color: #000;
      }
    }
    .el-dialog__body {
      position: relative;
      padding: 0 20px;
      height: 610px;
      width: 550px;
      .dialogBox {
        p {
          margin-bottom: 10px;
          >span {
            display: inline-block;
            width: 120px;
          }
          .el-input {
            width: 380px;
          }
        }
      }
      .el-select {
        height: 40px;
        .el-input__inner {
          height: 40px;
        }
      }
      .showAddress {
        .el-input {
          width: 120px!important;
        }
        .el-input__inner {
          width: 120px!important;
        }
      }
    }
    .dialog-footer {
      text-align: center;
      .keepBtn {
        background-color: rgba(0, 199, 199, 1);
        border: 1px solid rgba(0, 199, 199, 1);
        color: #fff;
      }
      .keepBtn:hover {
        background-color: rgba(0, 199, 199, .5);
        border: 1px solid rgba(102,221,221, .5);
      }
    }
  }
}
</style>
