// 财务管理 收款管理 账单明细
<template>
    <div class="collectPaylogDetail">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <!-- 菜单管理表格区 -->
        <el-row>
          <h6>款项确认<el-button class="backBtn lightDeepStyle query" size="mini" @click="routerPush()">返回</el-button></h6>
          <el-table ref="tableHeight" :max-height="tableHeight" :data="infoDetail" border style="width: 100%;">
            <el-table-column prop="money" label="打款金额" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="bondMoney" label="保证金" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="lixiMoney" label="已还利息" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="serveMoney" label="服务费" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="origMoney" label="已还本金" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="remainAmount" label="款项状态" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 0">未审核</span>
                  <span v-else-if="scope.row.status === 1">审核通过</span>
                  <span v-else-if="scope.row.status === 2">拒绝</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="collectDetail.paylogEntity.status == 2" prop="origMoney" label="驳回原因" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <h6 style="margin-top: 30px;">发票等单证</h6>
          <div class="imgBox">
            <div v-for="(item, index) in collectDetail.fileList" :key="index">
              <img :src="item.url">
              <span class="textHidden">{{item.name}}</span>
            </div>
          </div>
        </el-row>
        <el-row>
          <!-- 0 待确认 1已确认 2已拒绝 -->
          <div class="infoBox" v-if="collectDetail.paylogEntity.status == 0">
            <button @click="financeExamine(1)">单笔款项确认</button>
            <button @click="showCancel()">驳回</button>
          </div>
        </el-row>
        <el-row>
          <h6>基本信息</h6>
          <div class="contractBox">
            <div><p>付款开户银行</p><span>{{collectDetail.paylogEntity.bank}}</span><p>收款开户银行</p><span>{{collectDetail.paylogEntity.collectBank}}</span></div>
            <div><p>付款账号</p><span>{{collectDetail.paylogEntity.bankNum}}</span><p>收款账号</p><span>{{collectDetail.paylogEntity.collectBankNum}}</span></div>
            <div><p>付款账户名称</p><span>{{collectDetail.paylogEntity.bankCompany}}</span><p>收款账户名称</p><span>{{collectDetail.paylogEntity.collectBankCompany}}</span></div>
            <div><p>业务类型</p><span>公对公转出</span><p>货币</p><span>人民币</span></div>
            <!-- <div><p>合同期限</p><span>{{collectDetail.flowEntity.beginTime}}至{{collectDetail.flowEntity.endTime}}</span><p>合同编号</p><span>{{collectDetail.flowEntity.orderNum}}</span></div> -->
          </div>
        </el-row>
        <el-row>
          <h6>费用信息</h6>
          <div class="contractBox2">
            <div class="bondBox" v-if="collectDetail.paylogEntity.lixiMoney != 0">保证金</div>
            <div class="bondBox2" v-else>全款</div>
            <div v-if="collectDetail.paylogEntity.lixiMoney != 0"><p></p><p>保证金({{collectDetail.orderEntity.bondRate}}%)</p><span>贷款总值*{{collectDetail.orderEntity.bondRate}}%=保证金</span></div>
            <div v-else><p></p><p>全款(100%)</p><span>贷款总值+服务费</span></div>
            <div><p></p><p>服务费({{collectDetail.orderEntity.serveRate}}%)</p><span>贷款总值*{{collectDetail.orderEntity.serveRate}}%=服务费</span></div>
            <div v-if="collectDetail.paylogEntity.lixiMoney != 0"><p></p><p>利息(日利率{{collectDetail.orderEntity.moneyRate}}%)</p><span>当次还款本金*{{collectDetail.orderEntity.moneyRate}}%=利息</span></div>
            <!-- <div><p></p><p>仓储费(元)</p><span>根据每次还款时间收取相应的仓储费用</span></div> -->
            <div><p></p><p>货物总值(元)</p><span>订单金额 * 订单数量 = 货物总值</span></div>
          </div>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { payMentPaylogDetailApi, financeExamineApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      id: this.$route.query.id,
      // 收款明细详情
      collectDetail: '',
      infoDetail: [],
      remark: ''
    }
  },
  created() {
    this.getPayMentPaylogDetail()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 750
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 750
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取收款明细详情
    async getPayMentPaylogDetail() {
      const { data: res } = await payMentPaylogDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.collectDetail = res.data
      this.infoDetail.push(this.collectDetail.paylogEntity)
    },
    // 财务审核
    async financeExamine(status, value) {
      const { data: res } = await financeExamineApi({ id: this.id, remark: value, orderNum: this.collectDetail.flowEntity.orderNum, status: status })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (status === 1) {
        this.$message.success('确认成功!')
      } else {
        this.$message.success('驳回成功!')
      }
      this.infoDetail = []
      this.getPayMentPaylogDetail()
    },
    // 显示拒绝弹框
    showCancel() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (value === null || value === '') return this.$message.error('驳回原因不能为空!')
        this.financeExamine(2, value)
      }).catch(() => {
        this.$message({
          type: 'warn',
          message: '取消'
        })
      })
    },
    // 路由返回
    routerPush() {
      this.$router.back()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
h6 {
  margin: 0 0 20px;
  font-size: 16px;
  .backBtn {
    float: right;
  }
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.infoBox {
  margin: 40px 0 36px;
  display: flex;
  justify-content: center;
  height: 40px;
  line-height: 40px;
  button {
    margin-right: 10px;
    padding: 0 24px;
    height: 32px;
    background-color: rgba(51, 158, 191, 1);
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    line-height: 32px;
    color: #FFFFFF;
  }
  button:nth-child(2) {
    background-color: rgba(235, 19, 19, 1);
  }
}
.contractBox {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  .priceBox {
    position: absolute;
    top: -38px;
    right: 0;
    height: 38px;
    width: 290px;
    background-color: rgba(51, 158, 191, 1);
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    i {
      position: absolute;
      height: 0;
      width: 0;
      border: 16px solid #FFFFFF;
      border-right-color: rgba(51, 158, 191, 1);
      border-bottom-color: rgba(51, 158, 191, 1);
    }
    span {
      border: 0;
      font-size: 16px;
      color: #ffffff;
    }
  }
  >div {
    margin-top: -1px;
    display: flex;
    p {
      margin-left: -1px;
      padding-left: 16px;
      flex: 1;
      height: 36px;
      background-color: rgba(247, 249, 250, 1);
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
    span {
      margin-left: -1px;
      padding-left: 16px;
      flex: 1;
      height: 36px;
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
  }
}
.contractBox2 {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  .bondBox {
    position: absolute;
    top: 1px;
    left: -1px;
    padding-left: 16px;
    width: calc(25% - 12px);
    height: 147px;
    border: 1px solid #D4D6D9;
    background-color: rgba(247, 249, 250, 1);
    line-height: 147px;
  }
  .bondBox2 {
    position: absolute;
    top: 1px;
    left: -1px;
    padding-left: 16px;
    width: calc(25% - 12px);
    height: 110px;
    border: 1px solid #D4D6D9;
    background-color: rgba(247, 249, 250, 1);
    line-height: 110px;
  }
  >div {
    margin-top: -1px;
    display: flex;
    p {
      margin-left: -1px;
      padding-left: 16px;
      width: 25%;
      height: 36px;
      background-color: rgba(247, 249, 250, 1);
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
    span {
      margin-left: -1px;
      padding-left: 16px;
      width: 50%;
      height: 36px;
      border: 1px solid #D4D6D9;
      line-height: 36px;
    }
  }
}
.imgBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  >div {
    margin: 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    img {
      width: 280px;
      height: 120px;
    }
    span {
      margin-top: 10px;
      font-size: 14px;
      display: inline-block;
      width: 280px;
    }
  }
}
</style>
