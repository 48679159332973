// 模板管理 上传基础模板
<template>
  <div class='uploadBaseTemplate'>
    <!-- 卡片视图区 -->
    <el-card :style="'height:' + tableHeight2 + 'px'">
      <!-- 测试服 'https://adminapi.bmxgj.cn' -->
      <!-- 正式服 'https://adminapi.z-srm.com' -->
      <el-upload
        ref="uploadOrder"
        class="upload-demo"
        action="https://adminapi.z-srm.com/api/sign/upload"
        :headers="headers"
        multiple
        name='file'
        :limit="1"
        :on-success="uploadSuccess"
        :before-upload="beforeAvatarUpload"
        :show-file-list="true"
        :file-list="fileList">
        <el-button class="lightDeepStyle uploadBtn" size="mini">上传文件</el-button>
      </el-upload>
      <div v-for="(item, index) in baseTemplateList" :key="index" class="infoBox">
        <img src="../../assets/img/uploadImg.png">
        <u>{{item.fileName.substring(item.fileName.length - 3, item.fileName.length).toUpperCase()}}</u>
        <div class="infoContent">
          <p>{{item.fileName}}</p>
          <span>{{(item.fileSize/1024).toFixed(0)}}kb</span>
        </div>
        <i @click="delBaseTemplate(item.id)" class="el-icon-delete"></i>
      </div>
    </el-card>
  </div>
</template>
<script>
import { baseTemplateListApi, delBaseTemplateApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 基础模板列表
      baseTemplateList: [],
      headers: {
        'token': window.$cookies.get('token')
      },
      fileList: []
    }
  },
  created() {
    this.getBaseTemplateList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取基础模板列表
    async getBaseTemplateList() {
      const { data: res } = await baseTemplateListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.baseTemplateList = res.data.data
    },
    // 删除基础模板
    async delBaseTemplate(id) {
      const { data: res } = await delBaseTemplateApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.getBaseTemplateList()
    },
    // 上传文件成功回调
    uploadSuccess(res) {
      this.$message.success({ duration: 1000, message: '上传成功!' })
      this.$refs.uploadOrder.clearFiles()
      this.getBaseTemplateList()
    },
    // 上传前大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt2M
    }
  }
}
</script>
<style scoped lang='less'>
.uploadBaseTemplate {
  width: 100%;
  .el-card {
    padding: 26px;
    background:rgba(255,255,255,1);
    border-radius:3px;
    overflow: auto;
    .uploadBtn {
      margin-bottom: 25px;
      width: 200px;
      height: 36px;
      background-color: rgba(51, 158, 191, 1);
    }
    .infoBox {
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding: 20px 0;
      width: 100%;
      border-bottom: 1px solid #cccc;
      u {
        position: absolute;
        top: 46px;
        left: 14px;
        font-size: 12px;
        color: #FFFFFF;
      }
      .infoContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 15px;
        p {
          font-size: 14px;
          color: #000000;
          font-weight: 500;
        }
        span {
          font-size: 14px;
          color: rgba(187, 189, 191, 1);
        }
      }
      i {
        font-size: 20px;
        line-height: 45px;
      }
      i:hover {
        cursor: pointer;
        color: rgb(255, 0, 0);
      }
    }
  }
}
</style>
