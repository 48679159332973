// 合同管理 合同列表
<template>
    <div class="contractList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <p>
            <span>合同名称 : </span>
            <el-input v-model="contractData.fileName" placeholder="请输入合同名称" type="text" size="small" maxlength="40"></el-input>
            <span>合同状态 : </span>
            <el-select style="width: 160px;" v-model="contractData.ifSign" placeholder="请选择" size="mini" clearable>
              <el-option label="未签" value="0"></el-option>
              <el-option label="已签" value="1"></el-option>
              <el-option label="失败" value="3"></el-option>
              <el-option label="拒签" value="4"></el-option>
            </el-select>
            <span>客户名称 : </span>
            <el-input v-model="contractData.companyName" placeholder="请输入客户名称" type="text" size="small" maxlength="40"></el-input>
            <span>发起时间 : </span>
            <el-date-picker
              v-model="contractData.createTimeBegin"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span style="width: 30px; margin-right: 0">至</span>
            <el-date-picker
              v-model="contractData.createTimeEnd"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </p>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getContractList()">查询</el-button>
            <el-button class="lightDeepStyle query" size="mini" @click="rejectSearch()">重置</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="contractList" border style="width: 100%;">
              <el-table-column label="合同类别" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.type === 0">购销合同</span>
                    <span v-else-if="scope.row.type === 1">委托合同</span>
                    <span v-else-if="scope.row.type === 2">其他合同</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="orderNum" label="合同编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="fileName" label="合同名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="companyName" label="客户名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="beginTime" label="合同开始日期" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <el-date-picker v-if="chooseItem.id === scope.row.id"
                      v-model="chooseItem.beginTime"
                      type="date"
                      placeholder="请选择"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <span v-else>{{scope.row.beginTime}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="合同结束日期" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <el-date-picker v-if="chooseItem.id === scope.row.id"
                      v-model="chooseItem.endTime"
                      type="date"
                      placeholder="请选择"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <span v-else>{{scope.row.endTime}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="合同金额(元)" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="合同状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.ifSign === 0">未签</div>
                    <div v-else-if="scope.row.ifSign === 1">已签</div>
                    <div v-else-if="scope.row.ifSign === 3">失败</div>
                    <div v-else-if="scope.row.ifSign === 4">拒签</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建日期" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-between">
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click.stop="routerJump(scope.row)">查看</span>
                    <span v-if="chooseItem.id !== scope.row.id" style="cursor: pointer; color: rgba(250, 173, 20, 1)" size="mini" @click.stop="editTemplateTime(scope.row)">设置</span>
                    <span v-else style="cursor: pointer; color: rgba(250, 173, 20, 1)" size="mini" @click.stop="editTemplateTime(0)">确认设置</span>
                    <span style="cursor: pointer; color: rgba(235,19,19, 1)" size="mini">催办</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { getContractListApi, keepContractApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      contractData: {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      },
      // 所有菜单目录
      contractList: [],
      // 选中的项目
      chooseItem: ''
    }
  },
  created() {
    this.getContractList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取合同列表
    async getContractList() {
      if (this.contractData.createTimeBegin === null) this.contractData.createTimeBegin = ''
      if (this.contractData.createTimeEnd === null) this.contractData.createTimeEnd = ''
      const { data: res } = await getContractListApi(this.contractData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.contractList = res.data.data
    },
    // 重置搜索条件
    rejectSearch() {
      this.contractData = {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      }
      this.getContractList()
    },
    // 打开修改合同日期
    async editTemplateTime(result) {
      if (result === 0) {
        const { data: res } = await keepContractApi(this.chooseItem)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('保存成功!')
        this.chooseItem = ''
      } else {
        this.chooseItem = result
      }
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'contractDetail', query: { id: result.id } })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  >p {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    span {
      margin-right: 5px;
      display: inline-block;
      line-height: 28px;
    }
    .el-input {
      margin-right: 10px;
      width: 160px;
    }
    .el-select {
      margin-right: 5px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .priceQuotationDialog {
  .el-dialog {
    height: 600px;
    .el-dialog__body {
      height: 535px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
.priceQuotation>.el-table {
  margin-top: 60px;
}
.priceQuotation .dialogBody {
  height: 450px;
  overflow-y: auto;
}
.priceQuotation .el-dialog .el-table {
  margin-top: 0;
  text-align: center;
  .editBtn {
    background-color: rgba(42,49,59,.7);
    color: #fff;
  }
  .cancelBtn {
    background-color: #ccc;
    color: #fff;
  }
  .cancelBtn:hover {
    border: 1px solid #ccc;
  }
}
.priceQuotation .el-dialog .addText {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  .addValueInput {
    margin: 0 20px;
    margin-right: 10px;
    display: inline-block;
    width: 110px;
  }
  .addNameInput {
    margin: 0 20px;
    display: inline-block;
    width: 152px;
  }
  .addkeyInput {
    margin: 0 14px;
    display: inline-block;
  }
  .keepBtn {
    margin-left: 35px;
    background-color: #aacfcf;
    border: 0;
    color: #fff
  }
}
/deep/ .el-dialog {
  width: 480px!important;
  height: 700px!important;
  .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #000;
    color: #000;
    .el-dialog__title {
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    padding: 12px 16px;
    height: calc(100% - 60px);
    p {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      // height: 32px;
      line-height: 32px;
      >span {
        margin-right: 5px;
        color: #000;
        font-size: 14px;
        min-width: 80px;
        text-align: right;
      }
      .el-radio {
        line-height: 32px;
      }
      .el-textarea__inner {
        min-height: 100px!important;
      }
      .showInputBox {
        position: absolute;
        top: 0;
        left: 68px;
        width: calc(100% - 100px);
        z-index: 9999;
      }
      .el-cascader, .el-tag {
        margin-top: 30px;
        display: inline-block;
        width: calc(100% - 100px);
        .el-input__inner {
          height: 32px;
        }
      }
    }
  }
  .dialogFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 14px 20px;
    height: 56px;
    width: 100%;
    border-top: 1px solid #cccccc;
  }
}
/deep/ .mouldDialog .el-dialog {
  height: 400px!important ;
}
.avatar-uploader{
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultPicture.png') no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}
</style>
