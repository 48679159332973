// 帮助管理 帮助菜单
<template>
    <div class="helpMenuList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <el-button type="success" class="examine_btn" @click="routerJump()">新增</el-button>
          <div>
            <span>目录名称:</span>
            <el-select v-model="helpMenuData.mid" placeholder="请选择菜单目录" size="mini" clearable @change="getHelpMenu()">
              <el-option v-for="item in helpMenuList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div>
            <el-input size="mini" placeholder="请输入问题描述" v-model="helpMenuData.title" @keyup.enter.native="getHelpMenu()"></el-input>
          </div>
          <el-button class="lightDeepStyle query" size="mini" @click="getHelpMenu()">查询</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="allMenuData" border style="width: 100%">
              <el-table-column prop="name" label="目录名称" sortable min-width="140px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="sort" label="排序编号" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="title" label="问题描述" sortable min-width="300px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="mid" label="状态" sortable min-width="100px" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @input="controlSwitch(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button class="lightDeepStyle" size="mini" @click="routerJump(scope.row.id)">修改</el-button>
                    <el-button class="rejectStyle" size="mini" @click="delHelpMenuDetail(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="helpMenuData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { getHelpMenuDetailApi, editHelpMenuDetailApi, getHelpMenuListApi, getHelpMenuApi, delHelpMenuDetailApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 菜单目录列表
      helpMenuList: [],
      // 所有菜单目录
      allMenuData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      helpMenuData: {
        page: 1,
        size: 10,
        mid: '',
        status: '',
        title: '',
        type: 1
      }
    }
  },
  created() {
    this.getHelpMenu()
    this.getHelpMenuList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 360
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 360
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.helpMenuData.page = val
      this.currentPage = val
      this.getHelpMenu()
    },
    // 获取菜单目录列表
    async getHelpMenuList() {
      const { data: res } = await getHelpMenuListApi({ type: 1 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.helpMenuList = res.data.nameList
    },
    // 获取菜单内容列表
    async getHelpMenu() {
      const { data: res } = await getHelpMenuApi(this.helpMenuData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allMenuData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 删除菜单目录
    delHelpMenuDetail(id) {
      this.$confirm('是否删除当前内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delHelpMenuDetailApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getHelpMenu()
      }).catch(() => {
      })
    },
    // 路由跳转
    routerJump(id) {
      if (id) {
        this.$router.push({ path: '/helpMenuDetail', query: { id: id } })
      } else {
        this.$router.push('/helpMenuDetail')
      }
    },
    // 点击状态修改时触发
    async controlSwitch(result) {
      // 先根据id去获取详情
      const { data: res } = await getHelpMenuDetailApi(result.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newData = res.data.detail
      newData.status = result.status
      const { data: ress } = await editHelpMenuDetailApi(newData)
      if (ress.code !== 0) return this.$message.error(res.msg)
      if (result.status === 0) {
        this.$message.success('禁用成功!')
      } else {
        this.$message.success('启用成功!')
      }
      this.getHelpMenu()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 50px;
  .el-button {
    position: absolute;
    top: 0;
    left: 0;
  }
  >div {
    position: absolute;
    right: 70px;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
  >div:nth-child(2) {
    right: 300px;
  }
  .query {
    position: absolute;
    left: calc(100% - 60px);
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .helpMenuListDialog {
  .el-dialog {
    height: 300px;
    .el-dialog__body {
      height: 160px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
</style>
