// 帮助管理 帮助菜单详情
<template>
  <div class='helpMenuDetail'>
    <el-card>
      <el-button v-if="this.$route.query.id === undefined" class="lightDeepStyle" @click="addHelpMenuDetail()">保存新增</el-button>
      <el-button v-else class="lightDeepStyle" @click="editHelpMenuDetail()">保存修改</el-button>
      <div>
        <span>菜单目录</span>
        <el-select v-model="helpMenuContent.mid" placeholder="请选择菜单目录" size="mini" clearable>
          <el-option v-for="item in helpMenuList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div>
        <span>问题描述</span>
        <el-input size="mini" placeholder="请输入问题描述" v-model="helpMenuContent.title"></el-input>
      </div>
      <div>
        <span>排序编号</span>
        <el-input size="mini" placeholder="请输入排序编号" v-model="helpMenuContent.sort"></el-input>
      </div>
      <div>
        <span>发布状态</span>
        <el-switch
          v-model="helpMenuContent.status"
          :active-value="1"
          :inactive-value="0"
          active-color="rgba(27, 199, 181, 1)"
          inactive-color="#eee"
          @input="controlSwitch()">
        </el-switch>
      </div>
      <quill-editor
        ref="myQuillEditor"
        v-model="helpMenuContent.content"
        :options="editorOption"
      />
    </el-card>
  </div>
</template>
<script>
import { getHelpMenuDetailApi, editHelpMenuDetailApi, addHelpMenuDetailApi, getHelpMenuListApi } from '@/api'
export default {
  data() {
    return {
      helpMenuContent: {
        mid: '',
        title: '',
        sort: '',
        status: 0,
        content: '',
        type: 1
      },
      // 菜单目录列表
      helpMenuList: [],
      // 自定义富文本内容
      editorOption: {
        theme: 'snow',
        placeholder: '点击开始输入正文',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
            [{ script: 'sub' }, { script: 'super' }], // 下角标，上角标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 颜色选择
            [{ align: [] }], // 居中
            [('link', 'image')] // 控制能上传的内容
          ]
        }
      }
    }
  },
  created() {
    if (this.$route.query.id !== undefined) {
      this.getHelpMenuDetail()
    }
    this.getHelpMenuList()
  },
  methods: {
    // 获取菜单目录列表
    async getHelpMenuList() {
      const { data: res } = await getHelpMenuListApi({ type: 1 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.helpMenuList = res.data.nameList
    },
    // 新增帮助菜单内容
    async addHelpMenuDetail() {
      const { data: res } = await addHelpMenuDetailApi(this.helpMenuContent)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增帮助菜单内容成功!')
      this.$router.push('helpMenu')
    },
    // 修改帮助菜单内容
    async editHelpMenuDetail() {
      const { data: res } = await editHelpMenuDetailApi(this.helpMenuContent)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改帮助菜单内容成功!')
      this.$router.push('helpMenu')
    },
    // 获取帮助菜单详情
    async getHelpMenuDetail() {
      const { data: res } = await getHelpMenuDetailApi(this.$route.query.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.helpMenuContent = res.data.detail
      this.getHelpMenuList()
    }
  }
}
</script>
<style scoped lang='less'>
.helpMenuDetail {
  .el-card {
    width: 100%;
    height: 700px;
    background:rgba(255,255,255,1);
    border-radius:3px;
    overflow: auto;
    div {
      display: block;
      height: 30px;
      line-height: 12px;
      margin-top: 5px;
      span {
        margin-right: 10px;
      }
      .el-select {
        display: inline-block;
        width: 350px;
      }
      .el-input {
        display: inline-block;
        width: 350px;
      }
      .el-switch {
        display: inline-block;
        width: 200px;
      }
    }
    .quill-editor {
      margin-top: 10px;
    }
    /deep/ .ql-container {
      height: 350px!important;
    }
    /deep/ .ql-editor {
      height: 350px!important;
    }
  }
}
</style>
