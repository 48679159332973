//  采购审核管理
<template>
    <div class="registReview_content">
      <!-- 卡片视图区 -->
      <el-card v-show="showFirst">
        <!-- 内容搜索区 -->
        <el-row :gutter="20">
          <el-col :span="2">
            <p>品名:</p>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="请输入品名" clearable v-model="tableValue.labelName" @input="getQuoteList()" @keyup.enter.native="getQuoteList()">
            </el-input>
          </el-col>
          <!-- <el-col :span="2">
            <el-button type="info" @click="getQuoteList()">查询</el-button>
          </el-col> -->
          <el-col :span="2">
            <p>数据来源:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.source" placeholder="请选择数据来源" clearable @change="getQuoteList()">
              <el-option label="公海" :value="0"></el-option>
              <el-option label="地推" :value="1"></el-option>
              <el-option label="小程序" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <p>审核状态:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.approveStatus" placeholder="请选择审核状态" clearable @change="getQuoteList()">
              <el-option label="未审批" :value="0"></el-option>
              <el-option label="通过" :value="1"></el-option>
              <el-option label="拒绝" :value="2"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
            <el-button size="mini" icon='el-icon-sort' @click="showDialog">同步</el-button>
        </el-row>
        <!-- 物料定义表格区 -->
        <el-row>
          <el-table @selection-change="handleSelectionChange" :height="screenHeight" tooltip-effect="dark" ref="multipleTable" :data="tableData" border style="width: 100%" show-overflow-tooltip :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column type="selection" width="55" algin="center"></el-table-column>
            <el-table-column prop="labelName" label="品名" min-width="120px" show-overflow-tooltip align="center" >
              <template slot-scope="scope">
                <td style="cursor: pointer; color: #3975C6" @click="showSecondBox(scope.row.id)">{{ scope.row.labelName }}</td>
              </template>
            </el-table-column>
            <el-table-column prop="labelNameTop" label="产品类型"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="minPrice" label="意向单价"  min-width="150px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.minPrice }}——{{ scope.row.maxPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="求购数量"  min-width="150px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="productUnit" label="单位"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="distributionName" label="配送方式"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="day" label="有效期"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="picNum" label="附件数量"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="synchronization" label="同步到"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="sourceName" label="来源"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="approveStatusName" label="审核状态"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="操作" width="150px" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button size="mini" icon='el-icon-sort'  v-if="scope.row.approveStatusName === '审批通过'" @click="showDialog(scope.row.uuid)">同步</el-button>
                <div v-else-if="scope.row.approveStatusName === '待审核'">
                  <el-button class="passBtn" size="mini" @click="passConfirm(scope.row.id, 1)"><a>通过</a></el-button>
                  <el-button type="danger" size="mini" @click="passConfirm(scope.row.id, 2)"><a>不通过</a></el-button>
                </div>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="tableValue.size"
            :total="total">
          </el-pagination>
        </el-row>
      </el-card>
        <!-- 同步弹框 -->
    <el-dialog :visible.sync="dialogVisible" title="同步数据" :show-close="false">
      <template v-slot:default>
        <el-checkbox v-model="checkedList" label="1">地推</el-checkbox>
        <el-checkbox v-model="checkedList" label="2">小程序</el-checkbox>
        <div>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
      </template>
    </el-dialog>
      <!-- 商品信息 -->
      <el-card v-if="showSecond" class="detailsBox">
        <el-button @click="hideSecondBox">返回</el-button>
        <el-row>
            <p>商品信息</p>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>品名：<span style="font-weight: normal;">{{ detailData.labelName }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>类型：<span style="font-weight: normal;">{{detailData.labelNameTop}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>品牌：<span style="font-weight: normal;">{{detailData.item2}}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>求购数量：<span style="font-weight: normal;">{{detailData.num}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>单位：<span style="font-weight: normal;">{{detailData.productUnit}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>单位区间：<span style="font-weight: normal;">{{detailData.minPrice}}-{{ detailData.maxPrice }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="24">
                <div class="grid-content">
                    <p>型号：<span style="font-weight: normal;"> {{ detailData.item3 }} </span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="24">
                <span>上传图片：</span>
                <div class="grid-content bg-purple-dark">
                  <img v-for="(image, index) in detailData.pics" :key="index" :src="image.pic" />
                </div>
            </el-col>
        </el-row>
        <el-row>
            <p>其他信息</p>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>配送方式：<span style="font-weight: normal;">{{detailData.distributionName}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>货物所在地: <span style="font-weight: normal;">{{ detailData.addressName }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>可接受定做：<span style="font-weight: normal;">{{detailData.isMadeName}}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>有效期：<span style="font-weight: normal;">{{ detailData.day }}</span> 天</p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>备注：<span style="font-weight: normal;">{{ detailData.describes === '' || detailData.describes === null || detailData.describes === undefined ? '暂无' : detailData.describes }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <p>发布人信息</p>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>发布人：<span style="font-weight: normal;">{{ detailData.userName }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>发布时间：<span style="font-weight: normal;">{{detailData.createTime}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>发布人企业：<span style="font-weight: normal;"> {{ detailData.companyName }}</span></p>
                </div>
            </el-col>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { quoteListApi, quoteData, synquoteData, synquoteDataDt, synquoteDataXcx, purchaseDeatil } from '@/api'
export default {
  name: 'App',
  data() {
    return {
      // 同步弹框显示隐藏
      dialogVisible: false,
      checkedList: [],
      uuidList: [],
      // 详情页动态展示
      showFirst: true,
      showSecond: false,
      // 预览的图片
      srcList: [],
      tableValue: {
        page: 1,
        size: 4,
        labelName: '',
        source: '',
        approveStatus: ''
        // audit: '',
        // keyword: '',
        // moduleType: ''
      },
      // 获取表格数据
      tableData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 获取当前浏览器的高度
      screenHeight: '',
      modelTypeTitle: ''
    }
  },
  created() {
  },
  mounted() {
    this.screenHeight = document.body.clientHeight - 400
    if (this.screenHeight >= 1200) {
      this.tableValue.size = 8
    } else if (this.screenHeight >= 500) {
      this.tableValue.size = 6
    } else {
      this.tableValue.size = 4
    }
    this.getQuoteList()
  },
  methods: {
    // 审核功能
    async passConfirm(id, index) {
      const { data: res } = await quoteData({ id: id, approveStatus: index })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getQuoteList()
    },
    // 获取报价审核列表
    async getQuoteList() {
      const { data: res } = await quoteListApi(this.tableValue)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 获取表单数据
      this.tableData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
      // 确认发布信息标题
      this.tableData.forEach((item, index) => {
        if ((item.frontType === 1 || item.frontType === 2) && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 3 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3 + item.item4
        } else if (item.frontType === 4 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item4
        } else if (item.frontType === 5 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 6 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3
        } else if (item.moduleType === 2) {
          item.frontName = item.labelIdsName
        } else if (item.moduleType === 4) {
          item.frontName = item.item1
        }
      })
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.tableValue.page = val
      this.currentPage = val
      this.getQuoteList()
    },
    // 同步数据功能
    // 同步数据功能
    showDialog(uuids) {
      this.dialogVisible = true
      this.uuidList = [...new Set(this.uuidList)]
    },
    async confirm() {
      this.dialogVisible = false
      // console.log(this.checkedList.length, '长是是是')
      if (this.checkedList.length === 0) {
        this.$message({
          type: 'warning', // success error warning
          message: '请选择同步位置',
          duration: 2000
        })
      } else if (this.checkedList.length === 1) {
        if (this.checkedList[0] === '1') {
          const { data: res } = await synquoteDataDt({ uuids: this.uuidList.join(',') })
          if (res.code !== 0) return this.$message.error(res.msg)
        } else {
          console.log(this.uuidList, '传uuid前')
          const { data: res } = await synquoteDataXcx({ uuids: this.uuidList.join(',') })
          let newUuidList = []
          if (res.code === 0) {
            newUuidList.push(res.data.data.uuids)
            this.uuidList = []
            console.log(newUuidList, '接口获取到uuids')
            const { data: res2 } = await synquoteData({ uuids: newUuidList.join(','), types: this.checkedList.join(',') })
            console.log(res2, '222222')
          }
          // try {
          //   const [{ data: response1 }, { data: response3 }] = await Promise.all([synquoteData({ uuids: this.uuidList.join(','), types: this.checkedList.join(',') }), synquoteDataXcx({ uuids: this.uuidList.join(',') })])
          //   if (response1.code !== 0) return this.$message.error(response1.msg)
          //   if (response3.code !== 0) return this.$message.error(response3.msg)
          //   this.checkedList = []
          //   this.uuidList = []
          //   this.$message.success('同步成功!')
          //   // 处理两个接口的响应数据
          // } catch (error) {
          //   this.$message.error('同步失败！')
          // // 处理错误
          // }
        }
      } else if (this.checkedList.length > 1) {
        try {
          const [{ data: response1 }, { data: response2 }, { data: response3 }] = await Promise.all([synquoteData({ uuids: this.uuidList.join(','), types: this.checkedList.join(',') }), synquoteDataDt({ uuids: this.uuidList.join(',') }), synquoteDataXcx({ uuids: this.uuidList.join(',') })])
          if (response1.code !== 0) return this.$message.error(response1.msg)
          if (response2.code !== 0) return this.$message.error(response2.msg)
          if (response3.code !== 0) return this.$message.error(response3.msg)
          this.checkedList = []
          this.$message.success('同步成功!')
          this.uuidList = []
        // 处理两个接口的响应数据
        } catch (error) {
          // 处理错误
        }
      }
    },
    handleSelectionChange(selection, row) {
      // console.log(selection, '11111')
      if (selection.length === 0) {
        this.uuidList = []
      } else {
        selection.forEach((item) => {
          this.uuidList.push(item.uuid)
        })
      }
    },
    // 详情页显示隐藏
    async showSecondBox(id) {
      const { data: res } = await purchaseDeatil({ id: id })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailData = res.data.data
      this.showFirst = false
      this.showSecond = true
    },
    hideSecondBox() {
      this.showFirst = true
      this.showSecond = false
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 620px;
  }
  @media screen and (max-width: 1440px) {
    height: 500px;
  }
}
.el-card p {
  margin-right: -20px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.el-card .el-col>.el-button {
  width:70px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .el-button--info {
  background-color: rgba(0,0,0,.8);
}
.el-card .el-button--info:hover {
  background-color: rgba(0,0,0,.7);
}
.el-card .el-button--info:active {
  background-color: rgba(0,0,0,.9);
}
.el-card /deep/ .el-input__inner:focus {
  border: 1px solid #000;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  padding: 12px 0;
  width: 80px;
  height: 34px;
  background: rgba(255,255,255,1);
  border-radius: 3px;
  color: #666;
  line-height: 0
}
.el-card .add_btn {
  float: right;
}
.el-card .cancelExamine_btn {
  padding:12px 0;
  width:80px;
  height:34px;
  background:rgba(255,255,255,1);
  border-radius:3px;
  color: #666;
  line-height: 0;
}
.el-card .el-menu--horizontal {
    height: 60px;
    border-bottom: 1px solid #ccc!important;
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: 30px;
  right: 0;
}
.el-table .cell div {
  display: flex;
}
.el-table .cell p {
  margin: 8px 10px;
  width: 8px;
  height: 8px;
  background-color: #B7B7B7;
  border-radius: 50%;
  box-shadow: 1px 1px 1px black,-1px -1px 1px white;
}
.el-table .cell .el-button {
  border: 0;
}
.el-table .cell .passBtn {
  background-color: #009688;
}
.el-table .cell .passBtn:hover {
  background-color: rgba(0,150,136,.7)
}
.el-table .cell .passBtn:active {
  background-color: rgba(0,150,136,.9)
}
.el-table .cell a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.el-table .cell img {
  margin: 0 auto;
  display: block;
  width: 33px;
  height: 33px;
}
.el-menu-demo {
  margin: -20px 0 20px 0;
  font-weight: 400;
}
// 弹出查看窗口样式
.el-dialog__body li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.el-dialog__body li .company_name {
  font-size: 20px;
  color: #01519B;
}
.el-dialog__body p {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.el-dialog__body .float_l {
  display: inline-block;
  width: 265px;
}
.el-dialog__body .float_r {
  display: inline-block;
  width: 265px;
}
.detailsBox p{
    text-align: left;
}
.bg-purple-dark{
  display: flex;
  // width: 800px;
  overflow-x: auto;
  img{
    width: 150px;
    height: 150px;
    margin: 0 15px;
  }
}
</style>
