// 报名后台
<template>
    <div class="collectManage">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <div class="searchBox">
            <p>
              <span>供应商名称 : </span>
              <el-input v-model="orderData.name" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>发起时间 : </span>
              <el-date-picker
                v-model="orderData.startTime"
                type="date"
                placeholder="请选择"
                size="mini"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
              <span style="width: 30px; margin-right: 0">至</span>
              <el-date-picker
                v-model="orderData.endTime"
                type="date"
                placeholder="请选择"
                size="mini"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </p>
          </div>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getRegList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="name" label="供应商名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="createTime" label="报名时间" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="phone" label="联系方式" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="contact" label="联系人" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="remark" label="最近备注" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="rkTime" label="最新备注时间" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="200px" align="center">
                <template slot-scope="scope">
                  <div>
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="openDialog(scope.row)">备注</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <template>
        <el-dialog title="备注信息" :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog" v-dialogDrag>
          <h6>供应商名称: {{orderDetail.name}}</h6>
          <h6>联系人: {{orderDetail.contact}}</h6>
          <h6>报名时间: {{orderDetail.createTime}}</h6>
          <h6>联系方式: {{orderDetail.phone}}</h6>
          <ul>
            <li v-for="(item, index) in  remarkList" :key="index">
              <i>{{item.createTime}}</i><p>{{item.remark}}</p>
              <span>{{item.username}}</span>
            </li>
          </ul>
          <el-input v-model="remark" height="500" type="textarea" maxlength="100" placeholder="请输入备注内容（最多可输入100字）"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button class="keepBtn" @click="addRegRemark()">保 存</el-button>
            <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
          </div>
        </el-dialog>
      </template>
    </div>
</template>
<script>
import { getRegListApi, remarkListApi, addRegRemarkApi } from '@/api'
export default {
  data() {
    return {
      createDialog: false,
      remark: '',
      orderDetail: '',
      // 所有菜单目录
      orderList: [],
      orderData: {
        name: '',
        startTime: '',
        endTime: '',
        page: 1,
        size: 10
      },
      // 订单详情
      remarkList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0
    }
  },
  created() {
    this.getRegList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 保存内容
    async addRegRemark() {
      const { data: res } = await addRegRemarkApi({ regId: this.orderDetail.id, remark: this.remark })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('保存成功!')
      this.getRegList()
      this.createDialog = false
    },
    async openDialog(result) {
      this.remark = ''
      this.orderDetail = result
      const { data: res } = await remarkListApi(result.id)
      this.remarkList = res.data.list
      this.createDialog = true
    },
    // 获取订单列表
    async getRegList() {
      if (this.orderData.startTime === null) this.orderData.startTime = ''
      if (this.orderData.endTime === null) this.orderData.endTime = ''
      const { data: res } = await getRegListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.getRegList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    .addBtn {
      margin-right: 30px;
      width: 106px;
      height: 28px;
    }
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  // .el-switch {
  //   margin-left: 50%;
  //   transform: translateX(-16%);
  // }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 10px!important;
  width: 972px;
  height: 834px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 720px;
    width: 972px;
    h6 {
      margin: 20px 0 20px 15px;
      font-size: 16px;
      font-weight: 500;
    }
    ul {
      margin: 30px 0;
      height: 300px;
      overflow-y: auto;
      li {
        margin-bottom: 10px;
        padding: 0 0 0 20px;
        position: relative;
        width: 90%;
        border-bottom: 1px solid #ccc;
        line-height: 30px;
        i {
          display: inline-block;
          width: 250px;
        }
        p {
          display: inline-block;
          font-size: 20px;
          width: 566px;
          overflow: hidden;
          vertical-align: text-top;
          white-space: pre-wrap;
        }
        span {
          display: block;
          font-size: 16px;
        }
      }
    }
    .el-textarea__inner {
      height: 200px;
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
