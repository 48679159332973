// 个人中心
<template>
    <div class="content">
      <article>
        <header>
          <div class="head_left">
            <div class="head_user">
              <!-- http://adminapi.bmxgj.cn -->
              <!-- https://adminapi.z-srm.com -->
              <el-upload
                class="avatar-uploader"
                action="https://adminapi.z-srm.com/sys/common/upload-pic"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <span v-if="imageUrl" class="el-upload-action" @click.stop="handleRemove()">
                    <i class="el-icon-delete"></i>
                </span>
                <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
              </el-upload>
            </div>
          </div>
          <div class="info">
            <p>个人信息</p>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="82px" class="demo-ruleForm">
              <el-form-item label="姓名:" prop="contact">
                <span>{{ruleForm.contact}}</span>
              </el-form-item>
              <el-form-item label="绑定手机:" prop="mobile">
                <span>{{ruleForm.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}<a href="javascript:;">修改</a></span>
              </el-form-item>
              <el-form-item label="性别:" prop="name">
                <span>{{ruleForm.name}}</span>
              </el-form-item>
              <el-form-item label="绑定邮箱:" prop="email">
                <span>{{ruleForm.email}}</span>
              </el-form-item>
              <el-form-item label="生日:" prop="name">
                <span>{{ruleForm.name}}</span>
              </el-form-item>
              <el-form-item label="微信:" prop="name">
                <a href="javascript:;">绑定</a>
              </el-form-item>
            </el-form>
          </div>
        </header>
        <section class="other">
          <p>其他操作</p>
          <el-button>注册新企业</el-button>
          <el-button>消息设置</el-button>
        </section>
        <section class="my_author">
          <p>我有工作权限的公司</p>
          <el-table :data="tableData" style="width: 100%">
      <el-table-column  prop="address" label="企业名称" width="300">
      </el-table-column>
      <el-table-column  prop="name" label="部门" width="120">
      </el-table-column>
      <el-table-column  prop="date" label="岗位权限" width="100">
      </el-table-column>
      <el-table-column  prop="date" label="工号" width="100">
      </el-table-column>
      <el-table-column  prop="date" label="状态" width="100">
      </el-table-column>
      <el-table-column  prop="date" label="用户类型">
      </el-table-column>
    </el-table>
        </section>
      </article>
    </div>
</template>
<script>
import { } from '@/api'
export default {
  data() {
    return {
      ruleForm: {
        mobile: '',
        email: '',
        contact: '',
        qq: ''
      },
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      // 图片地址
      imageUrl: ''
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    // 获取用户的个人信息
    async getUserInfo() {
      // const { data: res } = await getUserInfoApi({ userId: '', companyId: window.sessionStorage.getItem('companyId'), buyId: '' })
      // this.ruleForm = res.data.user
    },
    // 移除图片
    handleRemove() {
      this.imageUrl = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data.url
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/webp' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    }
  }
}
</script>
<style scoped lang='less'>
article {
  width:100%;
  height:100%;
  background-color: #F4F4F4;
  border-radius:3px;
}
header {
  display: flex;
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.head_left {
  width: 200px;
  height: 200px;
  background:rgba(255,255,255,1);
}
.head_user {
  margin: 40px 60px 0 40px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
// 用户头像
.avatar-uploader{
  margin-top: -4px;
  width: 120PX;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultHead.jpg') no-repeat;
  background-size: 100% 100%;
  box-shadow: 0 0 0 4px rgba(0,0,0,.1);
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 120px;

}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}

.info {
  flex: 1;
  margin: 0 43px 0 20px;
  padding: 5px 20px;
  height: 200px;
  width: 79%;
  background:rgba(255,255,255,1);
}
.info p, .other p, .my_author p {
  margin-top: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0,0,0,.7);
  border-bottom: 1px solid #ccc;
}
.other {
  margin: 10px 0;
  padding: 5px 20px;
  height: 120px;
  width: 95% ;
  background:rgba(255,255,255,1);
}
.other .el-button {
  margin-top: 20px;
  width: 120px;
}
.my_author {
  margin: 10px 0;
  padding: 5px 20px;
  height: 100%;
  width: 95% ;
  background:rgba(255,255,255,1);
}
// 基本表单样式
.el-form {
  margin: 10px;
  height: 130px;
}
.el-form-item {
  float: left;
  margin-bottom: 5px;
  width: 40%;
  height: 40px;
}
.el-form-item__label {
  color: #000!important;
}
.el-form-item span a {
  margin-left: 15px;
  color: #409EFF;
}
.el-form-item a {
  color: #409EFF;
}
</style>
