// 客户详情
<template>
    <div class="editCustomer">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <nav>客户详情</nav>
        <main>
          <el-form ref="ruleForm" :model="editCustomerData" label-width="122px" :rules="rules">
            <h6>基础信息</h6>
            <el-form-item label="供应商名称:" prop="name" :rules="name">
              <el-select disabled placeholder="请输入客户名称" v-model="editCustomerData.name" filterable remote :remote-method="highSeasList">
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, index) in orderList"
                  v-bind:key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会员等级:" prop="userLevel" :rules="userLevel">
              <el-select disabled v-model="editCustomerData.userLevel" placeholder="请选择经营模式">
                <el-option label="体验版" :value="1"></el-option>
                <el-option label="基础版" :value="2"></el-option>
                <el-option label="标准版" :value="3"></el-option>
                <el-option label="专业版" :value="4"></el-option>
                <el-option label="旗舰版" :value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户行业:" prop="industry" :rules="industry">
              <el-select disabled placeholder="请选择所属行业" v-model="editCustomerData.industry" filterable>
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in industryList"
                  v-bind:key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年营业额(万元):" prop="turnover" :rules="turnover">
              <el-input disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="editCustomerData.turnover" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="企业员工数:" prop="staffNumber" :rules="staffNumber">
              <el-input disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="editCustomerData.staffNumber" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="企业经营状况:" prop="manage" :rules="manage">
              <el-select disabled v-model="editCustomerData.manage" placeholder="请选择">
                <el-option label="正常" :value="0"></el-option>
                <el-option label="注销" :value="1"></el-option>
                <el-option label="停业" :value="2"></el-option>
                <el-option label="解散" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主营产品:" prop="mainProducts" :rules="mainProducts">
              <el-input disabled v-model="editCustomerData.mainProducts" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="企业联系人:" prop="contact" :rules="contact">
              <el-input disabled v-model="editCustomerData.contact" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="tel" :rules="tel">
              <el-input disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="editCustomerData.tel" placeholder="请输入"></el-input>
            </el-form-item>
            <h6>企业信息</h6>
            <el-form-item label="营业执照: " class="uploadItem" prop="licensePic" :rules="licensePic">
              <!-- https://api.z-srm.com/ -->
              <!-- http://apisrm.bmxgj.cn/ -->
              <el-upload
                class="avatar-uploader"
                ref="uploadOrder"
                action="https://api.z-srm.com/sys/common/upload"
                :show-file-list="true"
                :before-upload="beforeAvatarUpload"
                :on-success="uploadSuccess"
                :file-list="editCustomerData.licensePic"
                :headers="uploadHeadrs"
                :data="uploadData"
                :on-remove="handleRemove"
                :on-exceed="uploadNumError">
                <img v-if="editCustomerData.licensePic" :src="editCustomerData.licensePic" class="avatar">
                <span v-if="editCustomerData.licensePic" class="el-upload-action" @click.stop="handleRemove()">
                    <i class="el-icon-delete"></i>
                </span>
                <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="法人代表:" prop="legalPerson" :rules="legalPerson">
              <el-input disabled v-model="editCustomerData.legalPerson" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="统一信用代码:" prop="regNumber" :rules="regNumber">
              <el-input disabled oninput="value=value.replace(/[\W]/g,'')" v-model="editCustomerData.regNumber" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="省市区:" prop="chooseAddress">
              <el-cascader disabled ref="cascaderRef" :options="provinceList" :props="defaultData" clearable v-model="editCustomerData.chooseAddress" @change="handleChange($event)" size="mini"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址:" prop="address" :rules="address">
              <el-input disabled maxLength="100" v-model="editCustomerData.address" placeholder="请输入"></el-input>
            </el-form-item>
            <h6>账号信息</h6>
            <el-form-item label="账号(手机号):" prop="username" :rules="username">
              <el-input disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="editCustomerData.username" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password" :rules="password">
              <el-input disabled v-model="editCustomerData.password" placeholder="********"></el-input>
            </el-form-item>
            <el-form-item label="确认密码:" prop="confirmPassword" :rules="confirmPassword">
              <el-input disabled v-model="editCustomerData.confirmPassword" placeholder="********"></el-input>
            </el-form-item>
          </el-form>
        </main>
        <footer>
          <el-button size="mini" type="primary" @click="resetPassword()">重置密码</el-button>
          <el-button size="mini" type="primary" @click="routerJump(1)">编辑</el-button>
          <el-button size="mini" @click="routerJump()">返回</el-button>
        </footer>
      </el-card>
    </div>
</template>
<script>
import { highSeasListApi, editCustomerApi, ocrBusinessLicenseApi, getProvinceAllApi, industryListApi, customerDetailApi, resetPasswordApi } from '@/api'
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      console.log(value, this.editCustomerData.password)
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editCustomerData.password && this.editCustomerData.password !== null) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 上传时的请求头
      uploadHeadrs: {
        token: window.sessionStorage.getItem('token')
      },
      // 上传时的携带参数
      uploadData: {
        uploadType: 'BUSINESS_LICENSE'
      },
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 修改参数
      editCustomerData: {
        id: '',
        userLevel: '',
        industry: '',
        name: '',
        turnover: '',
        staffNumber: '',
        manage: '',
        mainProducts: '',
        contact: '',
        tel: '',
        legalPerson: '',
        regNumber: '',
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        provinceName: '',
        cityName: '',
        areaName: '',
        address: '',
        licensePic: '',
        chooseAddress: '',
        username: '',
        password: '',
        confirmPassword: ''
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '供应商名称不能为空!', trigger: 'blur' },
          { min: 4, message: '最少输入4个字符', trigger: 'blur' },
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        userLevel: [
          { required: true, message: '会员等级不能为空!', trigger: 'blur' }
        ],
        industry: [
          { required: true, message: '客户行业不能为空!', trigger: 'blur' }
        ],
        turnover: [
          { max: 8, message: '最多可输入8个字符', trigger: 'blur' }
        ],
        staffNumber: [
          { max: 5, message: '最多可输入5个字符', trigger: 'blur' }
        ],
        mainProducts: [
          { max: 100, message: '最多可输入100个字符', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '企业联系人不能为空!', trigger: 'blur' },
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '联系方式不能为空!', trigger: 'blur' },
          { max: 15, message: '最多可输入15个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              console.log(value)
              if (value !== '') {
                if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) === false) {
                  callback(new Error('手机号格式不正确!'))
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        licensePic: [
          { required: true, message: '营业执照不能为空!', trigger: 'blur' }
        ],
        legalPerson: [
          { required: true, message: '法人代表不能为空!', trigger: 'blur' },
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        regNumber: [
          { required: true, message: '统一信用代码不能为空!', trigger: 'blur' }
        ],
        chooseAddress: [
          { required: true, message: '省市区不能为空!', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '详细地址不能为空!', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '账号不能为空!', trigger: 'blur' },
          { max: 15, message: '最多可输入15个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              console.log(value)
              if (value !== '') {
                if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) === false) {
                  callback(new Error('手机号格式不正确!'))
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          // { required: true, message: '密码不能为空!', trigger: 'blur' },
          { min: 6, message: '最少输入6个字符', trigger: 'blur' },
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      // 所属行业
      industryList: [],
      // 存储全部省市区列表
      provinceList: [],
      chooseAddress: '',
      // 级联选择器分类
      defaultData: {
        value: 'code',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover'
      },
      orderData: {
        name: '',
        addressAll: '',
        startTime: '',
        endTime: '',
        state: '',
        page: 1,
        size: 10
      },
      orderList: []
    }
  },
  created() {
    this.editCustomerData.id = this.$route.query.id
    this.getIndustryList()
    this.getProvince()
    this.getCustomerDetail()
    this.highSeasList('')
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 300
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 300
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 重置密码
    async resetPassword() {
      const { data: res } = await resetPasswordApi(this.editCustomerData.userId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('重置成功！')
    },
    // 获取公海客户列表
    async highSeasList(val) {
      this.orderData.name = val
      const { data: res } = await highSeasListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
    },
    // 获取客户详情
    async getCustomerDetail() {
      const { data: res } = await customerDetailApi(this.editCustomerData.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.editCustomerData = res.data.company
      this.editCustomerData.chooseAddress = [this.editCustomerData.provinceCode, this.editCustomerData.cityCode, this.editCustomerData.areaCode]
      if (this.editCustomerData.staffNumber === null) {
        this.editCustomerData.staffNumber = ''
      }
      if (this.editCustomerData.turnover === null) {
        this.editCustomerData.turnover = ''
      }
      if (this.editCustomerData.userLevel === 0) {
        this.editCustomerData.userLevel = ''
      }
      this.editCustomerData.staffNumber = this.editCustomerData.staffNumber + ''
      this.editCustomerData.turnover = this.editCustomerData.turnover + ''
    },
    // 级联选择器改变触发
    handleChange(e) {
      let addressName = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels
      this.editCustomerData.provinceCode = e[0]
      this.editCustomerData.cityCode = e[1]
      this.editCustomerData.areaCode = e[2]
      this.editCustomerData.provinceName = addressName[0]
      this.editCustomerData.cityName = addressName[1]
      this.editCustomerData.areaName = addressName[2]
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    // 获取行业表信息
    async getIndustryList() {
      const { data: res } = await industryListApi('')
      if (res.code !== 0) return this.$message.error('获取行业类型失败!')
      this.industryList = res.data.list
    },
    // 上传营业执照成功回调
    uploadSuccess(res, file) {
      this.editCustomerData.licensePic = res.data.url
      this.$refs.uploadOrder.clearFiles()
      this.ocrBusinessLicense()
    },
    // 根据上传的图片识别营业执照
    async ocrBusinessLicense() {
      const { data: res } = await ocrBusinessLicenseApi(this.editCustomerData.licensePic)
      if (res.code !== 0) {
        this.$message.error('图片识别失败请重新上传!')
        return false
      }
      this.editCustomerData.regNumber = res.data.regNum
      this.addCustomerData.company.legalPerson = res.data.person
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/webp' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    },
    // 上传数量超出限制提示
    uploadNumError(file) {
      this.$message.error('只能上传一张图片!')
    },
    // 移除上传的图片
    handleRemove() {
      this.editCustomerData.licensePic = ''
    },
    // 修改客户
    editCustomer() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await editCustomerApi(this.editCustomerData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '保存成功!' })
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    },
    // 路由跳转
    routerJump(val) {
      if (val === 1) {
        this.$router.push({ path: '/editCustomer', query: { id: this.editCustomerData.id } })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  nav {
    padding-bottom: 14px;
    width: 100%;
    border-bottom: 1px solid rgba(230, 232, 235, 1);
    font-size: 18px;
    font-weight: bold;
  }
  main {
    h6 {
      margin: 20px 0 10px;
      display: block;
      width: 100%;
      font-size: 16px;
    }
    .el-form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      color: rgba(0, 0, 0, 0.80);
      /deep/.el-form-item {
        margin: 0 0 20px 0;
        width: 33.3%;
        .el-form-item__label {
          line-height: 32px;
        }
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          padding-left: 0;
          width: calc(100% - 130px);
          line-height: 32px;
          .el-input__icon {
            line-height: 32px;
          }
          .el-input {
            width: 100%;
            height: 32px;
            .el-input__inner {
              height: 32px;
            }
          }
          .el-select {
            width: 100%;
            .el-input__inner {
              height: 32px;
            }
          }
          .el-cascader {
            width: 100%;
          }
        }
      }
      .el-form-item:nth-child(12) {
        width: 100%;
      }
      .avatar-uploader{
        width: 130px;
        height: 130px;
        border-radius: 2%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: url('../../assets/img/defaultPicture.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: top;
      }
      .avatar-uploader-icon{
        font-size: 0;
        color: #fff;
        width: 130px;
        height: 130px;
        line-height: 130px;
        text-align: center;
      }
      .avatar-uploader-icon:hover{
        font-size: 28px;
        background-color: rgba(0, 0, 0, .3);
      }
      .avatar {
        position: relative;
        width: 130px;
        height: 130px;
        display: block;
      }
      .el-upload-action {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 130px;
        height: 130px;
        font-size: 0;
        color: #fff;
        text-align: center;
        line-height: 130px;
      }
      .el-upload-action:hover {
        font-size: 20px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .3)
      }
    }
  }
  footer {
    height: 40px;;
  }
}
</style>
