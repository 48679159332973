// 属性配置
<template>
    <div class="classifyAttribute">
      <!-- 卡片视图区 -->
      <el-card>
        <el-row>
          <h3>基础信息</h3>
          <div class="contentBox">
            <p>
              <span class="labelBox">品类 : </span>
              <span>{{classifyInfo.pname}}</span>
            </p>
            <p>
              <span class="labelBox">品名 : </span>
              <span>{{classifyInfo.cname}}</span>
            </p>
            <p>
              <span class="labelBox">单位 : </span>
              <el-input placeholder="请输入单位" v-model="classifyInfo.unit" size="small"></el-input>
            </p>
            <p v-for="(item, index) in subValList" :key="index">
              <span class="labelBox">属性 : </span>
              <el-input placeholder="请输入属性名" v-model="item.name" size="small"></el-input>
              <span class="labelBox">排序 : </span>
              <el-input style="width: 100px;" placeholder="请输入数字" type="number" v-model="item.sort" size="small"></el-input>
              <i @click="(item.subList).push({ name: '' })" class="el-icon-plus addBtn"></i>
              <i @click="delValList(index)" style="color: red; margin-left: 380px" class="el-icon-delete"></i>
              <u class="natureBox">
                <p style="display: inline-block; margin-bottom: 0;" v-for="(item2, index2) in (item.subList)" :key="index2">
                  <el-input class="minInput" placeholder="请输入" v-model="subValList[index].subList[index2].name" size="mini"></el-input>
                  <i v-if="index2 !== 0" @click.prevent="delValSubList(index, index2)" class="el-icon-delete"></i>
                  <i v-else style="color: #dcdcdc" class="el-icon-delete"></i>
                </p>
              </u>
            </p>
            <el-button @click="confirmAddValSubList()" class="confirmAdd" type="info" size="mini">新增属性</el-button>
            <!-- <el-button :disabled="this.subValList.length === 1 ? true : false" @click="confirmDelValSubList()" type="danger" size="mini">删除属性</el-button> -->
            <p>
              <span class="labelBox">发货时间 : </span>
              <el-checkbox-group v-model="checkDataList" :max="3">
                <el-checkbox label="立即发货"></el-checkbox>
                <el-checkbox v-if="sendTime.oneShow === false" label="one">{{sendTime.oneTime}}天内发货<i @click.prevent="showMinInput(1)" class="el-icon-edit-outline"></i></el-checkbox>
                <el-checkbox v-else label="one">
                  <el-input ref="oneInp" @blur="sendTime.oneShow = false" class="minInput" placeholder="请输入" v-model="sendTime.oneTime" size="mini"></el-input>天内发货
                </el-checkbox>
                <el-checkbox v-if="sendTime.twoShow === false" label="two">{{sendTime.twoTime}}天内发货<i @click.prevent="showMinInput(2)" class="el-icon-edit-outline"></i></el-checkbox>
                <el-checkbox v-else label="two">
                  <el-input ref="twoInp" @blur="sendTime.twoShow = false" class="minInput" placeholder="请输入" v-model="sendTime.twoTime" size="mini"></el-input>天内发货
                </el-checkbox>
                <el-checkbox v-if="sendTime.threeShow === false" label="three">{{sendTime.threeTime}}天内发货<i @click.prevent="showMinInput(3)" class="el-icon-edit-outline"></i></el-checkbox>
                <el-checkbox v-else label="three">
                  <el-input ref="threeInp" @blur="sendTime.threeShow = false" class="minInput" placeholder="请输入" v-model="sendTime.threeTime" size="mini"></el-input>天内发货
                </el-checkbox>
              </el-checkbox-group>
            </p>
          </div>
          <h3 style="margin-top: 20px">支付方式</h3>
          <div class="contentBox">
            <p>
              <span>保证金 : </span>
              <el-input placeholder="请输入" v-model="classifyInfo.bondRate" size="small"></el-input> %
            </p>
            <p>
              <span>日利息 : </span>
              <el-input placeholder="请输入" v-model="classifyInfo.moneyRate" size="small"></el-input> %
            </p>
            <p>
              <span>服务费 : </span>
              <el-input placeholder="请输入" v-model="classifyInfo.serveRate" size="small"></el-input> %
            </p>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row style="margin: 20px 0; text-align: center;">
          <el-button @click="keepBtn()" class="confirmAdd" type="success" size="mini">保存</el-button>
          <el-button @click="cancelBtn()" class="confirmAdd" type="info" size="mini">取消</el-button>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { editClassifyAttributeApi, getClassifyDetailApi } from '@/api'
export default {
  data() {
    return {
      // 获取到的分类id
      classifyId: JSON.parse(this.$route.query.id),
      // 新增的分类信息
      classifyInfo: {
        cateId: '',
        valList: [],
        unit: '',
        moneyRate: '',
        serveRate: '',
        bondRate: '',
        sendTimes: []
      },
      // 分类属性列表
      subValList: [{
        name: '',
        sort: '',
        subList: [{ name: '' }]
      }],
      sendTime: {
        oneTime: '30',
        oneShow: false,
        twoTime: '60',
        twoShow: false,
        threeTime: '90',
        threeShow: false
      },
      // 勾选的发货时间列表
      checkDataList: []
    }
  },
  created() {
    this.getClassifyDetail()
  },
  methods: {
    // 获取分类列表详情
    async getClassifyDetail() {
      const { data: res } = await getClassifyDetailApi(this.classifyId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.classifyInfo = res.data.detail
      this.subValList = this.classifyInfo.valList
      // 第一次循环去掉立即发货
      if (this.classifyInfo.sendTimes.length !== 0) {
        this.classifyInfo.sendTimes.forEach((item, index) => {
          if (item === '立即发货') {
            this.checkDataList.push(item)
            this.classifyInfo.sendTimes.splice(index, 1)
          }
        })
      }
      if (this.classifyInfo.sendTimes.length !== 0) {
        this.classifyInfo.sendTimes.forEach((item2, index2) => {
          if (index2 === 0) {
            this.sendTime.oneTime = item2.replace('天内发货', '')
            this.checkDataList.push('one')
          } else if (index2 === 1) {
            this.sendTime.twoTime = item2.replace('天内发货', '')
            this.checkDataList.push('two')
          } else if (index2 === 2) {
            this.sendTime.threeTime = item2.replace('天内发货', '')
            this.checkDataList.push('three')
          }
        })
      }
    },
    // 保存属性
    async keepBtn() {
      // 清空日期
      this.classifyInfo.sendTimes = []
      // 将获取到的日期转化为合适的格式
      this.checkDataList.forEach((item, index) => {
        if (item === '立即发货') {
          this.classifyInfo.sendTimes.push('立即发货')
        } else if (item === 'one') {
          this.classifyInfo.sendTimes.push(this.sendTime.oneTime + '天内发货')
        } else if (item === 'two') {
          this.classifyInfo.sendTimes.push(this.sendTime.twoTime + '天内发货')
        } else if (item === 'three') {
          this.classifyInfo.sendTimes.push(this.sendTime.threeTime + '天内发货')
        }
      })
      this.classifyInfo.valList = this.subValList
      const { data: res } = await editClassifyAttributeApi(this.classifyInfo)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('保存成功!')
      this.$router.back()
    },
    // 取消
    cancelBtn() {
      this.$router.back()
    },
    // 添加属性
    confirmAddValSubList() {
      this.subValList.push({ name: '', sort: '', subList: [{ name: '' }] })
    },
    // 删除属性
    delValList(index) {
      this.subValList.splice(index, 1)
    },
    // 添加属性
    confirmDelValSubList() {
      this.subValList.splice(this.subValList.length - 1, 1)
    },
    // 删除对应的属性值
    delValSubList(index, index2) {
      this.subValList[index].subList.splice(index2, 1)
    },
    // 展示发货时间修改框同时获得焦点
    showMinInput(key) {
      if (key === 1) {
        this.sendTime.oneShow = true
        this.$nextTick(() => {
          this.$refs.oneInp.focus()
        })
      } else if (key === 2) {
        this.sendTime.twoShow = true
        this.$nextTick(() => {
          this.$refs.twoInp.focus()
        })
      } else {
        this.sendTime.threeShow = true
        this.$nextTick(() => {
          this.$refs.threeInp.focus()
        })
      }
    }
  }
}
</script>
<style scoped lang='less'>
/* 去掉el-input type为number时的上下箭头 */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
-moz-appearance: textfield !important;
}
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 1440px) {
    height: 100%;
  }
}
.classifyAttribute {
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .contentBox {
    margin-top: 15px;
    padding: 15px 20px;
    border: 1px solid #cccccc;
    font-size: 14px;
    font-weight: 500;
    p {
      margin-bottom: 20px;
      .labelBox {
        margin-right: 10px;
        display: inline-block;
        width: 68px;
        text-align: right;
      }
      .el-input {
        height: 32px;
        width: 230px;
      }
      .addBtn {
        margin-left: 12px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: rgba(51, 158, 191, 1);
      }
      .el-checkbox-group {
        display: inline-block;
        .el-checkbox {
          .minInput {
            margin-right: 5px;
            width: 60px;
            height: 24px;
            /deep/ .el-input__inner {
              height: 24px;
              line-height: 24px;
            }
          }
          i {
            margin-left: 5px;
          }
        }
      }
    }
    .natureBox {
      display: block;
      margin: 10px 78px 10px;
      padding: 10px 15px 10px;
      width: 800px;
      background: rgba(252, 252, 252, 1);
      border: 1px solid rgba(220, 220, 220, 1);
      white-space: pre-wrap;
      i {
        margin: 0 15px 0 5px;
        cursor: pointer;
      }
    }
    .confirmAdd {
      margin: -10px 78px 20px;
    }
  }
}
</style>
