// 新增资讯
<template>
    <div class="collectManage">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <div class="searchBox">
            <el-button class="addBtn lightDeepStyle" size="mini" @click="createOpen()">+ 新增资讯</el-button>
            <p>
              <span>资讯标题 : </span>
              <el-input v-model="orderData.title" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>资讯分类 : </span>
              <el-select style="width: 160px;" v-model="orderData.cate" placeholder="请选择" size="mini" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="品牌动态" value="1"></el-option>
                <el-option label="行业干货" value="2"></el-option>
              </el-select>
            </p>
            <p>
              <span>首页展示 : </span>
              <el-select style="width: 160px;" v-model="orderData.isFirst" placeholder="请选择" size="mini" clearable>
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </p>
            <p>
              <span>是否启用 : </span>
              <el-select style="width: 160px;" v-model="orderData.isDisplay" placeholder="请选择" size="mini" clearable>
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </p>
          </div>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getWebList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="title" label="资讯标题" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="cate" label="资讯分类" min-width="60px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.cate === 1 ? '品牌动态' : '行业干货'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="isFirst" label="首页展示" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.isFirst === 1 ? '是' : '否'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="isDisplay" label="启用状态" min-width="60px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.isDisplay"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @input="controlSwitch(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="200px" align="center">
                <template slot-scope="scope">
                  <div>
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="editOpen(scope.row)">编辑</span>
                    <span style="cursor: pointer; color: red" size="mini" @click.stop="delWebInfo(scope.row.id)">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <template>
        <el-dialog :title="addMessage === true ? '新增资讯' : '修改资讯'" :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog" v-dialogDrag>
          <h6>资讯标题</h6>
          <el-input v-model="keepInfoData.title" placeholder="输入资讯标题" style="padding-left: 10px; box-sizing: border-box"></el-input>
          <h6>资讯摘要</h6>
          <el-input v-model="keepInfoData.brief" placeholder="输入资讯摘要" style="padding-left: 10px; box-sizing: border-box"></el-input>
          <h6>资讯分类</h6>
          <el-select v-model="keepInfoData.cate" placeholder="请选择" size="mini">
            <el-option label="品牌动态" :value="1"></el-option>
            <el-option label="行业干货" :value="2"></el-option>
          </el-select>
          <h6>资讯封面图</h6>
          <!-- https://adminapi.bmxgj.cn -->
          <!-- https://adminapi.z-srm.com -->
          <el-upload
            class="avatar-uploader"
            :headers="headers"
            action="https://adminapi.z-srm.com/sys/common/upload-pic"
            :show-file-list="false"
            :data="{uploadType: 'BUSINESS_LICENSE'}"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove">
            <img v-if="keepInfoData.pic" :src="keepInfoData.pic" class="avatar">
            <span v-if="keepInfoData.pic" class="el-upload-action" @click.stop="handleRemove()">
                <i class="el-icon-delete"></i>
            </span>
            <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
          </el-upload>
          <div class="posBox">
            <div>
              <h6>首页展示</h6>
              <el-switch
                v-model="keepInfoData.isFirst"
                :active-value="1"
                :inactive-value="0"
                active-color="rgba(27, 199, 181, 1)"
                inactive-color="#eee">
              </el-switch>
            </div>
            <div>
              <h6>启用状态</h6>
              <el-switch
                v-model="keepInfoData.isDisplay"
                :active-value="1"
                :inactive-value="0"
                active-color="rgba(27, 199, 181, 1)"
                inactive-color="#eee">
              </el-switch>
            </div>
          </div>
          <h6>资讯内容</h6>
          <quill-editor
            ref="myQuillEditor"
            v-model="keepInfoData.content"
            :options="editorOption"
          />
          <div slot="footer" class="dialog-footer">
            <el-button class="keepBtn" @click="keepInfo()">保 存</el-button>
            <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
          </div>
        </el-dialog>
      </template>
    </div>
</template>
<script>
import { getWebListApi, editWebInfoApi, delWebInfoApi, addWebInfoApi, webInfoDetailApi } from '@/api'
export default {
  data() {
    return {
      headers: {
        'token': window.sessionStorage.getItem('token')
      },
      // 判断是创建消息还是编辑消息
      addMessage: true,
      // 创建消息
      createDialog: false,
      // 自定义富文本内容
      editorOption: {
        theme: 'snow',
        placeholder: '点击开始输入正文',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
            [{ script: 'sub' }, { script: 'super' }], // 下角标，上角标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 颜色选择
            [{ align: [] }], // 居中
            [('link', 'image')] // 控制能上传的内容
          ]
        }
      },
      // 保存消息传参
      keepInfoData: {
        id: '',
        cate: '',
        title: '',
        brief: '',
        content: '',
        pic: '',
        isFirst: 1,
        isDisplay: 1
      },
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderData: {
        cate: '',
        isFirst: '',
        isDisplay: '',
        title: '',
        page: 1,
        size: 10
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      orderList: []
    }
  },
  created() {
    this.getWebList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 保存资讯列表
    async keepInfo() {
      if (this.addMessage === true) {
        const { data: res } = await addWebInfoApi(this.keepInfoData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('新增成功')
      } else {
        const { data: res } = await editWebInfoApi(this.keepInfoData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('修改成功')
      }
      this.createDialog = false
      this.getWebList()
    },
    // 移除图片
    handleRemove() {
      this.keepInfoData.pic = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.keepInfoData.pic = res.data.url
    },
    // 点击创建时触发
    createOpen() {
      this.addMessage = true
      // 清空弹框内容再打开
      this.keepInfoData = {
        id: '',
        cate: '',
        title: '',
        brief: '',
        content: '',
        pic: '',
        isFirst: 1,
        isDisplay: 1
      }
      this.createDialog = true
    },
    // 点击修改时触发
    async editOpen(val) {
      // 先获取资讯内容
      const { data: res } = await webInfoDetailApi(val.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addMessage = false
      // 设置弹框内容再打开
      this.keepInfoData = {
        id: val.id,
        cate: val.cate,
        title: val.title,
        brief: val.brief,
        content: res.data.detail.content,
        pic: val.pic,
        isFirst: val.isFirst,
        isDisplay: val.isDisplay
      }
      this.createDialog = true
    },
    // 删除该条资讯
    delWebInfo(id) {
      this.$confirm('此操作将删除该条资讯', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delWebInfoApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getWebList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 点击状态修改时触发
    async controlSwitch(result) {
      const { data: res } = await editWebInfoApi(result)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (result.isDisplay === 0) {
        this.$message.success('禁用成功!')
      } else {
        this.$message.success('启用成功!')
      }
    },
    // 获取订单列表
    async getWebList() {
      const { data: res } = await getWebListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.getWebList()
    },
    // 重置搜索条件
    rejectSearch() {
      this.orderData = {
        cate: '',
        isFirst: '',
        isDisplay: '',
        title: '',
        page: 1,
        size: 10
      }
      this.getWebList()
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'collectDetail', query: { orderNum: result.orderNum } })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    .addBtn {
      margin-right: 30px;
      width: 106px;
      height: 28px;
    }
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  // .el-switch {
  //   margin-left: 50%;
  //   transform: translateX(-16%);
  // }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 10px!important;
  width: 972px;
  height: 834px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 720px;
    width: 972px;
    h6 {
      margin: 10px 0 10px 15px;
      font-size: 16px;
      font-weight: 500;
    }
    .posBox {
      position: absolute;
      top: 140px;
      left: 400px;
      width: 300px;
      height: 100px;
      h6 {
        margin-top: 50px;
        margin-left: 0;
      }
      .el-switch {
        margin-left: 10px;
      }
    }
    .avatar-uploader{
      margin-top: 20px;
      width: 225px;
      height: 150px;
      border-radius: 2%;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      background: url('../../assets/img/defaultPicture.png') no-repeat;
      background-size: 100% 100%;
      vertical-align: top;
    }
    .avatar-uploader-icon{
      font-size: 0;
      color: #fff;
      width: 225px;
      height: 150px;
      line-height: 150px;
      text-align: center;
    }
    .avatar-uploader-icon:hover{
      font-size: 28px;
      background-color: rgba(0, 0, 0, .3);
    }
    .avatar {
      position: relative;
      width: 225px;
      height: 150px;
      display: block;
    }
    .el-upload-action {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 225px;
      height: 150px;
      font-size: 0;
      color: #fff;
      text-align: center;
      line-height: 150px;
    }
    .el-upload-action:hover {
      font-size: 20px;
      background-color: #000;
      background-color: rgba(0, 0, 0, .3)
    }
    .el-select {
      height: 38px;
      border-bottom: 1px solid #E6E6E6;
      .el-input__inner {
        // width: 602px;
        height: 38px;
        font-size: 14px;
        // border: 1px solid #E6E6E6;
      }
    }
    .ql-toolbar.ql-snow {
      // border-top: 0;
    }
    .ql-container {
      height: 200px!important;
      .ql-editor {
        font-size: 16px;
        width: 100%;
        p {
          width: 100%;
        }
      }
    }
    .el-input {
      line-height: 0;
      border-bottom: 1px solid #E6E6E6;
      .el-input__inner {
        border: 0;
        font-size: 16px;
      }
    }
    >p {
      margin: 0;
      padding: 14px 25px;
      font-size: 14px;
      border-top: 1px solid rgba(230, 230, 230, 1);
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
