// 客户账号管理 客户列表
<template>
    <div class="customerList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <p>
            <span>供应商名称 : </span>
            <el-input v-model="customerData.name" placeholder="请输入名称" type="text" size="small" maxlength="40"></el-input>
            <span>创建日期 : </span>
            <el-date-picker
              v-model="customerData.startTime"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span style="width: 30px; margin-right: 0">至</span>
            <el-date-picker
              v-model="customerData.endTime"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span>剩余时间 : </span>
            <el-select style="width: 160px;" v-model="customerData.expDay" placeholder="请选择" size="mini" clearable>
              <el-option label="已过期" :value="1"></el-option>
              <el-option label="小于一周" :value="2"></el-option>
              <el-option label="小于一月" :value="3"></el-option>
            </el-select>
            <span>状态 : </span>
            <el-select style="width: 160px;" v-model="customerData.ifCancel" placeholder="请选择" size="mini" clearable>
              <el-option label="正常" :value="0"></el-option>
              <el-option label="禁用" :value="1"></el-option>
            </el-select>
          </p>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getCustomerList()">查询</el-button>
            <el-button class="keepBtn query" size="mini" @click="routerJump(1, '')">新增客户</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="customerList" border style="width: 100%;">
              <el-table-column prop="name" label="供应商名称" min-width="140px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click.stop="routerJump(2, scope.row)">{{scope.row.name}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="userLevel" label="会员等级" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.userLevel === 1">体验版</span>
                    <span v-else-if="scope.row.userLevel === 2">基础版</span>
                    <span v-else-if="scope.row.userLevel === 3">标准版</span>
                    <span v-else-if="scope.row.userLevel === 4">专业版</span>
                    <span v-else-if="scope.row.userLevel === 5">旗舰版</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="companyName" label="客户行业" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.industryName !== null">{{(scope.row.industryPname === null ? '' : scope.row.industryPname + '/') + scope.row.industryName}}</span>
                    <span v-else>暂无</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="contact" label="企业联系人" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="tel" label="联系方式" min-width="100px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.tel === null || scope.row.tel === '' ? '暂无' : scope.row.tel}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="expDay" label="剩余时间" min-width="80px" show-overflow-tooltip align="center" fixed="right">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.expDay === null || scope.row.expDay === '' ? '暂无' : scope.row.expDay + '天'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="ifCancel" label="状态" min-width="80px" show-overflow-tooltip align="center" fixed="right">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.ifCancel === false ? '正常' : '禁用'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100px" align="center" fixed="right">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-between">
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click.stop="routerJump(3, scope.row)">修改</span>
                    <span v-if="scope.row.ifCancel === true" @click="changeStatus(scope.row.id, 0)" style="cursor: pointer; color: green" size="mini">启用</span>
                    <span v-else @click="changeStatus(scope.row.id, 1)" style="cursor: pointer; color: rgba(235,19,19, 1)" size="mini">禁用</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="customerData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { customerListApi, customerDetailApi, editCustomerApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      customerData: {
        page: 1,
        size: 11,
        ifCancel: '',
        startTime: '',
        endTime: '',
        expDay: '',
        name: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      customerList: []
    }
  },
  created() {
    this.getCustomerList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 修改状态
    async changeStatus(id, status) {
      const { data: res } = await customerDetailApi(id)
      let EditCustomerData = res.data.company
      EditCustomerData.ifCancel = status
      const { data: ress } = await editCustomerApi(EditCustomerData)
      if (ress.code !== 0) return this.$message.error(ress.msg)
      if (status === 0) {
        this.$message.success({ duration: 1000, message: '启用成功!' })
      } else {
        this.$message.success({ duration: 1000, message: '禁用成功!' })
      }
      this.getCustomerList()
    },
    // 获取客户列表
    async getCustomerList() {
      if (this.customerData.startTime === null) this.customerData.startTime = ''
      if (this.customerData.endTime === null) this.customerData.endTime = ''
      console.log(this.customerData.startTime)
      if ((this.customerData.startTime + '').length === 10) {
        this.customerData.startTime = this.customerData.startTime + ' 00:00:00'
      }
      if ((this.customerData.endTime + '').length === 10) {
        this.customerData.endTime = this.customerData.endTime + ' 23:59:59'
      }
      const { data: res } = await customerListApi(this.customerData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.customerList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.customerData.page = val
      this.currentPage = val
      this.getCustomerList()
    },
    // 路由跳转
    routerJump(index, result) {
      if (index === 1) {
        this.$router.push({ path: 'addCustomer', query: { id: result.id } })
      } else if (index === 2) {
        this.$router.push({ path: 'customerDetail', query: { id: result.id } })
      } else {
        this.$router.push({ path: 'editCustomer', query: { id: result.id } })
      }
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  >p {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    span {
      margin-right: 5px;
      display: inline-block;
      line-height: 28px;
    }
    .el-input {
      margin-right: 10px;
      width: 160px;
    }
    .el-select {
      margin-right: 5px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .priceQuotationDialog {
  .el-dialog {
    height: 600px;
    .el-dialog__body {
      height: 535px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
.priceQuotation>.el-table {
  margin-top: 60px;
}
.priceQuotation .dialogBody {
  height: 450px;
  overflow-y: auto;
}
.priceQuotation .el-dialog .el-table {
  margin-top: 0;
  text-align: center;
  .editBtn {
    background-color: rgba(42,49,59,.7);
    color: #fff;
  }
  .cancelBtn {
    background-color: #ccc;
    color: #fff;
  }
  .cancelBtn:hover {
    border: 1px solid #ccc;
  }
}
.priceQuotation .el-dialog .addText {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  .addValueInput {
    margin: 0 20px;
    margin-right: 10px;
    display: inline-block;
    width: 110px;
  }
  .addNameInput {
    margin: 0 20px;
    display: inline-block;
    width: 152px;
  }
  .addkeyInput {
    margin: 0 14px;
    display: inline-block;
  }
  .keepBtn {
    margin-left: 35px;
    background-color: #aacfcf;
    border: 0;
    color: #fff
  }
}
/deep/ .el-dialog {
  width: 480px!important;
  height: 700px!important;
  .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #000;
    color: #000;
    .el-dialog__title {
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    padding: 12px 16px;
    height: calc(100% - 60px);
    p {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      // height: 32px;
      line-height: 32px;
      >span {
        margin-right: 5px;
        color: #000;
        font-size: 14px;
        min-width: 80px;
        text-align: right;
      }
      .el-radio {
        line-height: 32px;
      }
      .el-textarea__inner {
        min-height: 100px!important;
      }
      .showInputBox {
        position: absolute;
        top: 0;
        left: 68px;
        width: calc(100% - 100px);
        z-index: 9999;
      }
      .el-cascader, .el-tag {
        margin-top: 30px;
        display: inline-block;
        width: calc(100% - 100px);
        .el-input__inner {
          height: 32px;
        }
      }
    }
  }
  .dialogFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 14px 20px;
    height: 56px;
    width: 100%;
    border-top: 1px solid #cccccc;
  }
}
/deep/ .mouldDialog .el-dialog {
  height: 400px!important ;
}
.avatar-uploader{
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultPicture.png') no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}
</style>
