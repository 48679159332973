// 公海客户
<template>
    <div class="collectManage">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <div class="searchBox">
            <!-- 测试服 'https://adminapi.bmxgj.cn' -->
            <!-- 正式服 'https://adminapi.z-srm.com' -->
            <el-button @click="routerJump()" class="lightDeepStyle addBtn" size="mini">数据导入</el-button>
            <p>
              <span>企业名称 : </span>
              <el-input v-model="orderData.name" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>联系地址 : </span>
              <el-input v-model="orderData.addressAll" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>创建日期 : </span>
              <el-date-picker
                v-model="orderData.startTime"
                type="date"
                placeholder="请选择"
                size="mini"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
              <span style="width: 30px; margin-right: 0">至</span>
              <el-date-picker
                v-model="orderData.endTime"
                type="date"
                placeholder="请选择"
                size="mini"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </p>
            <p>
              <span>捞取状态 : </span>
              <el-select v-model="orderData.state" filterable placeholder="请选择" size="mini">
                <el-option label="全部" value=""></el-option>
                <el-option label="未捞取" :value="0"></el-option>
                <el-option label="已捞取" :value="1"></el-option>
              </el-select>
            </p>
          </div>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="highSeasList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
          <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
            <el-table-column prop="name" label="企业名称" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="address" label="地址" min-width="120px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="visitNum" label="跟进记录" min-width="80px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span>{{scope.row.visitNum + '条记录'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="contactNum" label="联系人" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span>{{scope.row.contactNum + '位'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="buyNum" label="采购需求" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span>{{scope.row.buyNum + '种'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sellNum" label="供应产品" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span>{{scope.row.sellNum + '种'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="捞取状态" min-width="60px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.state === 0">未捞取</span>
                  <span v-else>已捞取</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200px" align="center">
              <template slot-scope="scope">
                <div>
                  <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="openDialog(scope.row.id)">分配</span>
                  <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="routerPush(scope.row.id)">编辑</span>
                  <span style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 10px" size="mini" @click.stop="delSeas(scope.row.id)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <template>
        <el-dialog title="分配客户" :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog">
          <p>负责人</p>
          <el-select v-model="chooseData.wxUserId" filterable placeholder="请选择" size="mini">
            <el-option v-for="(item, index) in userList" :key="index" :label="item.nickName" :value="item.wxUserId"></el-option>
          </el-select>
          <div slot="footer" class="dialog-footer">
            <el-button class="keepBtn" @click="chooseSeas()">保 存</el-button>
            <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
          </div>
        </el-dialog>
      </template>
    </div>
</template>
<script>
import { highSeasListApi, chooseSeasApi, delSeasApi, getDeptUserApi } from '@/api'
export default {
  data() {
    return {
      // 分配内容
      chooseData: {
        companyId: '',
        wxUserId: ''
      },
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 当前用户的deptId 企业id
      deptId: JSON.parse(window.sessionStorage.getItem('info')).deptId,
      headers: {
        'token': window.$cookies.get('token')
      },
      // 负责人列表
      userList: [],
      createDialog: false,
      remark: '',
      orderDetail: '',
      // 所有菜单目录
      orderList: [],
      orderData: {
        name: '',
        addressAll: '',
        startTime: '',
        endTime: '',
        state: '',
        page: 1,
        size: 10
      },
      // 订单详情
      remarkList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0
    }
  },
  created() {
    this.highSeasList()
    console.log(this.deptId, '123456')
    this.getDeptUser()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 根据部门获取用户列表(负责人列表)
    async getDeptUser() {
      const { data: res } = await getDeptUserApi({ id: this.deptId, page: 1, size: 9999 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userList = res.data.list
    },
    // 删除公海客户
    delSeas(id) {
      this.$confirm('是否确认删除当前公海客户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delSeasApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '删除成功!' })
        this.highSeasList()
      }).catch(() => {
      })
    },
    // 详情跳转
    routerPush(id) {
      this.$router.push({ path: './highSeasDetail', query: { id: id } })
    },
    // 导入页面跳转
    routerJump() {
      this.$router.push({ path: '/uploadSeas' })
    },
    // 上传文件成功回调
    uploadSuccess(res) {
      this.$message.success({ duration: 1000, message: '上传成功!' })
      this.$refs.uploadOrder.clearFiles()
      this.highSeasList()
    },
    // 上传前大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 保存分配
    async chooseSeas() {
      const { data: res } = await chooseSeasApi({ companyId: this.chooseData.companyId, wxUserId: this.chooseData.wxUserId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('保存成功!')
      this.highSeasList()
      this.createDialog = false
    },
    // 打开编辑弹框
    openDialog(id) {
      this.chooseData.companyId = id
      this.createDialog = true
    },
    // 获取订单列表
    async highSeasList() {
      if (this.orderData.startTime === null) this.orderData.startTime = ''
      if (this.orderData.endTime === null) this.orderData.endTime = ''
      const { data: res } = await highSeasListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.highSeasList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    .addBtn {
      margin-right: 30px;
      width: 106px;
      height: 28px;
    }
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  // .el-switch {
  //   margin-left: 50%;
  //   transform: translateX(-16%);
  // }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 200px!important;
  width: 472px;
  height: 184px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 472px;
    p {
      margin-right: 10px;
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
