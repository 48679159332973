// 企业审核
<template>
  <div class="auditBox">
    <nav>
      <h5>企业审核</h5>
      <div>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(2, '')"><i class="el-icon-plus"></i>产品入库</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="wareData.companyName" placeholder="公司名称搜索" @input="getWarehouseList" prefix-icon="el-icon-search"></el-input>
      <el-select v-model="wareData.status" placeholder="状态 " size="mini" clearable @change="getWarehouseList">
        <el-option label="未审核" :value="1"></el-option>
        <el-option label="已审核" :value="2"></el-option>
        <el-option label="驳回" :value="3"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="warehouseList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="name" label="企业名称" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="businessNo" label="统一社会信用代码" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="legalPerson" label="法人代表" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.legalPerson === '' || scope.row.legalPerson === null ? '暂未填写' : scope.row.legalPerson}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="address" label="住所" min-width="80px" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="contact" label="联系人" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.contact === '' || scope.row.contact === null ? '暂未填写' : scope.row.contact}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="tel" label="联系电话" min-width="100px" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="状态" min-width="100px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.status === 1 ? '未审核' : scope.row.status=== 2 ? '已审核' : '驳回'}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="" label="操作" min-width="150px" align="center">
          <template slot-scope="scope" >
            <div style="display: flex; justify-content: center;">
                <el-button type="text" @click="detailFn(scope.row.id)" style="margin-right: 10px;">查看</el-button>
            <div v-if="scope.row.status===1">
                <el-button type="text"  @click="passFn(scope.row.id,2)">通过</el-button>
                <el-button type="text" @click="passFn(scope.row.id,3)">不通过</el-button>
            </div>
            <el-button type="text" v-else-if="scope.row.status===2" @click="reload(scope.row.id)">重新认证</el-button>
            </div>
          </template>
        </el-table-column>
        <!-- <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div> -->
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="wareData.size"
        :total="total">
      </el-pagination>
    </main>
       <!-- 重新认证验证弹框 -->
       <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>是否重新认证</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="reloadOk">确 定</el-button>
  </span>
       </el-dialog>
       <!-- 通过不通过弹框 -->
       <el-dialog
        title="提示"
        :visible.sync="dialogVisible2"
        width="30%"
        :before-close="handleClose">
        <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="reloadData.remark">
        </el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="passNo">取 消</el-button>
            <el-button type="primary" @click="passOK">确 定</el-button>
        </span>
        </el-dialog>
  </div>
</template>
<script>
import { auditListApi, auditNodeApi } from '@/api'
export default {
  name: 'outManage',
  data() {
    return {
      // 重新认证二次验证
      dialogVisible: false,
      dialogVisible2: false,
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      wareData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        companyName: '',
        status: ''
      },
      chooseTime: '',
      // 获取仓库列表参数
      reloadData: {
        id: null,
        // 当前页条数
        remark: '',
        status: null
      },
      // 仓库列表
      storeHouseList: [],
      // 物料管理列表
      warehouseList: [],
      // 列表动态高度
      tableHeight: ''
    }
  },
  created() {
  },
  mounted() {
    this.getWarehouseList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  activated() {
    if (this.$route.meta.isNeedRefresh || this.isFirstEnter) {
      this.getWarehouseList()
    }
    this.isFirstEnter = false
    this.$route.meta.isNeedRefresh = true
  },
  methods: {
    // 路由跳转
    routerJump(val) {

    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.wareData.size = val
      this.wareData.page = 1
      this.getWarehouseList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.wareData.page = val
      this.currentPage = val
      this.getWarehouseList('reset')
    },
    // 获取库存列表
    async getWarehouseList(reset) {
      if (reset !== 'reset') {
        this.wareData.page = 1
      }
      const { data: res } = await auditListApi(this.wareData)
      // 获取表单数据
      this.warehouseList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    detailFn(id) {
      this.$router.push({
        path: '/enterpriseAuditDetail',
        query: {
          id: id
        }
      })
    },
    reload(id) {
      this.dialogVisible = true
      this.reloadData.id = id
      this.reloadData.status = 1
    },
    // 重新认证
    async reloadOk() {
      const { data: res } = await auditNodeApi(this.reloadData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.dialogVisible = false
      this.getWarehouseList()
    },
    passFn(id, status) {
      this.dialogVisible2 = true
      this.reloadData.id = id
      this.reloadData.status = status
    },
    async passOK() {
      const { data: res } = await auditNodeApi(this.reloadData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.dialogVisible2 = false
      this.reloadData.remark = ''
      this.getWarehouseList()
    },
    passNo() {
      this.dialogVisible2 = false
      this.reloadData.remark = ''
    }
  }
}
</script>
<style scoped lang='less'>
.auditBox {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }
}
</style>
