// 新增客户
<template>
    <div class="addInform">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-breadcrumb separator="/">
             <el-breadcrumb-item :to="{ path: '/informManage' }">通知配置</el-breadcrumb-item>
             <el-breadcrumb-item  class="nowPage" style="color: rgba(18, 62, 131, 1)">新增通知</el-breadcrumb-item>
        </el-breadcrumb>
        <nav>新增通知</nav>
        <div class="searchBox"><span>功能:</span><el-input v-model="getData.name" @input="getOrderList" placeholder="请输入内容"></el-input></div>
        <main>
            <div class="headerBox">
                <h5>流程节点</h5>
                <el-button type="primary" @click="addNode">新增</el-button>
            </div>
            <div class="contentBox">
                <el-table
                :data="list"
                style="width: 100%">
                <el-table-column
                    prop="name"
                    label="节点名称"
                    width="180" align="center">
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="接收人"
                    width="180" align="center">
                </el-table-column>
                <el-table-column
                    prop="websiteContent"
                    label="站内消息内容" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.websiteTitle+scope.row.websiteContent}}</span>
                </template>
                </el-table-column>
                <el-table-column
                    prop="messageId"
                    label="短信消息ID" align="center">
                </el-table-column>
                <el-table-column
                    prop="enableStatus"
                    label="状态" align="center">
                    <template slot-scope="scope">
                        <el-switch
                        :value="scope.row.enableStatus === 1"
                        @input="toggleIsOpen(scope.row, $event)"
                        active-text="开启"
                        inactive-text="禁用">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteFn(scope.row.id)">删除</el-button>
                    <el-button type="text" size="small" @click="updateFn(scope.row.id)">编辑</el-button>
                </template>
                </el-table-column>
                </el-table>
            </div>
        </main>
        <el-dialog :title='isTitle' :visible.sync="isShow">
          <div class="contentDiv">
            <p>
              <span><i style="color: red;">*</i>节点名称 : </span>
              <el-select style="width: 160px;" v-model="form.pointCode" placeholder="请选择" size="mini" clearable  @input="memeberFn">
                <el-option v-for="(item, index) in selectList" :key="index" :label="item.pointName" :value="item.pointCode"></el-option>
              </el-select>
            </p>
            <p><span>接收人：</span>{{ form.userName }} <span>(自动读取)</span> </p>
            <div class="typeBox">
              <p>通知类型</p>
              <div class="typeSmall">
                <div class="top">
                  <p>
                    <el-checkbox v-model="isMessage" @change="messageFn1">站内信息:</el-checkbox>
                  </p>
                  <div>
                    <p>
                      <span><i style="color: red;">*</i>标题：</span>
                      <el-input style="width: 160px;" v-model="form.websiteTitle" placeholder="请输入标题"></el-input>
                    </p>
                    <p>
                      <span><i style="color: red;">*</i>内容：</span>
                      <el-input style="width: 160px;" v-model="form.websiteContent" placeholder="请输入内容"></el-input>
                    </p>
                  </div>
                </div>
                <div class="down">
                  <p>
                    <el-checkbox v-model="isNote" @change="messageFn2">短信:</el-checkbox>
                  </p>
                  <p>
                    <span><i style="color: red;">*</i>短信ID：</span>
                      <el-input style="width: 160px;" v-model="form.messageId" placeholder="请输入短信ID"></el-input>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="isShow = false">取 消</el-button>
            <el-button type="primary" @click="confirmFn">确 定</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
</template>
<script>
import { nodeListApi, informStartApi, informStopApi, informNodeListApi, informNodeDelApi, informAddNodeApi, informDetailNodeApi, informUpdateNodeApi } from '@/api'
export default {
  data() {
    return {
      isTitle: '新增通知节点',
      isShow: false,
      getData: {
        name: '',
        configId: this.$route.query.pointCode
      },
      typelist: [],
      // 节点列表
      list: [],
      // 接收人字段
      names: '',
      // 新增列表
      form: {
        pointCode: '',
        type: '',
        messageId: '',
        websiteTitle: '',
        websiteContent: '',
        configId: this.$route.query.pointCode,
        userName: ''
      },
      // 新增节点列表
      selectList: [],
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 验证短信
      isNote: false,
      // 验证信息
      isMessage: false,
      detailData: {}
    }
  },
  created() {
    this.getVipListFn()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 300
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 300
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 请求节点列表
    async getVipListFn() {
      const { data: res } = await nodeListApi(this.getData)
      if (res.code === 0) {
        this.list = res.data
      } else {
        this.$message({
          type: 'error', // success error warning
          message: '列表获取失败',
          duration: 200
        })
      }
    },
    // 启用禁用
    async toggleIsOpen(item, value) {
      console.log(item, value)
      item.enableStatus = value ? 1 : 0
      if (value === true) {
        // 启用接口
        const { data: res } = await informStartApi(item.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.getVipListFn()
      } else {
        // 禁用接口
        const { data: res } = await informStopApi(item.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.getVipListFn()
      }
    },
    // 新增节点
    async addNode() {
      this.form = {
        pointCode: '',
        type: '',
        messageId: '',
        websiteTitle: '',
        websiteContent: '',
        configId: this.$route.query.pointCode,
        name: ''
      }
      this.isMessage = false
      this.isNote = false
      this.isShow = true
      const { data: res } = await informNodeListApi(this.getData.configId)
      this.selectList = res.data
    },
    // 接收人数据回显
    memeberFn() {
      this.selectList.forEach(item => {
        if (item.pointCode === this.form.pointCode) {
          this.form.userName = item.pointUser
        }
      })
    },
    // 节点搜索
    async getOrderList() {
      const { data: res } = await nodeListApi(this.getData)
      if (res.code === 0) {
        this.list = res.data
      } else {
        this.$message({
          type: 'error', // success error warning
          message: '列表获取失败',
          duration: 200
        })
      }
    },
    // 删除
    async deleteFn(id) {
      const { data: res } = await informNodeDelApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getVipListFn()
    },
    // 新增节点
    async confirmFn() {
      if (this.isTitle === '新增通知节点') {
        if (this.form.pointCode !== '') {
          if (this.typelist.length === 2) {
            if (this.form.messageId !== '' && this.form.websiteContent !== '' && this.form.pointCode !== '' && this.form.websiteTitle !== '') {
              this.form.type = this.typelist.join(',')
              const { data: res } = await informAddNodeApi(this.form)
              if (res.code !== 0) return this.$message.error(res.msg)
              this.isMessage = false
              this.isNote = false
              this.isShow = false
              this.form = {
                pointCode: '',
                type: '',
                messageId: '',
                websiteTitle: '',
                websiteContent: '',
                configId: this.$route.query.pointCode,
                name: ''
              }
              this.getVipListFn()
            } else {
              this.$message({
                type: 'error',
                message: '请输入必填项'
              })
            }
          } else if (this.typelist.length === 1) {
            if (this.isMessage === true) {
              this.form.type = '0'
              if (this.form.websiteContent !== '' && this.form.websiteTitle !== '') {
                this.form.messageId = ''
                const { data: res } = await informAddNodeApi(this.form)
                if (res.code !== 0) return this.$message.error(res.msg)
                this.isShow = false
                this.isMessage = false
                this.form = {
                  pointCode: '',
                  type: '',
                  messageId: '',
                  websiteTitle: '',
                  websiteContent: '',
                  configId: this.$route.query.pointCode,
                  name: ''
                }
                this.getVipListFn()
              } else {
                this.$message({
                  type: 'error',
                  message: '站内消息标题或内容不能为空'
                })
              }
            } else if (this.isNote === true) {
              this.form.type = '1'
              if (this.form.messageId !== '') {
                this.form.websiteContent = ''
                this.form.websiteTitle = ''
                const { data: res } = await informAddNodeApi(this.form)
                if (res.code !== 0) return this.$message.error(res.msg)
                this.form = {
                  pointCode: '',
                  type: '',
                  messageId: '',
                  websiteTitle: '',
                  websiteContent: '',
                  configId: this.$route.query.pointCode,
                  name: ''
                }
                this.isNote = false
                this.isShow = false
                this.getVipListFn()
              } else {
                this.$message({
                  type: 'error',
                  message: '短信ID不能为空'
                })
              }
            }
          } else {
            this.$message({
              type: 'error',
              message: '请输入必填项'
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: '节点名称不能为空'
          })
        }
      } else {
        if (this.form.pointCode !== '') {
          if (this.typelist.length === 2) {
            if (this.form.messageId !== '' && this.form.websiteContent !== '' && this.form.pointCode !== '' && this.form.websiteTitle !== '') {
              this.form.type = this.typelist.join(',')
              const { data: res } = await informUpdateNodeApi(this.form)
              if (res.code !== 0) return this.$message.error(res.msg)
              this.isMessage = false
              this.isNote = false
              this.isShow = false
              this.form = {
                pointCode: '',
                type: '',
                messageId: '',
                websiteTitle: '',
                websiteContent: '',
                configId: this.$route.query.pointCode,
                name: ''
              }
              this.getVipListFn()
            } else {
              this.$message({
                type: 'error',
                message: '请输入必填项'
              })
            }
          } else if (this.typelist.length === 1) {
            if (this.isMessage === true) {
              this.form.type = '0'
              if (this.form.websiteContent !== '' && this.form.websiteTitle !== '') {
                this.form.messageId = ''
                const { data: res } = await informUpdateNodeApi(this.form)
                if (res.code !== 0) return this.$message.error(res.msg)
                this.isShow = false
                this.isMessage = false
                this.form = {
                  pointCode: '',
                  type: '',
                  messageId: '',
                  websiteTitle: '',
                  websiteContent: '',
                  configId: this.$route.query.pointCode,
                  name: ''
                }
                this.getVipListFn()
              } else {
                this.$message({
                  type: 'error',
                  message: '站内消息标题或内容不能为空'
                })
              }
            } else if (this.isNote === true) {
              this.form.type = '1'
              if (this.form.messageId !== '') {
                this.form.websiteContent = ''
                this.form.websiteTitle = ''
                const { data: res } = await informUpdateNodeApi(this.form)
                if (res.code !== 0) return this.$message.error(res.msg)
                this.form = {
                  pointCode: '',
                  type: '',
                  messageId: '',
                  websiteTitle: '',
                  websiteContent: '',
                  configId: this.$route.query.pointCode,
                  name: ''
                }
                this.isNote = false
                this.isShow = false
                this.getVipListFn()
              } else {
                this.$message({
                  type: 'error',
                  message: '短信ID不能为空'
                })
              }
            }
          } else {
            this.$message({
              type: 'error',
              message: '请输入必填项'
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: '节点名称不能为空'
          })
        }
      }
    },
    messageFn1() {
      if (this.isMessage === true) {
        this.typelist.push(0)
      } else if (this.isNote === true) {
        this.typelist.splice(0, this.typelist.length)
        this.typelist.push(1)
      } else {
        this.typelist.splice(0, this.typelist.length)
      }
    },
    messageFn2() {
      if (this.isNote === true) {
        this.typelist.push(1)
      } else if (this.isMessage === true) {
        this.typelist.splice(0, this.typelist.length)
        this.typelist.push(0)
      } else {
        this.typelist.splice(0, this.typelist.length)
      }
    },
    // 编辑
    async updateFn(id) {
      const { data: res1 } = await informDetailNodeApi(id)
      const { data: res2 } = await informNodeListApi(this.getData.configId)
      this.selectList = res2.data
      this.typelist = res1.data.type.split(',')
      if (this.typelist.length === 2) {
        this.isMessage = true
        this.isNote = true
      } else {
        if (this.typelist[0] === '1') {
          this.isNote = true
        } else {
          this.isMessage = true
        }
      }
      this.form = res1.data
      this.isTitle = '编辑通知节点'
      this.isShow = true
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  .searchBox{
    padding: 10px 0px 30px 0px;
    span{
        display: inline-block;
        margin-right: 10px;
        font-size: 15px;
        font-weight: bold;
    }
    .el-input{
        width: auto;
    }
  }
  .el-breadcrumb{
    height: 40px;
  }
  nav {
    padding-bottom: 14px;
    width: 100%;
    border-bottom: 1px solid rgba(230, 232, 235, 1);
    font-size: 18px;
    font-weight: bold;
  }
  main {
    h5 {
      margin: 20px 0 10px;
      display: block;
      width: 100%;
      font-size: 16px;
    }
    .headerBox{
        display: flex;
        .el-button{
            height: 40px;
        }
    }
    }
  footer {
    height: 40px;
  }
}
</style>
