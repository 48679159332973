<template>
    <div class="index_content">
      <!-- 工作台 -->
      <nav>
          <p>工作台</p>
          <div class="work_content">
            <div class="work_box1">
              <div class="header">
                <a @click="personRouter()"><img src="../assets/img/defaultHead.jpg" alt=""></a>
              </div>
              <div class="person_info">
                <p>{{time}},赛利亚,今天又是充满希望的一天!</p>
                <p>采购专员 | 浙江小蚁信息科技有限公司 - 合作事业部 - 采购组</p>
              </div>
            </div>
            <div class="work_box2">
              <p>供应商</p>
              <p>12</p>
            </div>
            <div class="work_box3">
              <p>物料库</p>
              <p>2223</p>
            </div>
          </div>
      </nav>
      <!-- 销售跳转 -->
      <section>
        <div>
          <p>销售前</p>
          <ul>
            <li>收到的询价订单</li>
            <li>发出的报价订单</li>
            <li>待确认</li>
            <li>待发货</li>
          </ul>
        </div>
        <div>
          <p>销售中</p>
          <ul>
            <li>待买家收货</li>
          </ul>
        </div>
        <div>
          <p>销售后</p>
          <ul>
            <li>待对账</li>
            <li>待开票对账单</li>
          </ul>
        </div>
      </section>
      <!-- 系统消息 -->
      <article>
        <div class="header">
          <h5>系统消息</h5>
          <button>刷新</button>
          <button>查看全部信息</button>
        </div>
        <div class="main">
          <ul>
            <li>
              <p>2020-4-20 14:30:25<span>采购员归档合同</span></p>
              <p>XXXXX有限公司已收到您寄出的合同[190930000156],并完成了签署归档,请到操作路径: 合同-合同管理-查询我收到的合同下进行查看</p>
            </li>
            <li>
              <p>2020-4-20 14:30:25<span>采购员归档合同</span></p>
              <p>XXXXX有限公司已收到您寄出的合同[190930000156],并完成了签署归档,请到操作路径: 合同-合同管理-查询我收到的合同下进行查看</p>
            </li>
          </ul>
        </div>
      </article>
    </div>
</template>
<script>
export default {
  data() {
    return {
      time: '早安'
    }
  },
  created() {
    var now = new Date()
    var hour = now.getHours()
    if (hour < 10) {
      this.time = '早安'
    } else if (hour < 12) {
      this.time = '中午好'
    } else if (hour < 18) {
      this.time = '下午好'
    } else {
      this.time = '晚上好'
    }
  },
  methods: {
    // 个人中心路由跳转
    personRouter() {
      this.$router.push('/personalCenter')
    }
  }
}
</script>
<style scoped lang='less'>
.index_content {
  width: 100%;
}
nav {
  width: 100%;
  height: 160px;
  background-color: #fff;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
nav p{
  margin: 0 15px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family:MicrosoftYaHeiLight;
  color:rgba(0,0,0,1);
  border-bottom: 1px solid rgba(238,238,238,1);
}
nav .work_content {
  display: flex;
  width: 100%;
  height: 118px;
}
.work_content .work_box1 {
  flex: 3;
  display: flex;
}
.work_box1 .header {
  width: 118px;
}
.header img {
  margin: 20px;
  width: 78px;
  border-radius: 50%;
}
.work_box1 .person_info {
  flex: 1;
}
.person_info p {
  margin-left: 15px;
  height: 59px;
  line-height: 95px;
  font-size: 18px;
  color:rgba(0,0,0,1);
  border-bottom: 0;
}
.person_info p:nth-child(2) {
  line-height: 40px;
  font-size: 15px;
  color: #ccc;
}
.work_content .work_box2 {
  flex: 1;
  margin: 15px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.work_box2 p, .work_box3 p {
  text-align: right;
  margin-right: 15px;
  height: 59px;
  line-height: 59px;
  font-size: 15px;
  color: #ccc;
  border-bottom: 0;
}
.work_box2 p:nth-child(2), .work_box3 p:nth-child(2) {
  margin-right: 15px;
  line-height: 2px;
  font-size: 25px;
  color:rgba(0,0,0,1);
  font-weight: 400;

}
.work_content .work_box3 {
  flex: 1;
  margin: 15px 0;
}
.index_content section {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 250px;
}
.index_content section>div {
  width: 30%;
  background-color: #fff;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
.index_content section>div>p {
  margin-bottom: 21px;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 135, 225, 1);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.index_content section>div li {
  margin: 10px 25px;
  height: 35px;
  background-color: rgba(153, 153, 153, 0.3);
  font-size: 14px;
  text-align: center;
  line-height: 35px;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
}
.index_content article {
  width: 100%;
  background-color: #fff;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
article .header {
  position: relative;
  padding: 0 22px;
  height: 55px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  line-height: 55px;
  vertical-align: middle;
}
article .header h5 {
  font-size: 16px;
  font-weight: 400;
}
article .header button {
  position: absolute;
  top: 16px;
  border-radius: 14px;
  height: 28px;
  outline: none;
  border: 0;
  cursor: pointer;
}
article .header button:nth-child(2) {
  right: 161px;
  width: 88px;
}
article .header button:nth-child(3) {
  right: 33px;
  width: 115px;
}
article .header button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
article .main li{
  height: 100px;
  border-bottom: 1px solid #ccc;
}
article .main li p {
  margin: 0 25px;
  height: 50px;
  line-height: 50px;
  color: rgba(0,0,0,1);
  font-size: 14px;
}
article .main li p:nth-child(2) {
  margin-top: -10px;
  font-weight: 500;
}
article .main li span {
  margin-left: 28px;
  color: rgba(0,0,0,0.65);
}
</style>
