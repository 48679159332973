// 模板管理 模板列表
<template>
    <div class="templateList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <el-button class="lightDeepStyle query addBtn" size="mini" @click="openStorageDialog(1)">新 增</el-button>
          <p>
            <span>模板名称 : </span>
            <el-input v-model="templateData.fileName" placeholder="请输入模板名称" type="text" size="small" maxlength="40"></el-input>
            <span>合同类别 : </span>
            <el-select style="width: 160px;" v-model="templateData.type" placeholder="请选择" size="mini" clearable>
              <el-option label="购销合同" value="0"></el-option>
              <el-option label="委托合同" value="1"></el-option>
              <el-option label="其他合同" value="2"></el-option>
            </el-select>
            <span>状态 : </span>
            <el-select style="width: 160px;" v-model="templateData.enable" placeholder="请选择" size="mini" clearable>
              <el-option label="禁用" value="0"></el-option>
              <el-option label="启用" value="1"></el-option>
            </el-select>
            <el-button class="lightDeepStyle query" size="mini" @click="getTemplateList()">查询</el-button>
          </p>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="allMenuData" border style="width: 100%;">
              <el-table-column prop="fileName" label="模板名称" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="合同类别" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.type === 0">购销合同</span>
                    <span v-else-if="scope.row.type === 1">委托合同</span>
                    <span v-else-if="scope.row.type === 2">其他合同</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="purpose" label="模板用途" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="状态" width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.enabled"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @change="controlSwitch(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建日期" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button class="lightDeepStyle" size="mini" @click.stop="openStorageDialog(scope.row)">详情</el-button>
                    <el-button class="rejectStyle" size="mini" @click.stop="delTemplate(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { templateListApi, keepTemplateApi, delTemplateApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      templateData: {
        fileName: '',
        enable: '',
        type: ''
      },
      // 所有菜单目录
      allMenuData: [],
      // 控制新增修改弹框显示隐藏
      newMenuVisible: false,
      // 获取接口地址
      headers: {
        'token': window.sessionStorage.getItem('token')
      },
      storageMoneyDetail: {
        orderNum: '',
        cost: ''
      },
      // 判断是新增还是修改
      addStorageMoneyType: true
    }
  },
  created() {
    this.getTemplateList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取模板列表
    async getTemplateList() {
      const { data: res } = await templateListApi(this.templateData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allMenuData = res.data.data
    },
    // 点击状态修改时触发
    async controlSwitch(result) {
      const { data: res } = await keepTemplateApi(result)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (result.enabled === false) {
        this.$message.success('禁用成功!')
      } else {
        this.$message.success('启用成功!')
      }
    },
    // 删除模板
    async delTemplate(id) {
      const { data: res } = await delTemplateApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getTemplateList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 25px;
  width: 100%;
  .addBtn {
    position: absolute;
    left: 0;
    height: 32px;
  }
  >p {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    span {
      margin-right: 5px;
      display: inline-block;
      line-height: 32px;
    }
    .el-input {
      margin-right: 10px;
      width: 160px;
    }
    .el-select {
      margin-right: 10px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
</style>
