// 合同管理 合同列表
<template>
    <div class="contractList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <nav>合同编号 : {{contractDetial.contractNum}}</nav>
        <div :style="'height:' + tableHeight + 'px'" class="contentBox">
          <main>
            <h5>{{contractDetial.fileName}}</h5>
            <div>
              <p><i>甲方 : </i><span>{{contractDetial.item1}}</span></p><p><i>乙方 : </i><span>浙江小蚁信息科技有限公司</span></p>
              <p><i>地址 : </i><span>{{contractDetial.item1}}</span></p><p><i>地址 : </i><span>宁波市鄞州区南部商务区九楼</span></p>
              <p><i>联系人 : </i><span>{{contractDetial.item1}}</span></p><p><i>联系人 : </i><span>陈先生</span></p>
              <p><i>联系电话 : </i><span>{{contractDetial.item1}}</span></p><p><i>联系电话 : </i><span>15888007866</span></p>
            </div>
            <article>
              <h6>基本信息<i>订单编号 : <span>{{contractDetial.orderNum}}</span></i></h6>
              <div><p>发起方</p><p>付款方式</p><p>签订方式</p><p>发货时间</p></div>
              <div><span>{{contractDetial.item1}}</span><span>对公打款</span><span>电子签章</span><span>{{contractDetial.signTime}}</span></div>
              <div><p>接收方</p><p>创建日期</p><p>合同开始时间</p><p>合同结束时间</p></div>
              <div><span>浙江小蚁信息科技有限公司</span><span>{{contractDetial.createTime}}</span><span>{{contractDetial.beginTime}}</span><span>{{contractDetial.endTime}}</span></div>
            </article>
          </main>
          <section>
            <h6><span>流程信息</span></h6>
            <p>文件来源</p>
            <span>e签宝</span>
            <p>发起方</p>
            <span>陈先生</span>
            <p>发起时间</p>
            <span>2022-06-11</span>
            <p>签署详情</p>
            <span>签署方</span>
            <div class="rightBox">
              <i>已签</i>
              <p>小蚁有限公司</p>
              <span>李四</span>
            </div>
            <div class="rightBox">
              <i>已签</i>
              <p>小蚁有限公司</p>
              <span>李四</span>
            </div>
            <p>签署时间</p>
            <span>2022-07-11</span>
          </section>
        </div>
      </el-card>
    </div>
</template>
<script>
import { contractDetailApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      contractId: this.$route.query.id,
      contractDetial: ''
    }
  },
  created() {
    this.getContractDetail()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 300
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 300
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取合同详情
    async getContractDetail() {
      const { data: res } = await contractDetailApi(this.contractId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.contractDetial = res.data.data
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  nav {
    padding-bottom: 14px;
    width: 100%;
    border-bottom: 1px solid rgba(230, 232, 235, 1);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
  }
  .contentBox {
    display: flex;
    main {
      padding-right: 40px;
      width: calc(100% - 320px);
      border-right: 1px solid rgba(230, 232, 235, 1);
      h5 {
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
      >div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        p {
          margin-bottom: 20px;
          width: 50%;
        }
      }
      article {
        width: 100%;
        color: rgba(49, 55, 61, 1);
        h6 {
          margin: 30px 0 13px;
          font-size: 16px;
          i {
            float: right;
            font-weight: 400;
          }
        }
        div {
          margin-top: -1px;
          display: flex;
          width: 100%;
          min-height: 36px;
          border: 1px solid rgba(230, 232, 235, 1);
          line-height: 36px;
          p {
            flex: 1;
            margin-left: -1px;
            padding-left: 16px;
            background-color: rgba(247, 249, 250, 1);
            border-left: 1px solid rgba(230, 232, 235, 1);
            border-right: 1px solid rgba(230, 232, 235, 1);
          }
          span {
            margin-left: -1px;
            width: 25%;
            padding-left: 16px;
            border-left: 1px solid rgba(230, 232, 235, 1);
            border-right: 1px solid rgba(230, 232, 235, 1);
          }
        }
      }
    }
    section {
      flex: 1;
      h6 {
        margin-top: 0;
        width: 100%;
        height: 43px;
        border-bottom: 1px solid rgba(230, 232, 235, 1);
        line-height: 43px;
        span {
          margin-left: 10px;
          display: inline-block;
          height: 43px;
          border-bottom: 2px solid rgba(51, 158, 191, 1);
          color: rgba(51, 158, 191, 1);
          font-size: 14px;
        }
      }
      >p {
        margin: 16px 24px 10px;
        color: rgba(0, 0, 0, 0.45);
      }
      >span {
        margin: 16px 24px 10px;
        font-weight: 500;
      }
      .rightBox {
        position: relative;
        margin: 10px 0;
        padding: 0 10px 0 30px;
        height: 40px;
        i {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 32px;
          height: 20px;
          background-color: rgba(51, 158, 191, 1);
          border-radius: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #FFFFFF;
          text-align: center;
        }
        p {
          color: #000000;
          font-size: 14px;
        }
        span {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
}
</style>
