// 模板列表
<template>
    <div class="helpMenuList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <div style="height: 30px;margin: 18px 10px -3px 0;display: inline-block">
            <!-- 测试服 'https://adminapi.bmxgj.cn' -->
            <!-- 正式服 'https://adminapi.z-srm.com' -->
            <el-upload
              ref="uploadOrder"
              class="upload-demo"
              action="https://adminapi.z-srm.com/api/sign/uploadTemplate"
              :headers="headers"
              multiple
              name='str'
              :limit="1"
              :on-success="uploadSuccess"
              :before-upload="beforeAvatarUpload"
              :show-file-list="true"
              :file-list="fileList">
              <el-button class="lightDeepStyle" size="mini">上 传</el-button>
            </el-upload>
          </div>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { addWidgetApi, createPDFApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      headers: {
        // 'token': window.sessionStorage.getItem('token')
        'token': window.$cookies.get('token')
      },
      fileList: []
    }
  },
  created() {
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 360
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 360
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 上传模板
    templateUpload() {
    },
    // 上传文件成功回调
    uploadSuccess(res) {
      console.log(res, 'makabaka')
      this.$message.success({ duration: 1000, message: '上传成功!' })
      this.$refs.uploadOrder.clearFiles()
      this.addWidget(res.data.data.templateId)
    },
    // 上传前大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 添加填写控件
    async addWidget(value) {
      const { data: res } = await addWidgetApi({ templateId: value })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.createPDF(value, res.data.data.data[0])
    },
    // 添加内容生成PDF
    async createPDF(value, widgets) {
      const { data: res } = await createPDFApi({ templateId: value, widgets: widgets })
      if (res.code !== 0) return this.$message.error(res.msg)
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 50px;
  .el-button {
    position: absolute;
    top: 0;
    left: 0;
  }
  >div {
    position: absolute;
    right: 70px;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
  >div:nth-child(2) {
    right: 300px;
  }
  .query {
    position: absolute;
    left: calc(100% - 60px);
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .helpMenuListDialog {
  .el-dialog {
    height: 300px;
    .el-dialog__body {
      height: 160px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
</style>
