// 行情管理 价格行情
<template>
    <div class="priceQuotation">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <div>
            <el-input size="mini" placeholder="请输入关键词" v-model="quotationListData.search" @keyup.enter.native="getPriceQuotationList()"></el-input>
          </div>
          <el-button class="lightDeepStyle query" size="mini" @click="getPriceQuotationList()">查询</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="allMenuData" border style="width: 100%;" @row-click="openRowPirceDialog">
              <el-table-column prop="type" label="分类名称" sortable min-width="100px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column type="index" label="排序编号" sortable width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="pic" label="图片" min-width="100px" align="center">
                <template slot-scope="scope">
                  <div>
                    <img :src="scope.row.pic === '' ? 'https://srm-wxitem.oss-cn-hangzhou.aliyuncs.com/img/defaultSource.png' : scope.row.pic">
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" sortable min-width="300px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="author" label="发布者" min-width="100px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="createTime" label="创建日期" sortable min-width="100px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="viewCount" label="浏览量" sortable min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="isOpen" label="状态" sortable min-width="80px" align="center">
                <template slot-scope="scope">
                  <div @click.stop>
                    <el-switch
                    v-model="scope.row.isOpen"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @input="editPriceQuotationContent(scope.row)">
                  </el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button class="lightDeepStyle" size="mini" @click.stop="openPirceDialog(scope.row)">编辑</el-button>
                    <el-button class="rejectStyle" size="mini" @click.stop="delDetailsData(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="quotationListData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <!-- 新增修改目录弹框 -->
      <el-dialog title="价格行情" :visible.sync="newMenuVisible" width="800px" :close-on-click-modal="false" class="priceQuotationDialog">
        <div class="dialogBody">
          <el-table :data="priceQuotationDetail" stripe style="width: 100%" size="mini" border>
            <el-table-column prop="type" show-overflow-tooltip  label="品名" min-width="150" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.id !== controlEditInput">{{scope.row.productName}}</span>
                <el-input style="width: 150px" v-if="scope.row.id === controlEditInput" v-model="scope.row.productName" placeholder="请输入" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column v-if="listTitleType === '建筑钢材'" show-overflow-tooltip label="钢厂" min-width="100" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.id !== controlEditInput">{{scope.row.fctName === '' ? '暂无' : scope.row.fctName}}</span>
                <el-input style="width: 100px" v-if="scope.row.id === controlEditInput" v-model="scope.row.fctName" placeholder="请输入" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column v-else show-overflow-tooltip label="钢厂" min-width="100" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.id !== controlEditInput">{{scope.row.fctName === '' ? '暂无' : scope.row.fctName}}</span>
                <el-input disabled style="width: 100px" v-if="scope.row.id === controlEditInput" v-model="scope.row.fctName" placeholder="暂无" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="标准价" min-width="80" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.id !== controlEditInput">{{scope.row.price}}</span>
                <el-input style="width: 80px" v-if="scope.row.id === controlEditInput" v-model="scope.row.price" placeholder="请输入" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="涨跌" min-width="80" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.id !== controlEditInput">{{scope.row.priceDrop}}</span>
                <el-input style="width: 80px" v-if="scope.row.id === controlEditInput" v-model="scope.row.priceDrop" placeholder="请输入" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column property="address" label="操作" width="200" align="center">
              <template slot-scope="scope">
                <el-button  v-if="scope.row.id !== controlEditInput" size="mini" class="editBtn" @click="editData(scope.row.id)">编辑</el-button>
                <el-button  v-if="scope.row.id !== controlEditInput" size="mini" type="danger" @click="delDetailsData(scope.row.id, scope.$index)">删除</el-button>
                <el-button  v-if="scope.row.id === controlEditInput" size="mini" class="editBtn" @click="keepEditData(scope.row)">保存</el-button>
                <el-button  v-if="scope.row.id === controlEditInput" size="mini" class="cancelBtn" @click="controlEditInput = false">取消</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dialogFooter">
          <!-- 新增参数值展示输入框 -->
          <div class="addText" v-if="controlAddInput === true">
            <el-input style="width: 160px" v-model="addDisposeData.productName" placeholder="品名" class="addValueInput" size="mini"></el-input>
            <el-input v-if="listTitleType === '建筑钢材'" style="width: 100px" v-model="addDisposeData.fctName" placeholder="暂无" class="addNameInput" size="mini"></el-input>
            <el-input v-else disabled style="width: 100px" v-model="addDisposeData.fctName" placeholder="钢厂" class="addNameInput" size="mini"></el-input>
            <el-input style="width: 80px" v-model="addDisposeData.price" placeholder="标准价" class="addkeyInput" size="mini"></el-input>
            <el-input style="width: 80px" v-model="addDisposeData.priceDrop" placeholder="涨跌" class="addkeyInput" size="mini"></el-input>
            <el-button class="keepBtn" size="mini" @click="keepDataBtn()">保存</el-button>
            <el-button size="mini" type="info" @click="cancelAddBtn()">取消</el-button>
          </div>
          <p v-if="controlAddInput === false" @click="controlAddInput = true"><span>新 增</span></p>
        </div>
      </el-dialog>
      <!-- 修改详情信息弹框 -->
      <el-dialog title="修改详情" :visible.sync="controlDetailContent" width="500px" :close-on-click-modal="false" class="priceQuotationContentDialog">
        <div class="dialogInput"><span>标题</span><el-input size="mini" v-model="editDetailContent.title" placeholder="请输入标题"></el-input></div>
        <div class="dialogInput"><span>发布人</span><el-input size="mini" v-model="editDetailContent.author" placeholder="请输入发布人"></el-input></div>
        <div class="dialogInput"><span>浏览量</span><el-input size="mini" v-model="editDetailContent.viewCount" placeholder="请输入浏览量"></el-input></div>
        <div class="dialogInput">
          <span>图片</span>
          <!-- http://adminapi.bmxgj.cn/  测试服 -->
          <!-- https://adminapi.z-srm.com/  正式服 -->
          <el-upload
            class="avatar-uploader"
            action="https://adminapi.z-srm.com/sys/common/upload-pic"
            :show-file-list="false"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload2"
            :on-remove="handleRemove">
            <img v-if="editDetailContent.pic" :src="editDetailContent.pic" class="avatar">
            <span v-if="editDetailContent.pic" class="el-upload-action" @click.stop="handleRemove()">
                <i class="el-icon-delete"></i>
            </span>
            <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
            </el-upload>
        </div>
        <p slot="footer">
          <el-button ref="editBtn" class="lightDeepStyle" @click="editPriceQuotationContent2()">修 改</el-button>
          <el-button ref="addBtn" class="searchStyle" @click="controlDetailContent = false">取 消</el-button>
        </p>
      </el-dialog>
    </div>
</template>
<script>
import { priceQuotationListApi, priceQuotationDetailApi, editPriceQuotationApi, delPriceQuotationApi, addPriceQuotationApi, editPriceQuotationContentApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 所有菜单目录
      allMenuData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      quotationListData: {
        page: 1,
        size: 10,
        search: ''
      },
      // 控制新增修改弹框显示隐藏
      newMenuVisible: false,
      // 价格详情列表
      priceQuotationDetail: [],
      // 控制修改的输入框
      controlEditInput: '',
      // 新增输入框填入的值
      addDisposeData: {
        titleId: '',
        productName: '',
        fctName: '',
        price: '',
        priceDrop: ''
      },
      // 控制新增输入框
      controlAddInput: false,
      // 存储当前打开的详情列表titleId
      listTitleId: '',
      // 控制详情内容弹框显示隐藏
      controlDetailContent: false,
      editDetailContent: {
        id: '',
        title: '',
        author: '',
        viewCount: '',
        pic: '',
        isOpen: ''
      },
      // 获取接口地址
      headers: {
        'token': window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getPriceQuotationList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 上传前格式和图片大小限制
    beforeAvatarUpload2(file) {
      const type = file.type === 'image/jpeg' || 'image/jpg' || 'image/webp' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    },
    // 移除图片
    handleRemove() {
      this.editDetailContent.pic = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.editDetailContent.pic = res.data.url
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.quotationListData.page = val
      this.currentPage = val
      this.getPriceQuotationList()
    },
    // 获取菜单内容列表
    async getPriceQuotationList() {
      const { data: res } = await priceQuotationListApi(this.quotationListData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allMenuData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 打开价格弹框
    async openPirceDialog(result) {
      this.listTitleId = result.id
      this.listTitleType = result.type
      // 获取价格行情列表
      const { data: res } = await priceQuotationDetailApi({ titleId: result.id })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.priceQuotationDetail = res.data.list
      this.newMenuVisible = true
    },
    // 编辑参数值
    editData(id) {
      this.controlEditInput = id
    },
    // 修改参数值
    async keepEditData(result) {
      console.log(result, '123')
      const { data: res } = await editPriceQuotationApi(result)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.controlAddInput = false
      this.controlEditInput = ''
      this.$message.success({ duration: 1000, message: '修改参数值成功!' })
    },
    // 删除菜单目录
    delDetailsData(id, index) {
      this.$confirm('是否删除当前价格行号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delPriceQuotationApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.priceQuotationDetail.splice(index, 1)
        this.$message.success('删除成功!')
      }).catch(() => {
      })
    },
    // 取消添加参数值
    cancelAddBtn() {
      this.controlAddInput = false
      // 新增输入框填入的值
      this.addDisposeData = {
        titleId: '',
        productName: '',
        fctName: '',
        price: '',
        priceDrop: ''
      }
    },
    // 保存参数值
    async keepDataBtn() {
      if (this.addDisposeData.productName === '') return this.$message.error('品名不能为空!')
      if (this.addDisposeData.fctName === '') return this.$message.error('钢厂不能为空!')
      if (this.addDisposeData.price === '') return this.$message.error('标准价不能为空!')
      if (this.addDisposeData.priceDrop === '') return this.$message.error('涨跌不能为空!')
      this.addDisposeData.titleId = this.listTitleId
      const { data: res } = await addPriceQuotationApi(this.addDisposeData)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 本地保存
      let newDisposeData = {
        productName: this.addDisposeData.productName,
        fctName: this.addDisposeData.fctName,
        price: this.addDisposeData.price,
        priceDrop: this.addDisposeData.priceDrop,
        id: res.data.id
      }
      this.priceQuotationDetail.push(newDisposeData)
      this.controlAddInput = false
      this.$message.success({ duration: 1000, message: '保存成功!' })
      // 新增输入框填入的值
      this.addDisposeData = {
        titleId: '',
        productName: '',
        fctName: '',
        price: '',
        priceDrop: ''
      }
    },
    // 修改行情列表(启用停用专用)
    async editPriceQuotationContent(value) {
      const { data: res } = await editPriceQuotationContentApi(value)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '状态修改!' })
      this.getPriceQuotationList()
    },
    async editPriceQuotationContent2() {
      const { data: res } = await editPriceQuotationContentApi(this.editDetailContent)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '状态修改!' })
      this.getPriceQuotationList()
      this.controlDetailContent = false
    },
    // 打开修改详情内容弹框
    openRowPirceDialog(row) {
      this.editDetailContent = row
      this.controlDetailContent = true
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  height: 25px;
  .el-button {
    position: absolute;
    top: 0;
    left: 0;
  }
  >div {
    position: absolute;
    right: 70px;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
  >div:nth-child(2) {
    right: 300px;
  }
  .query {
    position: absolute;
    left: calc(100% - 60px);
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .priceQuotationDialog {
  .el-dialog {
    height: 600px;
    .el-dialog__body {
      height: 535px;
      .dialogInput {
        span {
          margin-right: 10px;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
.priceQuotation>.el-table {
  margin-top: 60px;
}
.priceQuotation .dialogBody {
  height: 450px;
  overflow-y: auto;
}
.priceQuotation .el-dialog .el-table {
  margin-top: 0;
  text-align: center;
  .editBtn {
    background-color: rgba(42,49,59,.7);
    color: #fff;
  }
  .cancelBtn {
    background-color: #ccc;
    color: #fff;
  }
  .cancelBtn:hover {
    border: 1px solid #ccc;
  }
}
.priceQuotation .el-dialog .addText {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  .addValueInput {
    margin: 0 20px;
    margin-right: 10px;
    display: inline-block;
    width: 110px;
  }
  .addNameInput {
    margin: 0 20px;
    display: inline-block;
    width: 152px;
  }
  .addkeyInput {
    margin: 0 14px;
    display: inline-block;
  }
  .keepBtn {
    margin-left: 35px;
    background-color: #aacfcf;
    border: 0;
    color: #fff
  }
}
.priceQuotation .priceQuotationDialog p {
  width: 100%;
  height: 30px;
  background-color: black;
  color: #FFFFFF;
  text-align: center;
  line-height: 30px;
}
/deep/ .priceQuotationContentDialog {
  .el-dialog {
    height: 650px;
    .el-dialog__body {
      height: 510px;
      .dialogInput {
        >span {
          margin-right: 10px;
          display: inline-block;
          width: 55px;
        }
        .el-input {
          display: inline-block;
          width: 360px;
        }
      }
    }
  }
}
.avatar-uploader{
  margin-left: 110px;
  width: 300px;
  height: 200px;
  border-radius: 2%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #A7A7A7;
  background-size: 100% 100%;
  vertical-align: top;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 300px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 300px;
  height: 200px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 300px;
  height: 200px;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 200px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}
</style>
