<template>
<div class='vipContent'>
    <!-- 卡片视图区 -->
    <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row>
          <!-- 操作按钮 -->
          <el-button type="success" @click="vipAddFn">新增会员等级</el-button>
        </el-row>
        <el-row style="margin-top: 10px;">
            <el-col v-for="(item, index) in list" :key="index" :span="6">
              <div>
                  <h3>{{ item.name }}</h3>
                  <p v-if="item.id===1">体验版</p>
                  <el-switch  active-text="启用" inactive-text="停用" v-else :value="item.isOpen === 1" @input="toggleIsOpen(item, $event)"></el-switch>
              </div>
              <div class="unitiveClass"><span>价格：{{ item.price===0?'免费':item.price }}/{{ item.expDay }} 天</span> <span>使用中:{{item.count}}家(企业)</span></div>
              <div class="unitiveClass2">
                  <el-button type="primary" @click="updateVip(item.id)">配置</el-button>
                  <el-button type="danger" @click="deleteFn(item.id)" v-if="item.id!==1 && item.count === 0">删除</el-button>
              </div>
            </el-col>
        </el-row>
    </el-card>
  </div>
</template>

<script>
import { vipListApi, deleteVip, updateVipApi } from '@/api'
export default {
  components: {},
  props: {},
  data() {
    return {
      tableHeight2: '',
      // 会员列表
      list: [],
      roleData: {
        id: '',
        // 当前角色id
        // 备注
        expDay: '',
        // 上级岗位id
        parentId: null,
        // 角色名称
        name: '',
        // 部门名称
        rebate: '',
        // 权限菜单
        menuIds: [],
        // 存储权限菜单
        isOpen: '',
        renewPrice: '',
        userCount: '',
        price: '',
        rebatePrice: ''
      }
    }
  },
  computed: {},
  methods: {
    // 会员配置列表请求
    async getVipListFn() {
      const { data: res } = await vipListApi()
      if (res.code === 0) {
        this.list = res.data.list
      } else {
        this.$message({
          type: 'error', // success error warning
          message: '列表获取失败',
          duration: 200
        })
      }
    },
    // 跳转新增
    vipAddFn() {
      this.$router.push({
        path: '/addSettingVip',
        query: {
          id: ''
        }
      })
    },
    // 删除会员
    async deleteFn(id) {
      const { data: res } = await deleteVip({ id: id })
      if (res.code === 0) {
        this.getVipListFn()
        return this.$message({
          type: 'success', // success error warning
          message: '删除成功',
          duration: 1000
        })
      }
    },
    updateVip(id) {
      this.$router.push({
        path: '/addSettingVip',
        query: {
          id: id
        }
      })
    },
    async toggleIsOpen(item, value) {
      // 判断是否有使用中的企业
      if (item.count !== 0) return this.$message.error('有效期内存在企业使用会员，无法删除/停用')
      item.isOpen = value ? 1 : 0
      this.roleData.isOpen = item.isOpen
      this.roleData.id = item.id
      this.roleData.name = item.name
      const { data: res } = await updateVipApi(this.roleData)
      if (res.code === 0) {
        return this.$message({
          type: 'success', // success error warning
          message: '修改成功',
          duration: 1000
        })
      }
      this.getVipListFn()
    }
  },
  created() {
    this.getVipListFn()
  },
  mounted() {

  }
}
</script>
<style scoped lang="less">
.vipContent {
  width: 100%;
  .el-card {
    padding: 26px;
    background:rgba(255,255,255,1);
    border-radius:3px;
    overflow: auto;
    .el-col-6{
        width: 24%;
        margin-right: 5px;
        border: 1px solid #ccc;
       box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
       padding: 10px;
        div:nth-child(1){
            display: flex;
            line-height: 3.125rem;
            align-items: center;
            justify-content: space-between;
            h3{
                font-size: 14px;
                font-weight: bold;
                width: 50%;
            }
            p{
                font-weight: bold;
                font-size: 12px;
                color: #5ebdbe;
            }
        }
        .unitiveClass{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        span{
            display: inline-block;
            line-height: 3rem;
        }
       }
       .unitiveClass2{
        display: flex;
        align-items: center;
        justify-content: space-between;
       }
    }
  }
}
</style>
