import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// /backend   /api专用
// axios.defaults.baseURL = 'http://adminapi.bmxgj.cn' // 测试服
// axios.defaults.baseURL = 'https://adminapi.z-srm.com' // 正式服(旧项目)
// axios.defaults.baseURL = 'https://newadapi\.z-srm\.com' // 正式服(新项目)

// /sys专用
// axios.defaults.baseURL = 'http://apisrm.bmxgj.cn/' // 测试服
// axios.defaults.baseURL = 'https://api.z-srm.com/' // 正式服(旧项目)
// axios.defaults.baseURL = 'https://newapi\.z-srm\.com/' // 正式服(新项目)
// 公海客户
// 用户审核，采购需求审核
// http://gh.bmxgj.cn （测试服）
// 请求拦截器
axios.interceptors.request.use(res => {
  Nprogress.start()
  res.headers['token'] = window.sessionStorage.getItem('token')
  return res
})
axios.interceptors.response.use(res => {
  Nprogress.done()
  return res
})

// 密码登录★
export const loginApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/admin/login', params)
}

// 退出登录☆
export const logoutApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/admin/logout')
}

// 获取导航菜单栏
export const getNavMenuApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/menu/nav')
}

// 发送验证码 ☆
export const sendVerificationApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/send-sms?type=' + params.type + '&mobile=' + params.mobile)
}

// 注册审核管理
// 获取注册审核管理用户列表★
export const getUserListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/list?name=' + params.name + '&page=' + params.page + '&size=' + params.size + '&status=' + params.status)
}
// 用户审核通过,企业升级★
export const passExaminApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/update', params)
}
// 注销用户
export const cancelUserApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/user/cancel?companyId=' + params)
}
// 恢复用户
export const recoveryUserApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/user/recover?companyId=' + params)
}

// 角色管理
// 获取角色列表★
export const getRoleListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/client-role/list?search=' + params.search + '&page=' + params.page + '&size=' + params.size)
}

// 获取角色详情★
export const getRoleDetailsApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/client-role/info?id=' + params)
}

// 修改角色详情★
export const editRoleDetailsApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-role/update', params)
}

// 删除角色★
export const delRoleApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-role/delete', params)
}

// 新增角色★
export const addRoleDetailsApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-role/save', params)
}

// 菜单管理
// 获取所有菜单★
export const getAllMenuApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/client-menu/list')
}

// 获取菜单详情★
export const getMenuDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/client-menu/info?menuId=' + params)
}

// 新增菜单项
export const addMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-menu/save', params)
}

// 修改菜单
export const editMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-menu/update', params)
}

// 删除菜单
export const delMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/client-menu/delete?menuId=' + params)
}

// 后台菜单管理
// 获取所有菜单★
export const getAllBackMenuApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/menu/list')
}

// 获取菜单详情★
export const getBackMenuDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/menu/info?menuId=' + params)
}

// 新增菜单项
export const addBackMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/menu/save', params)
}

// 修改菜单
export const editBackMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/menu/update', params)
}

// 删除菜单
export const delBackMenuItemApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/menu/delete?menuId=' + params)
}

// 帮助管理 菜单目录
// 帮助菜单目录列表
export const getHelpMenuListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/manual/list?type=' + params.type)
}

// 新增菜单目录
export const addHelpMenuApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/save', params)
}

// 修改菜单目录
export const editHelpMenuApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/update', params)
}

// 删除菜单目录
export const delHelpMenuApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/del?id=' + params)
}

// 帮助管理 帮助菜单
// 帮助菜单内容列表
export const getHelpMenuApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/manual/content-list?title=' + params.title + '&page=' + params.page + '&size=' + params.size + '&mid=' + params.mid + '&type=' + params.type)
}

// 新增帮助菜单内容
export const addHelpMenuDetailApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/save-content', params)
}

// 修改帮助菜单内容
export const editHelpMenuDetailApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/update-content', params)
}

// 获取内容详情
export const getHelpMenuDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/manual/detail?id=' + params)
}

// 删除内容详情
export const delHelpMenuDetailApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/manual/del-content?id=' + params)
}
//

// 寻源----
export const quoteListApis = params => {
  return axios.get('http://adminapi.bmxgj.cn//backend/purchase/info/sellList?audit=' + params.audit + '&moduleType=' + params.moduleType + '&keyword=' + params.keyword)
}

// 采购审核列表----
export const quoteListApi = params => {
  return axios.get('http://gh.bmxgj.cn/backend/Mo/buy/list?audit=' + params.page + '&size=' + params.size + '&labelName=' + params.labelName + '&source=' + params.source + '&approveStatus=' + params.approveStatus)
}

// 审核采购信息
export const examinInfoApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/purchase/info/auditSellInfo', params)
}
// 采购审核详情
export const purchaseDeatil = params => {
  return axios.get('http://gh.bmxgj.cn/backend/Mo/buy/info?id=' + params.id)
}
// 采购审核功能接口
export const quoteData = params => {
  return axios.post('http://gh.bmxgj.cn/backend/Mo/buy/approve', params)
}
// 采购审核同步 uuids types
export const synquoteData = params => {
  return axios.post('http://gh.bmxgj.cn/backend/Mo/buy/synchronization', params)
}
// 采购审核同步 uuids
export const synquoteDataDt = params => {
  // return axios.post('http://adminapi.bmxgj.cn//backend/DT/synchronizationBuy', params)
}
// 采购审核同步 uuids
export const synquoteDataXcx = params => {
  return axios.post('https://appapi.bmxgj.cn/api/purchase/info/synchronizationBuy', params)
}
// 客户审核获取列表
export const clientListApi = params => {
  return axios.get('http://gh.bmxgj.cn/backend/Mo/list?page=' + params.page + '&size=' + params.size + '&name=' + params.name + '&source=' + params.source + '&approveStatus=' + params.approveStatus)
}
// 客户审核列表详情
export const clientListDetailApi = params => {
  return axios.get('http://gh.bmxgj.cn/backend/Mo/info?id=' + params.id)
}
// 客户审核同步接口（小程序）
export const synchronousData = params => {
  return axios.post('http://gh.bmxgj.cn//backend/Mo/synchronization', params)
}
// 客户审核同步接口（地推）
export const syncGroundData = params => {
  return axios.post('http://adminapi.bmxgj.cn//backend/DT/synchronization', params)
}
// 客户审核功能接口
export const auditData = params => {
  return axios.post('http://gh.bmxgj.cn/backend/Mo/approve', params)
}
export const keepMessageApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/purchase/info/saveMessage', params)
}

// 添加填写控件
export const addWidgetApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/api/sign/addWidget', params)
}

// 添加内容生成PDF
export const createPDFApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/api/sign/createPDF', params)
}

// 行情管理
// 价格行情列表
export const priceQuotationListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/market/titleList?search=' + params.search + '&size=' + params.size + '&page=' + params.page)
}

// 价格详情列表
export const priceQuotationDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/market/list?titleId=' + params.titleId)
}

// 修改价格详情列表
export const editPriceQuotationApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/market/update', params)
}

// 修改价格详情内容
export const editPriceQuotationContentApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/market/updateTitle', params)
}

// 删除价格详情列表
export const delPriceQuotationApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/market/delete?id=' + params)
}

// 新增价格详情列表
export const addPriceQuotationApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/market/save', params)
}

// 分类管理
// 获取全部分类
export const getAllClassifyApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/category/all-list?search=')
}

// 新增分类
export const addClassifyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/category/save', params)
}

// 修改分类
export const editClassifyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/category/update', params)
}

// 删除分类
export const deleteClassifyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/category/delete?id=' + params)
}

// 获取分类详情
export const getClassifyDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/attr/detail?id=' + params)
}

// 修改分类属性
export const editClassifyAttributeApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/attr/update', params)
}

// 分类详情列表(含价格设置)
export const classifyPriceListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/attr/priceList?id=' + params)
}

// 分类详情添加列表
export const addClassifyPriceApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/attr/addPrice', params)
}

// 系统消息列表
export const systemMessageListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/message/list?page=' + params.page + '&size=' + params.size + '&username=' + params.username + '&companyName=' + params.companyName + '&content=' + params.content + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&pushType=' + params.pushType)
}

// 新增系统消息
export const addSystemMessageApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/message/save', params)
}

// 新增消息时用户搜索
export const addMessageUserSearchApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/searchUser?username=' + params)
}

// 修改系统消息
export const editSystemMessageApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/message/update', params)
}

// 删除系统消息
export const delSystemMessageApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/message/delete?id=' + params)
}

// 通知模板列表
export const noticeTemplateApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/message/templateList')
}

// 修改通知模板
export const editNoticeTmplateApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/message/updateTemplate', params)
}

// 收款管理
// 收款列表
export const payMentList = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/finance/list?orderNum=' + params.orderNum + '&contractNum=' + params.contractNum + '&fileName=' + params.fileName + '&companyName=' + params.companyName + '&payStatus=' + params.payStatus + '&page=' + params.page + '&size=' + params.size)
}

// 收款详情
export const payMentDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/finance/detail?orderNum=' + params)
}

// 收款明细详情
export const payMentPaylogDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/finance/paylogDetail?id=' + params)
}

// 财务审核
export const financeExamineApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/finance/audit', params)
}

// 银行卡管理列表
export const bankCardListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/bank/list')
}

// 删除银行卡
export const delbankCardApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/bank/delete?id=' + params)
}

// 新增银行卡
export const addBankCardApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/bank/save', params)
}

// 修改银行卡
export const editBankCardApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/bank/update', params)
}

// 仓储费列表
export const storageMoneyListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/storagemoney/list?page=' + params.page + '&size=' + params.size + '&orderNum=' + params.orderNum)
}

// 删除仓储费
export const delStorageMoneyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/storagemoney/delete?id=' + params)
}

// 新增仓储费
export const addStorageMoneyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/storagemoney/save', params)
}

// 修改仓储费
export const editStorageMoneyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/storagemoney/update', params)
}

// 模板管理
// 1.上传基础模板
// 基础模板列表
export const baseTemplateListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/getTemplateOriginalList', params)
}

// 删除基础模板
export const delBaseTemplateApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/deleteTemplateOriginalById?id=' + params)
}

// 2.新建模板
// 获取文件上传的md5和url并上传文件
export const getFileMd5AndUrlApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/uploadTemplate?id=' + params)
}

// 获取控件参数
export const getControlDataApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/getTemplateWidget?sysEsignTemplateOriginalId=' + params)
}

// 保存模板
export const keepTemplateApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/api/sign/saveTemplate', params)
}

// 模板列表
export const templateListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/getTemplateList?fileName=' + params.fileName + '&enable=' + params.enable + '&type=' + params.type)
}

// 删除模板
export const delTemplateApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/api/sign/deleteTemplate?id=' + params)
}

// 合同管理
// 1.合同列表
export const getContractListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/getTemplateFlowList?fileName=' + params.fileName + '&ifSign=' + params.ifSign + '&companyName=' + params.companyName + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd)
}

// 2.合同保存
export const keepContractApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/api/sign/getTemplateFlowSave', params)
}

// 3.合同详情
export const contractDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/api/sign/getTemplateFlowInfo?id=' + params)
}

// 客户管理
// 1.客户列表
export const getCompanyListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/companyList?page=' + params.page + '&size=' + params.size + '&ifCancel=' + params.ifCancel + '&startTime=' + params.startTime + '&endTime=' + params.endTime)
}

// 2.客户保存
export const addCompanyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/addCompany', params)
}

// 3.客户修改
export const updateCompanyApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/updateCompany', params)
}

// 4.客户详情
export const companyDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/companyDetail?id=' + params)
}

// 订单管理
// 1.订单列表
export const getOrderListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/order/list?status=' + params.status + '&payStatus=' + params.payStatus + '&search=' + params.search + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&page=' + params.page + '&size=' + params.size)
}

// 2.修改订单
export const editOrderApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/jicai/order/update', params)
}

// 3.订单详情
export const orderDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/order/detail?id=' + params)
}

// 出库管理
// 1.出库列表
export const getOutBoundListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/outbound/list?outboundNum=' + params.outboundNum + '&orderNumAndCompanyName=' + params.orderNumAndCompanyName + '&status=' + params.status + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&page=' + params.page + '&size=' + params.size)
}

// 2.出库审批
export const outBoundApprovalApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/jicai/outbound/approve?id=' + params.id + '&status=' + params.status + '&remark=' + params.remark)
}

// 用户管理
// 经营属性
// 1.经营属性列表
export const businessListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/businessAttribute/list?page=' + params.page + '&size=' + params.size)
}

// 2.保存经营属性
export const saveBusinessApi = params => {
  return axios.post('backend/businessAttribute/save', params)
}

// 3.删除经营属性
export const delBusinessApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/businessAttribute/delete?id=' + params)
}

// 用户标签列表
export const userLabelListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/businessAttribute/companyList?page=' + params.page + '&size=' + params.size + '&name=' + params.name)
}

// 配置公司的经营属性
export const userLabelInfoApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/businessAttribute/changeCompanyMsg?id=' + params.id + '&businessAttribute=' + params.businessAttribute)
}

// 官网资讯
// 1.资讯列表
export const getWebListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/gw/articleList?page=' + params.page + '&size=' + params.size + '&cate=' + params.cate + '&isFirst=' + params.isFirst + '&isDisplay=' + params.isDisplay + '&title=' + params.title)
}

// 2.修改官网资讯
export const editWebInfoApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/gw/editArticle', params)
}

// 3.删除官网资讯
export const delWebInfoApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/gw/delete?id=' + params)
}

// 4.新增官网资讯
export const addWebInfoApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/gw/addArticle', params)
}

// 5.官网资讯详情
export const webInfoDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/gw/articleDetail?id=' + params)
}

// 报名后台
// 1.报名列表
export const getRegListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/gw/regList?page=' + params.page + '&size=' + params.size + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&name=' + params.name)
}
// 2.报名详情列表
export const remarkListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/gw/remarkList?regId=' + params.id)
}
// 3.添加报名备注
export const addRegRemarkApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/gw/addRegRemark', params)
}

// 地推
// 组织架构
// 1.部门列表
export const deptListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/dept/list')
}

// 2.新增修改部门
export const changeDeptApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/dept/save', params)
}

// 3.删除部门
export const delDeptApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/dept/delete?id=' + params)
}

// 4.根据部门获取用户列表
export const getDeptUserApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/dept/userList?deptId=' + params.id + '&page=' + params.page + '&size=' + params.size)
}

// 编辑用户信息
export const editUserInfoApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/admin/register', params)
}

// 删除用户信息
export const delUserApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/admin/delete?id=' + params)
}

// 权限管理
// 1.角色列表
export const roleListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/role/list?search=' + params.search + '&page=' + params.page + '&size=' + params.size)
}

// 2.新增修改角色
export const addRoleApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/role/save', params)
}

// 3.修改角色
export const editRoleApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/role/update', params)
}

// 4.删除角色
export const deleteRoleApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/role/delete?roleId=' + params)
}

// 5.角色详情
export const roleDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/role/info?id=' + params)
}

// 公海客户
// 1.公海客户列表
export const highSeasListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/list?name=' + params.name + '&page=' + params.page + '&size=' + params.size + '&addressAll=' + params.addressAll + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&state=' + params.state)
}

// 2.公海客户详情
export const highSeasDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/info?id=' + params)
}

// 3.删除公海客户
export const delSeasApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/delete?id=' + params)
}

// 4.分配公海客户
export const chooseSeasApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/allot?companyId=' + params.companyId + '&wxUserId=' + params.wxUserId)
}

// 5.公海客户行业类型
export const seasIndustryApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/industry', params)
}

// 6.修改公海客户
export const editHighSeasApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/DT/save', params)
}

// 7.公海客户数据导入记录列表
export const highSeasLoadListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/loadList?page=' + params.page + '&size=' + params.size)
}

export const highSeasErrorApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/DT/export?loadId=' + params,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '错误数据.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}

// 9.同步企业微信
export const getWeChatDeptApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/dept/WeChatDept')
}

// 客户管理
// 客户列表
export const customerListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/companyList?page=' + params.page + '&size=' + params.size + '&ifCancel=' + params.ifCancel + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&expDay=' + params.expDay + '&name=' + params.name)
}

// 新增客户
export const addCustomerApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/addCompany', params)
}
// 修改客户
export const editCustomerApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/updateCompany', params)
}
// 客户详情
export const customerDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/user/companyDetail?id=' + params)
}

// orc识别营业执照
export const ocrBusinessLicenseApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/ocrBusinessLicense?url=' + params)
}

// 获取省市区
export const getProvinceApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/position-list?pcode=' + params)
}

// 获取全部省市区
export const getProvinceAllApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/positionAllList')
}
// 获取行业表
export const industryListApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/industry-list?name=' + params)
}

// 获取多级行业表
export const industryLevelListApi = params => {
  return axios.get('http://apisrm.bmxgj.cn/sys/common/industry-level')
}

// 重置客户密码
export const resetPasswordApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/user/reset?userId=' + params)
}
// —————————————————会员管理__________——————————-
// 会员配置
export const vipListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/userLevel/list')
}
// 新增会员
export const addRoleInfoApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/userLevel/save', params)
}
// 删除会员
export const deleteVip = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/userLevel/delete?id=' + params.id)
}
// 修改会员
export const updateVipApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/userLevel/update', params)
}
// 详情会员
export const detailVip = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/userLevel/info?id=' + params)
}
// 获取所有菜单★
export const getVipMenuApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/client-menu/list')
}
// 企业会员列表
export const getCorporateListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/backend/userLevel/companyList?name=' + params.name + '&levelId=' + params.levelId + '&page=' + params.page + '&size=' + params.size)
}
// 开通续费企业会员
export const openMenberApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/backend/userLevel/activateMember', params)
}
// 通知配置列表请求
export const informListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/msg/configList')
}
// 通知节点列表
export const nodeListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/msg/pointModuleList?configId=' + params.configId + '&name=' + params.name)
}
// 启用接口
export const informStartApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/msg/enable/' + params)
}
// 禁用接口
export const informStopApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/msg/disable/' + params)
}
// 新增节点下拉列表请求
export const informNodeListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/msg/pointList?configId=' + params)
}
// 删除节点
export const informNodeDelApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/msg/del/' + params)
}
// 新增节点
export const informAddNodeApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/msg/add', params)
}
// 编辑数据回显
export const informDetailNodeApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/msg/info?id=' + params)
}
// 修改
export const informUpdateNodeApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/msg/edit', params)
}
// 企业审核列表
export const auditListApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/company/page?page=' + params.page + '&size=' + params.size + '&companyName=' + params.companyName + '&status=' + params.status)
}
export const auditDetailApi = params => {
  return axios.get('http://adminapi.bmxgj.cn/new/company/info?id=' + params)
}
export const auditNodeApi = params => {
  return axios.post('http://adminapi.bmxgj.cn/new/company/audit', params)
}
