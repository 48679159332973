// 操作手册 手册一级菜单(====帮助目录)
<template>
    <div class="helpMenuList">
      <!-- 卡片视图区 -->
      <el-card>
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <el-button type="success" class="examine_btn" @click="openMenuDialog()">新增</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="multipleTable" :data="allMenuData" border style="width: 100%">
              <el-table-column label="类型" sortable min-width="80px" show-overflow-tooltip align="center">
                <template>
                  <div class="cate">一级菜单</div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="一级菜单名称" sortable min-width="200px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="sort" label="排序编号" sortable min-width="100px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="状态" sortable min-width="80px" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @change="switchChange(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button class="lightDeepStyle" size="mini" @click="openEditMenu(scope.row)">编辑</el-button>
                    <el-button class="rejectStyle" size="mini" @click="delHelpMenu(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
      </el-card>
      <!-- 新增修改目录弹框 -->
      <el-dialog :title="controlOpen === true ? '新增一级菜单' : '修改一级菜单'" :visible.sync="newMenuVisible" width="500px" :close-on-click-modal="false" class="helpMenuListDialog">
        <div class="dialogInput"><span>菜单名称</span><el-input size="mini" v-model="addMenuData.name" placeholder="请输入菜单名称"></el-input></div>
        <div class="dialogInput"><span>排序编号</span><el-input size="mini" v-model="addMenuData.sort" placeholder="请输入排序编号"></el-input></div>
        <div class="dialogInput">
          <span>状态</span>
          <el-switch
            v-model="addMenuData.status"
            :active-value="1"
            :inactive-value="0"
            active-color="rgba(27, 199, 181, 1)"
            inactive-color="#eee">
          </el-switch>
        </div>
        <p slot="footer">
          <el-button v-if="controlOpen === false" ref="editBtn" class="lightDeepStyle" @click="editHelpMenu()">修 改</el-button>
          <el-button v-else ref="addBtn" class="lightDeepStyle" @click="addHelpMenu()">添 加</el-button>
          <el-button ref="addBtn" class="searchStyle" @click="newMenuVisible = false">取 消</el-button>
        </p>
      </el-dialog>
    </div>
</template>
<script>
import { getHelpMenuListApi, addHelpMenuApi, editHelpMenuApi, delHelpMenuApi } from '@/api'
export default {
  data() {
    return {
      // 控制新增菜单的弹出框
      newMenuVisible: false,
      // 判断当前打开的弹框时新增还是修改
      controlOpen: true,
      // 所有菜单目录
      allMenuData: [],
      addMenuData: {
        id: '',
        name: '',
        sort: '',
        status: 0
      },
      // 单选按钮选中状态menuId值
      radioValue: 0,
      nameInp: '请输入菜单名称'
    }
  },
  created() {
    this.getmanualList()
  },
  methods: {
    // 获取手册列表
    async getmanualList() {
      const { data: res } = await getHelpMenuListApi({ type: 2 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allMenuData = res.data.nameList
    },
    // 打开新增修改弹框
    openMenuDialog() {
      this.addMenuData = {
        id: '',
        name: '',
        sort: '',
        status: 0
      }
      this.controlOpen = true
      this.newMenuVisible = true
    },
    // 新增菜单目录
    async addHelpMenu() {
      const { data: res } = await addHelpMenuApi({ name: this.addMenuData.name, sort: this.addMenuData.sort, status: this.addMenuData.status, type: 2 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增成功!')
      this.newMenuVisible = false
      this.getmanualList()
    },
    // 修改菜单目录
    async editHelpMenu() {
      const { data: res } = await editHelpMenuApi({ id: this.addMenuData.id, name: this.addMenuData.name, sort: this.addMenuData.sort, status: this.addMenuData.status })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改成功!')
      this.newMenuVisible = false
      this.getmanualList()
    },
    // 修改状态
    switchChange(result) {
      this.addMenuData.id = result.id
      this.addMenuData.name = result.name
      this.addMenuData.sort = result.sort
      this.addMenuData.status = result.status
      this.editHelpMenu()
    },
    // 打开修改弹框
    async openEditMenu(result) {
      this.addMenuData.id = result.id
      this.addMenuData.name = result.name
      this.addMenuData.sort = result.sort
      this.addMenuData.status = result.status
      this.controlOpen = false
      this.newMenuVisible = true
    },
    // 删除菜单目录
    delHelpMenu(id) {
      this.$confirm('是否删除当前目录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delHelpMenuApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getmanualList()
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  margin-top: -20px;
}
.el-table div {
  width: 140px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translate(-16%);
  }
}
.el-table .cate {
  margin-left: 50%;
  transform: translate(-50%);
  width: 80px;
  background-color:#17706e;
}
.el-table .list {
  margin-left: 50%;
  transform: translate(-50%);
  width: 80px;
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
/deep/ .helpMenuListDialog {
  .el-dialog {
    height: 360px;
    .el-dialog__body {
      height: 220px;
      .dialogInput {
        span {
          margin-right: 10px;
          display: inline-block;
          width: 70px;
          text-align: right;
        }
        .el-input {
          width: 360px;
        }
      }
    }
  }
}
</style>
