//  报价审核管理
<template>
    <div class="registReview_content">
      <!-- 卡片视图区 -->
      <el-card>
        <!-- 内容搜索区 -->
        <el-row :gutter="20">
          <el-col :span="2">
            <p>查询条件:</p>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="请输入公司名称或物理描述" clearable v-model="tableValue.keyword" @input="getQuoteList()" @keyup.enter.native="getQuoteList()">
            </el-input>
          </el-col>
          <!-- <el-col :span="2">
            <el-button type="info" @click="getQuoteList()">查询</el-button>
          </el-col> -->
          <el-col :span="2">
            <p>审核状态:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.audit" placeholder="请选择审核状态" @change="getQuoteList()">
              <el-option label="全部" :value="''"></el-option>
              <el-option label="审核中" :value="0"></el-option>
              <el-option label="审核通过" :value="1"></el-option>
              <el-option label="驳回" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <p>所属模块:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.moduleType" placeholder="请选择所属模块" @change="getQuoteList()">
              <el-option label="全部" :value="''"></el-option>
              <el-option label="采购寻源" :value="1"></el-option>
              <el-option label="废料交易" :value="2"></el-option>
              <el-option label="库存特价" :value="3"></el-option>
              <el-option label="外协加工" :value="4"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!-- 物料定义表格区 -->
        <el-row>
          <el-table :height="screenHeight" tooltip-effect="dark" ref="multipleTable" :data="tableData" border style="width: 100%" show-overflow-tooltip :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column type="index" label="行号" width="50px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="provinceName" label="图片" min-width="120px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <el-image :preview-src-list="srcList" @click="srcList.push(scope.row.pic)" style="width: 90px; height:60px" :src="scope.row.pic"></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="客户名称"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="frontName" label="物料信息"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="describes" label="物料描述"  min-width="150px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;">{{scope.row.describes == '' ? '暂无' : scope.row.describes}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="scaleDescribe" label="单价区间"  min-width="120px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-if="scope.row.minPrice !== '' && scope.row.maxPrice !== ''">￥{{scope.row.minPrice}} - ￥{{scope.row.maxPrice}}</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else-if="scope.row.minPrice !== '' && scope.row.maxPrice === ''">￥{{scope.row.minPrice}}</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else>暂无</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="productUnit" label="单位"  min-width="80px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="addressName" label="货物所在地"  min-width="160px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="invalidDay" label="有效期"  min-width="80px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;">{{scope.row.invalidStrTime}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="scaleDescribe" label="可接受定做"  min-width="100px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-if="scope.row.isMade === 1">是</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else>否</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="scaleDescribe" label="审核状态"  min-width="120px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.audit === 0"><p style="background-color: #409EFF"></p>待审核</div>
                  <div v-else-if="scope.row.audit === 1"><p style="background-color: green"></p>审核通过</div>
                  <div v-else><p style="background-color: red;"></p>审核拒绝</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间"  min-width="120px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;">{{(scope.row.createTime).substring(0, 10)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="moduleType" label="所属模块"  min-width="90px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-if="scope.row.moduleType === 1">采购寻源</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else-if="scope.row.moduleType === 2">废料交易</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else-if="scope.row.moduleType === 3">库存特价</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else>外协加工</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="驳回原因"  min-width="150px"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-if="scope.row.reason !== ''">{{scope.row.reason}}</span>
                  <span style="display: inline-block; width: 100%; text-align: center;" v-else>暂无</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150px" fixed="right" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.audit === 0 && scope.row.audit == 2">用户已结束</span>
                <span v-else-if="scope.row.audit === 0 && scope.row.isInvalid != 0">已过期</span>
                <div v-else-if="scope.row.audit === 0 && scope.row.status == 0">
                  <el-button class="passBtn" size="mini" @click="passConfirm(scope.row, 1)"><a>通过</a></el-button>
                  <el-button type="danger" size="mini" @click="passConfirm(scope.row, 2)"><a>驳回</a></el-button>
                </div>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="tableValue.size"
            :total="total">
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { quoteListApis, examinInfoApi, keepMessageApi } from '@/api'
export default {
  name: 'App',
  data() {
    return {
      // 预览的图片
      srcList: [],
      tableValue: {
        page: 1,
        size: 4,
        audit: '',
        keyword: '',
        moduleType: ''
      },
      // 获取表格数据
      tableData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 获取当前浏览器的高度
      screenHeight: '',
      modelTypeTitle: ''
    }
  },
  created() {
  },
  mounted() {
    this.screenHeight = document.body.clientHeight - 400
    if (this.screenHeight >= 1200) {
      this.tableValue.size = 8
    } else if (this.screenHeight >= 500) {
      this.tableValue.size = 6
    } else {
      this.tableValue.size = 4
    }
    this.getQuoteList()
  },
  methods: {
    // 通过或驳回审核
    async passConfirm(item, index) {
      if (index === 2) {
        this.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async({ value }) => {
          if (value === null || value === '') return this.$message.error('驳回原因不能为空!')
          const { data: res } = await examinInfoApi({ id: item.id, reason: value, audit: index })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('驳回成功!')
          // 驳回成功的时候通知被驳回用户
          //  确认发布类型
          this.modelTypeTitle = ''
          switch (item.moduleType) {
            case 1: this.modelTypeTitle = '采购寻源'
              break
            case 2: this.modelTypeTitle = '废料交易'
              break
            case 3: this.modelTypeTitle = '库存特价'
              break
            case 4: this.modelTypeTitle = '外协加工'
              break
          }
          const { data: result } = await keepMessageApi({ messageTitle: this.modelTypeTitle + '审核不通过', messageContent: '您好,您发布的' + this.modelTypeTitle + '审核不通过, 原因 : [' + value + ']', messageOtherTitle: item.frontName, messageOther: '数量 : ' + item.num + item.productUnit, messageOtherCompanyId: item.companyId, messageOtherUserId: item.userId, messageType: 2, messageOtherFrontType: item.frontType, messageOtherModuleType: item.moduleType, messageOtherPic: item.pic, messageOtherIfRead: 0, messageOtherCategory: 3, messageOtherId: item.id, messageOtherInfoId: '' })
          if (result.code !== 0) return this.$message.error(result.msg)
          this.getQuoteList()
        }).catch(() => {
          this.$message({
            type: 'warn',
            message: '取消'
          })
        })
      } else {
        const { data: res } = await examinInfoApi({ id: item.id, reason: '', audit: index })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('审批通过!')
        this.getQuoteList()
      }
    },
    // 获取报价审核列表
    async getQuoteList() {
      const { data: res } = await quoteListApis(this.tableValue)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 获取表单数据
      this.tableData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
      // 确认发布信息标题
      this.tableData.forEach((item, index) => {
        if ((item.frontType === 1 || item.frontType === 2) && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 3 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3 + item.item4
        } else if (item.frontType === 4 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item4
        } else if (item.frontType === 5 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 6 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3
        } else if (item.moduleType === 2) {
          item.frontName = item.labelIdsName
        } else if (item.moduleType === 4) {
          item.frontName = item.item1
        }
      })
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.tableValue.page = val
      this.currentPage = val
      this.getQuoteList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 620px;
  }
  @media screen and (max-width: 1440px) {
    height: 500px;
  }
}
.el-card p {
  margin-right: -20px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.el-card .el-col>.el-button {
  width:70px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .el-button--info {
  background-color: rgba(0,0,0,.8);
}
.el-card .el-button--info:hover {
  background-color: rgba(0,0,0,.7);
}
.el-card .el-button--info:active {
  background-color: rgba(0,0,0,.9);
}
.el-card /deep/ .el-input__inner:focus {
  border: 1px solid #000;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  padding: 12px 0;
  width: 80px;
  height: 34px;
  background: rgba(255,255,255,1);
  border-radius: 3px;
  color: #666;
  line-height: 0
}
.el-card .add_btn {
  float: right;
}
.el-card .cancelExamine_btn {
  padding:12px 0;
  width:80px;
  height:34px;
  background:rgba(255,255,255,1);
  border-radius:3px;
  color: #666;
  line-height: 0;
}
.el-card .el-menu--horizontal {
    height: 60px;
    border-bottom: 1px solid #ccc!important;
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: 30px;
  right: 0;
}
.el-table .cell div {
  display: flex;
}
.el-table .cell p {
  margin: 8px 10px;
  width: 8px;
  height: 8px;
  background-color: #B7B7B7;
  border-radius: 50%;
  box-shadow: 1px 1px 1px black,-1px -1px 1px white;
}
.el-table .cell .el-button {
  border: 0;
}
.el-table .cell .passBtn {
  background-color: #009688;
}
.el-table .cell .passBtn:hover {
  background-color: rgba(0,150,136,.7)
}
.el-table .cell .passBtn:active {
  background-color: rgba(0,150,136,.9)
}
.el-table .cell a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.el-table .cell img {
  margin: 0 auto;
  display: block;
  width: 33px;
  height: 33px;
}
.el-menu-demo {
  margin: -20px 0 20px 0;
  font-weight: 400;
}
// 弹出查看窗口样式
.el-dialog__body li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.el-dialog__body li .company_name {
  font-size: 20px;
  color: #01519B;
}
.el-dialog__body p {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.el-dialog__body .float_l {
  display: inline-block;
  width: 265px;
}
.el-dialog__body .float_r {
  display: inline-block;
  width: 265px;
}
</style>
