<template>
    <div class="roleManageEdit">
      <header>
        <ul>
          <li>岗位权限编码 : <span>{{roleData.roleId}}</span></li>
          <li>岗位权限名称 : <el-input type="text" v-model="roleData.roleName" placeholder="请输入"></el-input></li>
          <li>部门 : <el-input type="text" v-model="roleData.dept" placeholder="请输入"></el-input></li>
          <li>上级岗位 :
            <el-select v-model="roleData.parentId" clearable placeholder="请选择">
              <el-option
                v-for="item in superArr"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId">
              </el-option>
            </el-select>
          </li>
        </ul>
        <nav>
          <p>数据权限设置</p>
          <el-table :data="tableData" style="width: 97%">
            <el-table-column prop="date" label="数据权限" min-width="30%" align="center"></el-table-column>
            <el-table-column prop="name" label="是否开启" min-width="20%" align="center">
              <template>
                <el-switch></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="说明"></el-table-column>
          </el-table>
        </nav>
      </header>
      <main>
        <el-button size="mini" @click="selectAll()">全选</el-button>
        <el-button size="mini" @click="selectNotAll()">全不选</el-button>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" index="1" v-for="item in allMenuData" :key="item.menuId">
            <ul>
              <!-- 第二层 -->
              <li v-for="subItem in item.children" :key="subItem.menuId">
                <p>{{subItem.name}}</p>
                <!-- 第三层 -->
                <div v-for="endItem in subItem.children" :key="endItem.menuId">
                  <span>{{endItem.name}}</span>
                  <el-switch v-if="endItem.orderNum === true" v-model="btnValueA[endItem.menuId]" :active-value="btnValueA[endItem.menuId]" @click.native.prevent="changeStatus(btnValueA[endItem.menuId], endItem.menuId)" :inactive-value="0" active-color="#FEB300"></el-switch>
                  <el-switch v-if="endItem.orderNum === false" v-model="btnValueB[endItem.menuId]" :active-value="endItem.menuId" :inactive-value="0" @click.native.prevent="changeStatus2(btnValueB[endItem.menuId], endItem.menuId)" active-color="#FEB300"></el-switch>
                  <!-- 按钮层 -->
                  <el-checkbox-group v-model="roleData.menuIdList" v-for="btnItem in endItem.children" :key="btnItem.menuId">
                    <el-checkbox :label="btnItem.menuId">{{btnItem.name}}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </li>
            </ul>
          </el-tab-pane>
          <el-button @click="addRoleDetails()" v-if="this.$route.query.roleId === undefined"><i class="el-icon-document-checked"></i> 确认新增</el-button>
          <el-button @click="editRoleDetails()" v-else><i class="el-icon-document-checked"></i> 保存</el-button>
        </el-tabs>
      </main>
    </div>
</template>
<script>
import { getRoleDetailsApi, editRoleDetailsApi, addRoleDetailsApi, getAllMenuApi } from '@/api'
export default {
  data() {
    return {
      tableData: [{
        date: '价格查看权限',
        name: 'hi',
        address: '无该权限的岗位, 在查看相关业务单据时价格和金额显示为*****'
      }],
      // 默认选中标签页
      activeIndex: '1',
      // activeName这个值没用但不写会报错
      activeName: '',
      // 存储上级岗位的数组
      superArr: JSON.parse(window.sessionStorage.getItem('postListData')),
      // 所有菜单
      allMenuData: [],
      roleData: {
        // 当前角色id
        roleId: this.$route.query.roleId,
        // 备注
        remark: '',
        // 上级岗位id
        parentId: null,
        // 角色名称
        roleName: '',
        // 部门名称
        dept: '',
        // 权限菜单
        menuIdList: []
      },
      // 初始开启的开关
      btnValueA: [],
      // 初始未开启的开关
      btnValueB: []
    }
  },
  created() {
    this.getRoleDetails()
    // 所有岗位中删除当前岗位
    if (this.roleData.roleId !== undefined) {
      for (var i = 0; i < this.superArr.length; i++) {
        if (this.superArr[i].roleId === this.roleData.roleId - 0) {
          this.superArr.splice(i, 1)
        }
      }
    }
    this.changeRoleName()
  },
  mounted() {
    this.getAllMenu()
  },
  methods: {
    handleClick() {
    },
    // 获取角色详情
    async getRoleDetails() {
      if (this.roleData.roleId === undefined) return false
      const { data: res } = await getRoleDetailsApi(this.roleData.roleId)
      this.roleData.menuIdList = res.data.role.menuIdList
      this.roleData = res.data.role
      // 如果没有上级岗位就显示为空
      if (this.roleData.parentId === 0) {
        this.roleData.parentId = ''
      }
      this.btnValueA = this.roleData.menuIdList
      this.btnValueB = this.roleData.menuIdList
    },
    // 修改角色详情
    async editRoleDetails() {
      this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== null })
      const { data: res } = await editRoleDetailsApi(this.roleData)
      if (res.code !== 0) return this.$message.error('修改角色详情失败!')
      this.$message.success('修改角色成功!')
      this.$router.push('/roleManage')
    },
    // 新增角色详情
    async addRoleDetails() {
      this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== null })
      const { data: res } = await addRoleDetailsApi(this.roleData)
      if (res.code !== 0) return this.$message.error('新增角色失败!')
      this.$message.success('新增角色成功!')
      this.$router.push('/roleManage')
    },
    // 获取所有菜单
    async getAllMenu() {
      const { data: res } = await getAllMenuApi()
      let data = res.data.menuList
      // 左侧菜单数据修改为树形结构
      function setTreeData(arr) {
        let map = {}
        arr.forEach(i => {
          map[i.menuId] = i
        })
        let treeData = []
        arr.forEach(child => {
          const mapItem = map[child.parentId]
          if (mapItem) {
            (mapItem.children || (mapItem.children = [])).push(child)
          } else {
            treeData.push(child)
          }
        })
        return treeData
      }
      setTimeout(() => {
        this.allMenuData = setTreeData(data)
        this.changeStatus()
      }, 0)
    },
    // 根据权限菜单改变roleName的状态(!!!)
    changeRoleName() {
      this.allMenuData.forEach(item => {
        if (item.children !== undefined) {
          item.children.forEach(items => {
            // 第三层菜单层 start
            if (items.children !== undefined) {
              items.children.forEach(itemss => {
                itemss.orderNum = false
                this.roleData.menuIdList.forEach(res => {
                  if (res === itemss.menuId) {
                    itemss.orderNum = true
                  }
                })
                // 按钮层 start
                if (itemss.children !== undefined) {
                  itemss.children.forEach(itemsss => {
                    itemsss.orderNum = false
                    this.roleData.menuIdList.forEach(ress => {
                      if (ress === itemsss.menuId) {
                        itemsss.orderNum = true
                      }
                    })
                  })
                }
                // 按钮层 end
              })
            }
            // 第三层菜单层 end
          })
        }
      })
    },
    // 初始开启按钮权限操作
    changeStatus(status, menuId) {
      if (status !== true) {
        this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== menuId })
        this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== 0 })
        this.changeRoleName()
      }
      // console.log(this.roleData.menuIdList, '我从第一个按钮变成第二个按钮')
    },
    // 初始关闭按钮操作
    changeStatus2(status, menuId) {
      if (status === menuId) {
        this.roleData.menuIdList.push(menuId)
      } else {
        this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== menuId })
        this.roleData.menuIdList = this.roleData.menuIdList.filter(item => { return item !== 0 })
      }
      // console.log(this.roleData.menuIdList, '我是第二个按钮')
    },
    // 全不选
    selectAll() {
      let allMenu = []
      this.allMenuData.forEach(i => {
        allMenu.push(i.menuId)
        i.children.forEach(item => {
          allMenu.push(item.menuId)
          if (item.children !== undefined) {
            item.children.forEach(items => {
              allMenu.push(items.menuId)
              if (items.children !== undefined) {
                items.children.forEach(itemss => {
                  allMenu.push(itemss.menuId)
                })
              }
            })
          }
        })
      })
      this.roleData.menuIdList = allMenu
      setTimeout(() => {
        this.changeRoleName()
      }, 1)
    },
    // 全不选
    selectNotAll() {
      this.roleData.menuIdList = []
      this.changeRoleName()
    }
  }
}
</script>
<style scoped lang='less'>
.roleManageEdit header {
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.roleManageEdit header ul {
  display: flex;
  margin: 10px;
  padding: 15px 10px 20px;
  border-bottom: 1px dashed #ccc;
}
.roleManageEdit header ul li {
  flex: 1;
}
.roleManageEdit header nav p {
  margin-left: 10px;
  color: rgb(150, 146, 33);
  font-size: 16px;
  font-weight: 500;
}
.roleManageEdit header nav .el-table {
  margin: 20px;
  border: 1px solid #ccc;
}
.roleManageEdit main {
  position: relative;
  margin-top: 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.roleManageEdit main .el-button {
  background-color: rgba(254,179,0,1);
  border: 0;
  color: #fff;
  cursor: pointer;
  z-index: 1;
}
.roleManageEdit main .el-button:nth-child(1) {
  position: absolute;
  top: 12px;
  right: 120px;
}
.roleManageEdit main .el-button:nth-child(2) {
  position: absolute;
  top: 12px;
  right: 40px;
}
.roleManageEdit main .el-button:hover {
  background-color: rgba(254,179,0,.7);
}
.roleManageEdit main li {
  margin: 15px 30px;
  border: 1px solid #ccc;
  background-color: #F5F5F5;
}
.roleManageEdit main li p {
  margin: 10px 0 0 10px;
}
.roleManageEdit main li div {
  margin: 5px 20px;
  font-size: 14px;
  font-weight: 400;
}
.roleManageEdit main li div span {
  display: inline-block;
  min-width: 200px;
}
.roleManageEdit main li .el-switch {
  margin-left: 20px;
}
.roleManageEdit main li .el-checkbox-group {
  display: inline-block;
  margin-left: 20px;
}
.roleManageEdit main .el-tabs .el-button {
  margin: 0 50% 10px 50%;
  transform: translate(-50%);
  background-color: rgb(245,108,108);
}
.roleManageEdit main .el-tabs .el-button:hover {
  background-color: rgba(245,108,108, .7);
}
</style>
