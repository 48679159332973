// 出库管理 出库列表
<template>
    <div class="orderList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <p>
            <span>出库单号 : </span>
            <el-input v-model="outBoundData.outboundNum" placeholder="请输入出库单号" type="text" size="small" maxlength="40"></el-input>
            <span>订单编号/客户名称 : </span>
            <el-input v-model="outBoundData.orderNumAndCompanyName" placeholder="请输入出库单号" type="text" size="small" maxlength="40"></el-input>
            <span>审批状态 : </span>
            <el-select style="width: 160px;" v-model="outBoundData.status" placeholder="请选择" size="mini" clearable>
              <el-option label="待审核" value="0"></el-option>
              <el-option label="审核通过" value="1"></el-option>
              <el-option label="拒绝" value="2"></el-option>
            </el-select>
            <span>创建日期 : </span>
            <el-date-picker
              v-model="outBoundData.createTimeBegin"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span style="width: 30px; margin-right: 0">至</span>
            <el-date-picker
              v-model="outBoundData.createTimeEnd"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </p>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getOutBoundList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="outboundNum" label="出库单号" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="cateName" label="品名" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="val" label="自定义" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="num" label="出库数量" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="unit" label="单位" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="实出金额(元)" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.num * scope.row.price}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="companyName" label="客户名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="orderNum" label="订单编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="createTime" label="创建时间" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="userName" label="制单人" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="remark" label="驳回原因" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.remark !== ''">{{scope.row.remark}}</span>
                    <span v-else>暂无</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="审批状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.status === 0" style="color: rgba(51, 158, 191, 1);">待审核</span>
                    <span v-else-if="scope.row.status === 1" style="color: #38B865;">审核通过</span>
                    <span v-else style="color: #EB1313;">拒绝</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-around">
                    <span v-if="scope.row.status === 0" style="cursor: pointer; color: #38B865" size="mini" @click.stop="editStatus(1, '', scope.row.id)">通过</span>
                    <span v-if="scope.row.status === 0" style="cursor: pointer; color: #EB1313" size="mini" @click.stop="openBox(scope.row.id)">拒绝</span>
                    <span v-else>暂无</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="outBoundData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { getOutBoundListApi, outBoundApprovalApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      outBoundData: {
        outboundNum: '',
        orderNumAndCompanyName: '',
        status: '',
        createTimeBegin: '',
        createTimeEnd: '',
        page: 1,
        size: 10
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      orderList: [],
      // 选中的项目
      chooseItem: ''
    }
  },
  created() {
    this.getOutBoundList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单列表
    async getOutBoundList() {
      if (this.outBoundData.createTimeBegin === null) this.outBoundData.createTimeBegin = ''
      if (this.outBoundData.createTimeEnd === null) this.outBoundData.createTimeEnd = ''
      const { data: res } = await getOutBoundListApi(this.outBoundData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.outBoundData.page = val
      this.currentPage = val
      this.getOutBoundList()
    },
    // 审批操作
    async editStatus(status, remark, id) {
      const { data: res } = await outBoundApprovalApi({ id: id, status: status, remark: remark })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (status === 1) {
        this.$message.success('审批通过!')
      } else {
        this.$message.success('拒绝成功!')
      }
      this.getOutBoundList()
    },
    //  驳回
    openBox(id) {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (value === null || value === '') return this.$message.error('驳回原因不能为空!')
        this.editStatus(2, value, id)
      }).catch(() => {
        this.$message({
          type: 'warn',
          message: '取消'
        })
      })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  >p {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    span {
      margin-right: 5px;
      display: inline-block;
      line-height: 28px;
    }
    .el-input {
      margin-right: 10px;
      width: 160px;
    }
    .el-select {
      margin-right: 5px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
</style>
