// 角色管理
<template>
  <div class="roleManage_content">
    <!-- 全局权限 -->
    <nav>
      <p>全局权限设置</p>
    </nav>
    <!-- 岗位权限 -->
    <main>
      <p>岗位权限</p>
      <el-input placeholder="请输入岗位权限名称" clearable v-model="roleManageData.search" @keyup.enter.native="getRoleList()"></el-input>
      <el-button type="info" @click="routerPush()">+ 新增</el-button>
      <el-table :data="roleData" stripe style="width: 98%">
        <el-table-column prop="roleId" label="岗位权限编码" min-width="10%" width="200px" align="center"></el-table-column>
        <el-table-column prop="roleName" label="岗位权限名称" min-width="30%" align="center"></el-table-column>
        <el-table-column prop="dept" label="部门" min-width="30%" align="center"></el-table-column>
        <el-table-column prop="parentName" label="上级岗位" min-width="30%" align="center"></el-table-column>
        <el-table-column prop="" label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="routerPush(scope.row.roleId)">权限编辑</el-button>
            <el-button size="mini" @click="delDefine(scope.row.roleId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页功能 -->
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        :page-size="roleManageData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { getRoleListApi, delRoleApi } from '@/api'
export default {
  data() {
    return {
      // 获取当前浏览器的宽度
      screenWidth: '',
      roleData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      roleManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 7,
        // 合同名称
        search: ''
      },
      // 存储总的岗位列表数据
      postListData: []
    }
  },
  created() {
    this.getRoleList()
  },
  mounted() {
  },
  methods: {
    routerPush(roleId) {
      this.$router.push({ path: '/roleManageEdit', query: { roleId: roleId } })
    },
    // 获取角色列表
    async getRoleList() {
      const { data: res } = await getRoleListApi(this.roleManageData)
      // 获取表单数据
      this.roleData = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
      this.roleData.forEach(item => {
        let roleObj = { roleId: item.roleId, roleName: item.roleName }
        this.postListData.push(roleObj)
      })
      window.sessionStorage.setItem('postListData', JSON.stringify(this.postListData))
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.roleManageData.page = val
      this.currentPage = val
      this.getRoleList()
    },
    // 删除确认框
    delDefine(id) {
      this.$confirm('确定要删除该角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await delRoleApi([id])
        if (res.code !== 0) return this.$message.error('删除角色失败!')
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getRoleList()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>
<style scoped lang='less'>
.roleManage_content nav {
  width: 100%;
  height: 150px;
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.roleManage_content main>.el-button {
  position: absolute;
  top: 14px;
  right: 10px;
  margin-left: 20px;
  width:90px;
  height: 32px;
  line-height: 0;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  background-color: #49505A;
}
.roleManage_content  main>.el-button:hover {
  background-color: rgba(73,80,90, .7)
}
.roleManage_content p {
  display: inline-block;
  margin: 20px;
}
.roleManage_content .el-input {
  position: absolute;
  top: 14px;
  right: 120px;
  height: 32px;
  width: 260px;
}
.roleManage_content main {
  position: relative;
  margin-top: 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.roleManage_content .el-table {
  margin: 10px;
  border: 1px solid #ccc;
}
.roleManage_content .el-table .el-button:nth-child(1) {
  border: 0;
  background-color: #FEB300;
  color: #fff;
}
.roleManage_content .el-table .el-button:nth-child(2) {
  border: 0;
  background-color: red;
  color: #fff;
}
.roleManage_content .el-table .el-button:hover{
  background-color: #ec823a;
}
.el-pagination {
  float: right;
  margin: 10px;
}
</style>
