// 用户管理 标签用户
<template>
    <div class="labelUser">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <p><span>企业名称 : </span><el-input v-model="userLabelData.name" placeholder="请输入企业名称" type="text" clearable autocomplete="off" size="mini" @input="getUserLabelList()"></el-input></p>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="userLabelList" border style="width: 100%;">
              <el-table-column prop="name" label="LOGO" width="90px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <el-image style="width: 60px; height:60px" :src="scope.row.pic" alt=""></el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="企业名称" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="regNumber" label="统一社会信用代码" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="industry" label="行业类型" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="" label="经营属性" min-width="20px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div class="businessCenter">
                    <div class="businessBox" v-for="(item, index) in scope.row.businessAttributeString" :key="index">{{item}}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="入驻时间" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="contact" label="联系人" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="mobile" label="联系电话" min-width="20px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-around">
                    <el-button class="keepBtn" size="mini" @click="editModel(scope.row)">配置属性</el-button>
                    <el-button type="primary" size="mini" @click="routerJump(scope.row)">查看</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="userLabelData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <!-- 菜单模板 -->
      <template>
        <div>
          <el-dialog class="miniDialog" title="配置属性" :visible.sync="menuVisible" width="30%" :close-on-click-modal="false">
            <p><span>企业名称 : </span>{{detailData.name}}</p>
            <p><span>统一社会信用代码 : </span>{{detailData.regNumber === '' ? '暂无' : detailData.regNumber}}</p>
            <p>
              <span>经营属性 : </span>
              <el-checkbox-group v-model="checkedBusiness">
                <el-checkbox v-for="(item, index) in businessList" :label="item.id" :key="index">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </p>
            <div slot="footer">
              <el-button size="mini" @click="cancelChoose()">取 消</el-button>
              <el-button size="mini" type="primary" @click="saveBusiness()">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </template>
    </div>
</template>
<script>
import { businessListApi, userLabelListApi, userLabelInfoApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      userLabelData: {
        page: 1,
        size: 10,
        name: ''
      },
      detailData: {
        id: '',
        name: '',
        sort: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 标签用户列表
      userLabelList: [],
      // 经营属性列表
      businessList: [],
      // 控制新增菜单的弹出框
      menuVisible: false,
      // 勾选的经营属性
      checkedBusiness: [],
      checkedId: ''
    }
  },
  created() {
    this.getUserLabelList()
    this.getuserLabelList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 取消选择
    cancelChoose() {
      this.checkedBusiness = []
      this.menuVisible = false
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'labelUserDetail', query: { result: JSON.stringify(result) } })
    },
    // 获取标签用户列表
    async getUserLabelList() {
      const { data: res } = await userLabelListApi(this.userLabelData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userLabelList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.userLabelData.page = val
      this.currentPage = val
      this.getUserLabelList()
    },
    editModel(result) {
      this.detailData = result
      if (result.businessAttribute !== '' && result.businessAttribute !== null) {
        this.checkedBusiness = (result.businessAttribute).split(',').map(i => Number(i))
      }
      this.checkedId = result.id
      this.menuVisible = true
    },
    // 获取经营属性列表
    async getuserLabelList() {
      const { data: res } = await businessListApi({ page: 1, size: 99999 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.businessList = res.data.data.records
    },
    // 保存经营属性
    async saveBusiness() {
      if (this.checkedBusiness === '' && this.checkedBusiness === null) return this.$message.error('经营属性不能为空!')
      const { data: res } = await userLabelInfoApi({ id: this.checkedId, businessAttribute: (this.checkedBusiness).toString() })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改成功!')
      this.menuVisible = false
      this.getUserLabelList()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  width: 100%;
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    span {
      margin-right: 10px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
.businessCenter {
  width: 100%;
  white-space: pre-wrap;
  .businessBox {
    margin-right: 11px;
    padding: 0 7px;
    display: inline-block;
    height: 20px;
    background-color: #3AABC2;
    color: #FFFFFF;
    line-height: 20px;
  }
}
/deep/ .miniDialog .el-dialog {
  width: 700px!important;
  height: 524px;
  .el-dialog__header {
    height: 28px;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(60,141,188,.1);
    .el-dialog__title {
      color: #000000;
    }
    .el-dialog__close::before {
      color: #000000;
    }
  }
  .el-dialog__body {
    height: 415px;
    >p {
      display: flex;
      justify-content: flex-start;
      line-height: 40px;
      color: #333333;
      >span {
        margin-right: 5px;
        display: inline-block;
        width: 125px;
        text-align: right;
        font-size: 14px;
        color: #000000;
        font-weight: 500;
      }
      .el-checkbox-group {
        margin-left: 5px;
        width: calc(100% - 125px);
        white-space: pre-wrap;
        .el-checkbox__label {
          width: 120px;
        }
      }
    }
  }
  .el-dialog__footer {
    height: 46px;
    line-height: 46px;
    >div {
      height: 100%;
      line-height: 100%;
    }
  }
}
</style>
