// 后台菜单管理
<template>
    <div class="menuManage_contaner">
      <!-- 卡片视图区 -->
      <el-card>
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <el-button type="success" class="examine_btn" @click="addMenu()">新增</el-button>
          <el-button type="primary" class="examine_btn" @click="getMenuDetail()">修改</el-button>
          <el-button type="danger" class="examine_btn" @click="delTips()">删除</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="multipleTable" :data="allMenuData" border style="width: 100%" row-key="menuId" :tree-props="{childList: 'childList'}" highlight-current-row  :default-sort="{ prop: 'orderNum'}">
              <el-table-column label="选择" width="60" header-align="center" align="right">
                <template slot-scope="scope">
                    <el-radio v-model="radioValue" :label="scope.row.menuId">&nbsp;</el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" sortable min-width="11%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="list" v-if="scope.row.type === 0">目 录</div>
                  <div class="menu" v-if="scope.row.type === 1">菜 单</div>
                  <div class="btn" v-if="scope.row.type === 2">按 钮</div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="菜单名称" min-width="13%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="menuId" label="菜单id" sortable min-width="11%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="parentName" label="父级菜单" sortable min-width="13%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="icon" label="图标" sortable min-width="13%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="orderNum" label="排序编号" sortable min-width="12%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="url" label="菜单链接url" sortable min-width="15%" show-overflow-tooltip></el-table-column>
              <el-table-column prop="perms" label="授权编码" min-width="12%" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-row>
      </el-card>
      <!-- 菜单模板 -->
      <template id="newMenu">
        <div>
          <el-dialog title="菜单操作" :visible.sync="newMenuVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :default-sort="{prop: 'orderNum', order: 'ascending'}">
              <el-form-item prop="type">
                  <p>类型:</p>
                  <el-radio-group v-model="ruleForm.type" @change="radiosChange">
                    <el-radio :label="0">目录</el-radio>
                    <el-radio :label="1">菜单</el-radio>
                    <el-radio :label="2">按钮</el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item prop="name">
                  <p class="p_name">新增菜单名称: </p><el-input v-model="ruleForm.name" :placeholder="nameInp" type="text" clearable autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="parentId" ref="cas1">
                  <p>父级目录: </p><el-cascader :options="allMenuData" :props="defaultData" clearable v-model="ruleForm.parentId" placeholder="请选择父级目录"></el-cascader>
              </el-form-item>
              <el-form-item prop="parentId" ref="cas2" style="display: none">
                  <p>父级菜单: </p><el-cascader :options="allMenuData" :props="defaultData2" clearable v-model="ruleForm.parentId" placeholder="请选择父级菜单"></el-cascader>
              </el-form-item>
              <el-form-item prop="url" ref="url">
                  <p>菜单跳转url: </p><el-input v-model="ruleForm.url" placeholder="菜单跳转url" type="text" clearable></el-input>
              </el-form-item>
              <el-form-item prop="orderNum">
                  <p>排序编号: </p><el-input v-model="ruleForm.orderNum" placeholder="请输入排序编号" type="text" clearable></el-input>
              </el-form-item>
              <el-form-item prop="icon" ref="icon" style="display: none">
                  <p>图标: </p><el-input v-model="ruleForm.icon" placeholder="菜单图标" type="text" clearable></el-input>
              </el-form-item>
              <div class="tipsInfo" style="display: none"><a href="https://www.iconfont.cn/collections/detail?spm=a313x.7781069.1998910419.d9df05512&cid=19238">点我获取图标库</a>(写入前加入iconfont icon-)</div>
            </el-form>
            <p slot="footer">
              <el-button @click="newMenuVisible = false">返 回</el-button>
              <el-button ref="editBtn" type="primary" @click="editMenuItem()" style="display: none">修 改</el-button>
              <el-button ref="addBtn" type="primary" @click="addMenuItem()">添 加</el-button>
            </p>
          </el-dialog>
        </div>
      </template>
    </div>
</template>
<script>
import { getAllBackMenuApi, addBackMenuItemApi, getBackMenuDetailApi, editBackMenuItemApi, delBackMenuItemApi } from '@/api'
export default {
  data() {
    return {
      // 表单数据
      ruleForm: {
        name: '',
        icon: '',
        url: '',
        type: 1,
        parentId: 0,
        orderNum: null
      },
      // 登录框校验
      rules: {
      },
      // 控制新增菜单的弹出框
      newMenuVisible: false,
      // 所有菜单数据
      allMenuData: [],
      // 单选按钮选中状态menuId值
      radioValue: 0,
      defaultData: {
        value: 'menuId',
        label: 'name',
        children: null,
        // 控制级联选择器只选则单个值
        emitPath: false
      },
      defaultData2: {
        value: 'menuId',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false
      },
      nameInp: '请输入菜单名称'
    }
  },
  created() {
    this.getAllMenu()
  },
  methods: {
    // 获取所有菜单
    async getAllMenu() {
      const { data: res } = await getAllBackMenuApi()
      let data1 = res.data.menuList
      // 左侧菜单数据修改为树形结构
      function setTreeData(arr) {
        let map = {}
        arr.forEach(i => {
          map[i.menuId] = i
        })
        let treeData = []
        arr.forEach(child => {
          const mapItem = map[child.parentId]
          if (mapItem) {
            (mapItem.children || (mapItem.children = [])).push(child)
          } else {
            treeData.push(child)
          }
        })
        return treeData
      }
      this.allMenuData = setTreeData(data1)
      this.allMenuData.forEach(item => {
        // child是为菜单级联准备的
        item.child = item.children
        item.child.forEach(items => {
          items.child = null
        })
        // tree是为按钮级联准备的
        item.tree = item.children
        item.tree.forEach(res => {
          res.tree = res.children
          if (res.tree !== undefined) {
            res.tree.forEach(ress => {
              ress.tree = undefined
            })
          }
        })
      })
    },
    // 单选按钮触发修改弹出框
    radiosChange() {
      // 图标标签
      let icon = this.$refs.icon.$el
      let iconTips = document.getElementsByClassName('tipsInfo')
      // 路由跳转标签
      let url = this.$refs.url.$el
      // 父级选择器
      let cas1 = this.$refs.cas1.$el
      let cas2 = this.$refs.cas2.$el
      // 新增名称
      let pName = document.getElementsByClassName('p_name')
      if (this.ruleForm.type === 0) {
        icon.style.display = 'block'
        iconTips[0].style.display = 'block'
        url.style.display = 'none'
        cas1.style.display = 'none'
        cas2.style.display = 'none'
        pName[0].innerHTML = '新增目录名称: '
        this.nameInp = '请输入目录名称'
      } else if (this.ruleForm.type === 1) {
        icon.style.display = 'none'
        iconTips[0].style.display = 'none'
        url.style.display = 'block'
        cas1.style.display = 'block'
        cas2.style.display = 'none'
        pName[0].innerHTML = '新增菜单名称: '
        this.nameInp = '请输入菜单名称'
      } else {
        icon.style.display = 'none'
        iconTips[0].style.display = 'none'
        url.style.display = 'none'
        cas1.style.display = 'none'
        cas2.style.display = 'block'
        pName[0].innerHTML = '新增按钮名称: '
        this.nameInp = '请输入按钮名称'
      }
    },
    // 打开新增菜单弹出框
    addMenu() {
      this.newMenuVisible = true
      this.ruleForm = {
        name: '',
        icon: '',
        url: '',
        type: 1,
        parentId: 0,
        orderNum: null
      }
      let addBtn = this.$refs.addBtn.$el
      let editBtn = this.$refs.editBtn.$el
      addBtn.style.display = 'inline-block'
      editBtn.style.display = 'none'
      this.radiosChange()
    },
    // 新增菜单
    async addMenuItem() {
      const { data: res } = await addBackMenuItemApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message({
        message: '新增菜单成功',
        type: 'success'
      })
      this.getAllMenu()
      this.newMenuVisible = false
      this.ruleForm = {
        name: '',
        icon: '',
        url: '',
        type: 1,
        parentId: 0,
        orderNum: null
      }
    },
    // 通过菜单id获取当前菜单信息并渲染到弹出框中
    async getMenuDetail() {
      if (this.radioValue === 0) return this.$message.error('请选择需要修改的菜单项')
      const { data: res } = await getBackMenuDetailApi(this.radioValue)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.ruleForm = res.data.menu
      this.newMenuVisible = true
      setTimeout(item => {
        let addBtn = this.$refs.addBtn.$el
        let editBtn = this.$refs.editBtn.$el
        addBtn.style.display = 'none'
        editBtn.style.display = 'inline-block'
        this.radiosChange()
      }, 0)
    },
    // 修改菜单
    async editMenuItem() {
      const { data: res } = await editBackMenuItemApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message({
        message: '修改菜单成功',
        type: 'success'
      })
      this.getAllMenu()
      this.newMenuVisible = false
      this.ruleForm = {
        name: '',
        icon: '',
        url: '',
        type: 2,
        parentId: 0,
        orderNum: null
      }
    },
    // 确认删除提示
    delTips() {
      if (this.radioValue === 0) return this.$message.error('请选择需要删除的菜单项')
      this.$confirm('确定要删除该菜单项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.delMenuItem()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    },
    // 删除菜单
    async delMenuItem() {
      const { data: res } = await delBackMenuItemApi(this.radioValue)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.radioValue = 0
      this.getAllMenu()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 1440px) {
    height: 100%;
  }
}
.el-card .el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  margin-top: -20px;
}
.el-table div {
  width: 59px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
}
// .el-table .cate {
//   background-color:#17706e;
// }
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.tipsInfo {
  margin: -5px 0 0 100px;
  padding-left: 20px;
  height: 30px;
  width: 280px;
  background-color: #F9F2F4;
  border-radius: 4px;
  line-height: 30px;
  color: #FF838C;
}
.el-form-item {
  margin-bottom: 12px;
}
.tipsInfo a {
  color: #C7254E;
}
</style>
