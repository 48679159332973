// 分类详情设置
<template>
    <div class="menuManage_contaner">
      <!-- 卡片视图区 -->
      <el-card>
        <el-row class="layout_row">
          <!-- 操作按钮 -->
          <el-button type="success" class="examine_btn" @click="addMenu()">新增</el-button>
          <el-button type="primary" class="examine_btn" @click="getMenuDetail()">修改</el-button>
          <el-button type="danger" class="examine_btn" @click="delTips()">删除</el-button>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="multipleTable" :data="allMenuData" border style="width: 100%" row-key="id" :tree-props="{children: 'childList'}" highlight-current-row  :default-sort="{ prop: 'sort'}">
              <el-table-column label="选择" width="60" header-align="center" align="right">
                <template slot-scope="scope">
                    <el-radio v-model="radioValue" :label="scope.row">&nbsp;</el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" sortable width="80px" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="cate" v-if="scope.row.parentId === 0">品 类</div>
                  <div class="list" v-else>品 名</div>
                </template>
              </el-table-column>
              <el-table-column label="展示图" width="110px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div class="imgBox">
                    <img :src="scope.row.pic">
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="品类" min-width="13%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.parentId === 0">{{scope.row.name}}</span>
                  <span v-else>{{scope.row.parentName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="品名" sortable min-width="13%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.parentId === 0">--</span>
                  <span v-else>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label="所属品类" sortable min-width="13%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.parentId === 0">--</span>
                  <span v-else>{{scope.row.parentName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="id" sortable min-width="11%" show-overflow-tooltip></el-table-column>
              <el-table-column label="序号" sortable min-width="12%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.parentId === 0">{{scope.row.id}}</span>
                  <span v-else>{{scope.row.parentId}}-{{scope.row.id}}</span>
                </template>
              </el-table-column>
              <el-table-column label="排序编号" sortable min-width="12%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.sort}}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" sortable min-width="12%" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.isOpen"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="rgba(27, 199, 181, 1)"
                    inactive-color="#eee"
                    @input="controlSwitch(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="12%" show-overflow-tooltip>
                <template>
                  <div>
                    <span style="color: rgba(250, 173, 20, 1)">详情</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
      </el-card>
      <!-- 菜单模板 -->
      <template id="newMenu">
        <div>
          <el-dialog title="菜单操作" :visible.sync="newMenuVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :default-sort="{prop: 'orderNum', order: 'ascending'}">
              <el-form-item prop="type" ref="classify">
                  <p>类型:</p>
                  <el-radio-group v-model="ruleForm.type" @change="radiosChange">
                    <el-radio :label="1">品类</el-radio>
                    <el-radio :label="2">品名</el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item prop="name">
                  <p class="p_name">品类: </p><el-input v-model="ruleForm.name" :placeholder="nameInp" type="text" clearable autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="parentId" ref="cas" style="display: none">
                  <p>所属品类: </p><el-cascader :options="allMenuData" :props="defaultData" clearable v-model="ruleForm.parentId" placeholder="请选择品类"></el-cascader>
              </el-form-item>
              <el-form-item>
                  <p>排序编号: </p><el-input v-model="ruleForm.sort" placeholder="请输入排序编号" type="text" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <p>图片: </p>
                <!-- http://adminapi.bmxgj.cn -->
                <!-- https://adminapi.z-srm.com -->
                <el-upload
                    class="avatar-uploader"
                    action="https://adminapi.z-srm.com/sys/common/upload-pic"
                    :show-file-list="false"
                    :data="{uploadType: 'BUSINESS_PRODUCT'}"
                    :headers="headers"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload2"
                    :on-remove="handleRemove">
                    <img v-if="ruleForm.pic" :src="ruleForm.pic" class="avatar">
                    <span v-if="ruleForm.pic" class="el-upload-action" @click.stop="handleRemove()">
                        <i class="el-icon-delete"></i>
                    </span>
                    <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
                </el-upload>
              </el-form-item>
            </el-form>
            <p slot="footer">
              <el-button @click="newMenuVisible = false">返 回</el-button>
              <el-button ref="editBtn" type="primary" @click="editMenuItem()" style="display: none">修 改</el-button>
              <el-button ref="addBtn" type="primary" @click="addMenuItem()">添 加</el-button>
            </p>
          </el-dialog>
        </div>
      </template>
    </div>
</template>
<script>
import { getAllClassifyApi, addClassifyApi, editClassifyApi, deleteClassifyApi } from '@/api'
export default {
  data() {
    return {
      // 所有菜单数据
      allMenuData: [],
      // 控制新增菜单的弹出框
      newMenuVisible: false,
      // 表单数据
      ruleForm: {
        pic: '',
        name: '',
        parentId: 0,
        sort: '',
        type: 1
      },
      // 登录框校验
      rules: {
      },
      headers: {
        'token': window.sessionStorage.getItem('token')
      },
      // ---------------------------------------------------
      // 单选按钮选中
      radioValue: '',
      defaultData: {
        value: 'id',
        label: 'name',
        children: null,
        // 控制级联选择器只选则单个值
        emitPath: false
      },
      nameInp: '请输入品类'
    }
  },
  created() {
    this.getAllMenu()
  },
  methods: {
    // 获取所有菜单
    async getAllMenu() {
      const { data: res } = await getAllClassifyApi()
      this.allMenuData = res.data.list
      console.log(this.ruleForm.type)
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      console.log(res.data, '123456')
      this.ruleForm.pic = res.data.url
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload2(file) {
      const type = file.type === 'image/jpeg' || 'image/jpg' || 'image/webp' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    },
    // 移除图片
    handleRemove() {
      this.ruleForm.pic = ''
    },
    // 单选按钮触发修改弹出框
    radiosChange() {
      // 父级选择器
      let cas = this.$refs.cas.$el
      // 新增名称
      let pName = document.getElementsByClassName('p_name')
      if (this.ruleForm.type === 1) {
        cas.style.display = 'none'
        pName[0].innerHTML = '品类: '
        this.nameInp = '请输入品类'
      } else {
        cas.style.display = 'block'
        pName[0].innerHTML = '品名: '
        this.nameInp = '请输入品名'
      }
    },
    // 打开新增菜单弹出框
    addMenu() {
      this.newMenuVisible = true
      this.ruleForm = {
        pic: '',
        name: '',
        parentId: 0,
        sort: '',
        type: 1
      }
      let addBtn = this.$refs.addBtn.$el
      let editBtn = this.$refs.editBtn.$el
      addBtn.style.display = 'inline-block'
      editBtn.style.display = 'none'
      this.radiosChange()
    },
    // 新增菜单
    async addMenuItem() {
      if (this.ruleForm.type !== 1 && this.ruleForm.parentId === 0) return this.$message.error('请先选择所属品类!')
      if (this.ruleForm.type === 1 && this.ruleForm.name === '') return this.$message.error('品类不能为空!')
      if (this.ruleForm.type !== 1 && this.ruleForm.name === '') return this.$message.error('品名不能为空!')
      if (this.ruleForm.sort === '') return this.$message.error('排序编号不能为空!')
      const { data: res } = await addClassifyApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (this.ruleForm.type === 1) {
        this.$message({
          message: '新增品类成功',
          type: 'success'
        })
      } else {
        this.$message({
          message: '新增品名成功',
          type: 'success'
        })
      }
      this.getAllMenu()
      this.newMenuVisible = false
      this.ruleForm = {
        pic: '',
        name: '',
        parentId: 0,
        sort: '',
        type: 1
      }
    },
    // ---------------------------------------------------------------
    // 1.修改时将列表信息渲染到弹出框中
    async getMenuDetail() {
      if (this.radioValue === '') return this.$message.error('请选择需要修改的菜单项')
      this.ruleForm = {
        id: this.radioValue.id,
        pic: this.radioValue.pic,
        name: this.radioValue.name,
        parentId: this.radioValue.parentId,
        sort: this.radioValue.sort,
        type: this.radioValue.parentId === 0 ? 1 : 2
      }
      this.newMenuVisible = true
      setTimeout(item => {
        let addBtn = this.$refs.addBtn.$el
        let editBtn = this.$refs.editBtn.$el
        let classify = this.$refs.classify.$el
        addBtn.style.display = 'none'
        editBtn.style.display = 'inline-block'
        classify.style.display = 'none'
        this.radiosChange()
      }, 0)
    },
    // 修改菜单
    async editMenuItem() {
      const { data: res } = await editClassifyApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message({
        message: '修改成功',
        type: 'success'
      })
      this.getAllMenu()
      this.newMenuVisible = false
      this.ruleForm = {
        pic: '',
        name: '',
        parentId: 0,
        sort: '',
        type: 1
      }
    },
    // 确认删除提示
    delTips() {
      if (this.radioValue === '') return this.$message.error('请选择需要删除的菜单项')
      this.$confirm('确定要删除该菜单项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delMenuItem()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    },
    // 删除菜单
    async delMenuItem() {
      const { data: res } = await deleteClassifyApi(this.radioValue.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.radioValue = ''
      this.getAllMenu()
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    },
    // 点击状态修改时触发
    async controlSwitch(result) {
      this.ruleForm = result
      this.ruleForm.isOpen === 0 ? this.ruleForm.isOpen = 1 : this.ruleForm.isOpen = 0
      const { data: res } = await editClassifyApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (result.status === 0) {
        this.$message.success('禁用成功!')
      } else {
        this.$message.success('启用成功!')
      }
      this.getHelpMenu()
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 1440px) {
    height: 100%;
  }
}
.el-card .el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  position: relative;
  margin-top: -20px;
}
.el-table div {
  width: 59px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
}
.el-table .cate {
  background-color:#17706e;
}
.el-table .list {
  background-color:#e2979c;
}
.el-table .menu {
  background-color:#e7305b;
}
.el-table .btn {
  background-color:#b6eb7a;
}
.el-form-item {
  margin-bottom: 12px;
}
/deep/ .el-table td, .el-table th {
    padding: 5px 0 0!important;
  }
.avatar-uploader{
  width: 180px;
  height: 120px;
  border-radius: 2%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultPicture.png') no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 180px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 180px;
  height: 120px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 180px;
  height: 120px;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 120px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, .3)
}
.imgBox {
  img {
    width: 90px;
    height: 60px;
  }
}
</style>
