// 企业会员管理
<template>
    <div class="corporateMember">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row>
            <h1 style="font-weight: bold;line-height: 45px;font-size: 20px;">企业会员管理</h1>
        </el-row>
        <el-row class="layout_row">
          <div class="searchBox">
            <p>
              <span>企业名称 : </span>
              <el-input  clearable v-model="orderData.name" placeholder="请输入" type="text" size="small" maxlength="40" @input="getOrderList()"></el-input>
            </p>
            <p>
              <span>会员 : </span>
              <el-select style="width: 160px;" v-model="orderData.levelId" placeholder="请选择" size="mini" clearable  @change="memeberFn">
                <el-option v-for="(item, index) in uniqueCompaniesData" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </p>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="companyName" label="企业名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <!-- <el-table-column prop="contractNum" label="合同编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column> -->
              <el-table-column prop="industryName" label="行业名称" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <!-- <el-table-column prop="fileName" label="合同名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column> -->
              <el-table-column prop="contact" label="联系人" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="tel" label="联系电话" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="levelName" label="会员" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="expDay" label="有效期" min-width="60px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.expDay>0" style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini">还剩{{ scope.row.expDay }}天</span>
                    <span v-else>已过期</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="expTime" label="到期时间" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="operateLevelTime" label="最近开通会员时间" min-width="60px" show-overflow-tooltip align="center">
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.expDay<=0 || scope.row.levelName==='体验会员'" style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click="MemberFn(scope.row,1)">开通会员</span>
                    <span v-else style="cursor: pointer; color: rgba(51, 158, 191, 1); margin-right: 20px;" size="mini" @click="MemberFn(scope.row,2)">续费会员</span>
                    <span v-if="scope.row.expDay>0 && scope.row.levelName !='体验会员'" style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click="MemberFn(scope.row,3)">变更会员</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
      <!-- 开通会员弹出框 -->
      <el-dialog :title="titles" :visible.sync="dialogFormVisible">
        <div class="listBox">
          <div class="memberBox" v-for="(item, index) in list" :key="index">
            <el-radio v-model="addMember.levelId" :label="item.id">{{item.name}}</el-radio>
            <p>会员价: {{item.price}}元/{{ item.expDay }}天</p>
            <!-- <p v-if="titles == '续费会员'">续费价格: {{item.price}}元/年</p> -->
            <p v-if="titles == '变更会员'">折扣价: {{item.price * item.rebate * 0.01}}元/年</p>
          </div>
        </div>
        <div class="numberBox">
          <span>开通次数 : </span>
          <el-input-number v-model="counts"  :min="1"></el-input-number>
        </div>
        <p v-if="titles == '变更会员' || titles == '续费会员'">当前会员: {{addMember.levelName}}, 有效期{{addMember.expDay}}天</p>
        <p v-if="titles == '变更会员'">升级会员可抵扣费用{{((nowItem.price/nowItem.expDay) * addMember.expDay).toFixed(2)}}元</p>
        <div slot="footer" class="dialog-footer">
            <el-button @click="concelFn">取 消</el-button>
            <el-button type="primary" @click="confirmFn()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>
<script>
// openMenberApi
import { getCorporateListApi, vipListApi, openMenberApi } from '@/api'
export default {
  data() {
    return {
      days: null,
      counts: 1,
      // 开通或续费参数
      addMember: {
        count: 1,
        companyId: '',
        levelId: '',
        levelName: '',
        type: ''
      },
      uniqueCompaniesData: [],
      dialogFormVisible: false,
      titles: '开通会员',
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderData: {
        name: '',
        levelId: '',
        page: 1,
        size: 10
      },
      form: {
        count: '',
        companyId: '',
        levelId: '',
        expDay: ''
      },
      list: [],
      selectData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      orderList: [],
      // 选中的项目
      chooseItem: '',
      // 当前会员信息
      nowItem: ''
    }
  },
  created() {
    this.getOrderList()
    this.selectDataList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单列表
    async getOrderList() {
      const { data: res } = await getCorporateListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 获取搜索会员下拉框数据
    async selectDataList() {
      const { data: res } = await vipListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.uniqueCompaniesData = res.data.list
    },
    // 会员下拉搜索
    async memeberFn() {
      const { data: res } = await getCorporateListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      console.log(val)
      this.orderData.page = val
      this.currentPage = val
      this.getOrderList()
    },
    // 重置搜索条件
    rejectSearch() {
      this.orderData = {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      }
      this.getOrderList()
    },
    async MemberFn(data, type) {
      this.counts = 1
      this.list = []
      if (type === 1) {
        // 开通会员操作
        this.titles = '开通会员'
        this.addMember.type = 1
        this.addMember.companyId = data.companyId
        const { data: res } = await vipListApi()
        if (res.code === 0) {
          res.data.list.forEach((item, index) => {
            if (item.isOpen !== 0) {
              this.list.push(item)
            }
          })
        } else {
          this.$message({
            type: 'error', // success error warning
            message: '列表获取失败',
            duration: 200
          })
        }
        this.dialogFormVisible = true
      } else if (type === 2) {
        console.log(data, '嘎嘎嘎')
        // 续费会员操作
        this.titles = '续费会员'
        this.addMember.type = 2
        this.addMember.companyId = data.companyId
        this.addMember.levelId = data.userLevel
        this.addMember.levelName = data.levelName
        this.addMember.expDay = data.expDay
        const { data: res } = await vipListApi()
        if (res.code === 0) {
          res.data.list.forEach((item, index) => {
            if (item.isOpen !== 0 && item.name === data.levelName) {
              this.list.push(item)
              this.nowItem = item
            }
          })
        } else {
          this.$message({
            type: 'error', // success error warning
            message: '列表获取失败',
            duration: 200
          })
        }
        this.dialogFormVisible = true
      } else {
        // 变更会员操作
        this.titles = '变更会员'
        this.addMember.type = 1
        this.addMember.companyId = data.companyId
        this.addMember.levelId = data.userLevel
        this.addMember.levelName = data.levelName
        this.addMember.expDay = data.expDay
        const { data: res } = await vipListApi()
        if (res.code === 0) {
          res.data.list.forEach((item, index) => {
            if (item.isOpen !== 0 && item.name !== data.levelName) {
              this.list.push(item)
            }
            if (item.name === data.levelName) {
              this.nowItem = item
            }
          })
        } else {
          this.$message({
            type: 'error', // success error warning
            message: '列表获取失败',
            duration: 200
          })
        }
        this.dialogFormVisible = true
      }
    },
    // 确定
    async confirmFn() {
      this.list.forEach(item => {
        if (item.id === this.addMember.levelId) {
          this.days = item.expDay
        }
      })
      this.addMember.count = this.counts * this.days
      const { data: res } = await openMenberApi(this.addMember)
      if (res.code !== 0) return res.$message.error(res.msg)
      this.dialogFormVisible = false
      this.counts = 1
      this.getOrderList()
    },
    // 取消
    concelFn() {
      this.addMember = {
        count: 1,
        companyId: '',
        levelId: ''
      }
      this.dialogFormVisible = false
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.memberBox{
  margin-bottom: -1px;
    display: flex;
    border: 1px solid #ccc;
    align-items: center;
    // justify-content: space-around;
    /deep/ .el-radio {
      margin-left: 20px;
      width: 90px;
      .el-radio__label {
        font-size: 18px;
      }
    }
    >p:nth-child(2) {
      width: 180px;
    }
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
</style>
