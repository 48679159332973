//  客户审核
<template>
    <div class="registReview_content">
      <!-- 卡片视图区 -->
      <el-card v-show="showFirst">
        <!-- 内容搜索区 -->
        <el-row :gutter="20">
          <el-col :span="2">
            <p>供应商名称:</p>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="请输入供应商名称" clearable v-model="tableValue.name" @input="getQuoteList()" @keyup.enter.native="getQuoteList()">
            </el-input>
          </el-col>
          <!-- <el-col :span="2">
            <el-button type="info" @click="getQuoteList()">查询</el-button>
          </el-col> -->
          <el-col :span="2">
            <p>数据来源:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.source" placeholder="请选择数据来源" clearable @change="getQuoteList()">
              <el-option label="公海" :value="0"></el-option>
              <el-option label="地推" :value="1"></el-option>
              <el-option label="小程序" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <p>审核状态:</p>
          </el-col>
          <el-col :span="4">
            <!-- audit 0审核 1通过 2失败 -->
            <el-select v-model="tableValue.approveStatus" placeholder="请选择审核状态" clearable @change="getQuoteList()">
              <el-option label="未审批" :value="0"></el-option>
              <el-option label="通过" :value="1"></el-option>
              <el-option label="拒绝" :value="2"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <!-- <template slot-scope="scope"> -->
            <el-button size="mini" icon='el-icon-sort' @click="showDialog">同步</el-button>
          <!-- </template> -->
        </el-row>
        <!-- 物料定义表格区 -->
        <el-row>
          <el-table :height="screenHeight" tooltip-effect="dark" ref="multipleTable" :data="tableData" border style="width: 100%" show-overflow-tooltip :default-sort = "{prop: 'date', order: 'descending'}"  @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55" algin="center">
              <!-- <template slot-scope="{row}">
                <el-checkbox  @change="handleSelectionChange(row)"></el-checkbox>
              </template> -->
            </el-table-column>
            <el-table-column  prop="name" label="供应商名称" min-width="120px" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <td @click="showSecondBox(scope.row.id)">{{ scope.row.name }}</td>
              </template>
            </el-table-column>
            <el-table-column prop="industryName" label="客户行业"  min-width="150px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="synchronization" label="同步到"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="sourceName" label="数据来源"  min-width="80px"  show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="approveStatusName" label="审核状态"  min-width="120px"  show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="操作" width="150px" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button size="mini" icon='el-icon-sort'  v-if="scope.row.approveStatusName === '审批通过'" @click="showDialog(scope.row.uuid)">同步</el-button>
                <div v-else-if="scope.row.approveStatusName === '待审核'">
                  <el-button class="passBtn" size="mini" @click="passConfirm(scope.row.id, 1)"><a>通过</a></el-button>
                  <el-button type="danger" size="mini" @click="passConfirm(scope.row.id, 2)"><a>不通过</a></el-button>
                </div>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="tableValue.size"
            :total="total">
          </el-pagination>
        </el-row>
      </el-card>
    <!-- 同步弹框 -->
    <el-dialog :visible.sync="dialogVisible" title="同步数据" :show-close="false">
      <template v-slot:default>
        <el-checkbox v-model="checkedList" label="1">地推</el-checkbox>
        <el-checkbox v-model="checkedList" label="2">小程序</el-checkbox>
        <div>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
      </template>
    </el-dialog>
       <!-- 商品信息--详情页 -->
       <el-card v-if="showSecond" class="detailsBox">
        <el-button @click="hideSecondBox">返回</el-button>
        <el-row>
            <p>商品信息</p>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>客户名称：<span style="font-weight: normal;">{{ detailData.name }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>客户行业：<span style="font-weight: normal;">{{detailData.industryName}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>营业额（万元）<span style="font-weight: normal;">{{ detailData.turnover }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>企业员工数：<span style="font-weight: normal;">{{detailData.staffNumber}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>企业经营状况：<span style="font-weight: normal;">{{ detailData.manageName }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>主营产品：<span style="font-weight: normal;">{{ detailData.product }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <p>客户类型：<span style="font-weight: normal;">{{ detailData.typeName }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <p>企业信息</p>
        </el-row>
        <el-row type="flex">
            <el-col :span="24">
                <span>营业执照：</span>
                <div class="grid-content bg-purple-dark">
                  <img :src="detailData.businessLicense" alt="" style="height: 140px; width: 140px;">
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>法人代表：<span style="font-weight: normal;">{{ detailData.legalPerson }}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <p>统一信用代码：<span style="font-weight: normal;">{{detailData.code}}</span></p>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <p>省市区：<span style="font-weight: normal;">{{detailData.cityName}}{{ detailData.areaName }}</span></p>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="8">
                <div class="grid-content bg-purple" style="text-align: left;">
                    <p>详细地址：<span style="font-weight: normal;">{{ detailData.address }}</span></p>
                </div>
            </el-col>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { clientListApi, clientListDetailApi, synchronousData, auditData, syncGroundData } from '@/api'
export default {
  name: 'App',
  data() {
    return {
    // 同步弹框数据
      dialogVisible: false,
      checkedList: [],
      uuidList: [],
      // 详情页动态展示
      showFirst: true,
      showSecond: false,
      // 预览的图片
      srcList: [],
      tableValue: {
        page: 1,
        size: 4,
        name: '',
        source: '',
        approveStatus: ''
        // audit: '',
        // keyword: '',
        // moduleType: ''
      },
      // 获取表格数据
      tableData: [],
      // 获取详情页数据
      detailData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 获取当前浏览器的高度
      screenHeight: '',
      modelTypeTitle: ''
    }
  },
  created() {
  },
  mounted() {
    this.screenHeight = document.body.clientHeight - 400
    if (this.screenHeight >= 1200) {
      this.tableValue.size = 8
    } else if (this.screenHeight >= 500) {
      this.tableValue.size = 6
    } else {
      this.tableValue.size = 4
    }
    this.getQuoteList()
  },
  methods: {
    // 审核功能
    async passConfirm(id, index) {
      const { data: res } = await auditData({ id: id, approveStatus: index })
      // console.log(res, '1212112121212')
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getQuoteList()
    },
    // 获取客户审核列表
    async getQuoteList() {
      const { data: res } = await clientListApi(this.tableValue)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 获取表单数据
      this.tableData = res.data.page.list

      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
      // 确认发布信息标题
      this.tableData.forEach((item, index) => {
        if ((item.frontType === 1 || item.frontType === 2) && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 3 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3 + item.item4
        } else if (item.frontType === 4 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item4
        } else if (item.frontType === 5 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item1 + item.item2
        } else if (item.frontType === 6 && (item.moduleType === 1 || item.moduleType === 3)) {
          item.frontName = item.labelIdsName + item.item2 + item.item3
        } else if (item.moduleType === 2) {
          item.frontName = item.labelIdsName
        } else if (item.moduleType === 4) {
          item.frontName = item.item1
        }
      })
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.tableValue.page = val
      this.currentPage = val
      this.getQuoteList()
    },
    // 同步数据功能
    showDialog(uuids) {
      this.dialogVisible = true
      this.uuidList.push(uuids)
    },
    async confirm() {
      if (this.checkedList.length === 0) {
        this.$message({
          type: 'warning', // success error warning
          message: '请选择同步位置',
          duration: 2000
        })
      } else {
        if (this.uuidList.length !== 0) {
          try {
            const [{ data: response1 }, { data: response2 }] = await Promise.all([synchronousData({ uuids: this.uuidList.join(','), types: this.checkedList.join(',') }), syncGroundData({ uuids: this.uuidList.join(','), types: this.checkedList.join(',') })])
            if (response1.code !== 0) return this.$message.error(response1.msg)
            if (response2.code !== 0) return this.$message.error(response2.msg)
            // this.uuidList = []
            this.$message.success('同步成功!')
          // 处理两个接口的响应数据
          } catch (error) {
            // 处理错误
          }
          this.checkedList = []
        } else {
          this.$message({
            type: 'error', // success error warning
            message: '请选择需要同步的数据',
            duration: 2000
          })
        }
      }
      this.dialogVisible = false
      this.getQuoteList()
    },
    handleSelectionChange(row) {
      row.forEach((item) => {
        this.uuidList.push(item.uuid)
      })
    },
    // 详情页显示隐藏
    async showSecondBox(id) {
      const { data: res } = await clientListDetailApi({ id: id })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailData = res.data.data
      this.showFirst = false
      this.showSecond = true
    },
    hideSecondBox() {
      this.showFirst = true
      this.showSecond = false
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
  @media screen and (min-width: 1600px){
    height: 700px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    height: 620px;
  }
  @media screen and (max-width: 1440px) {
    height: 500px;
  }
}
.el-card p {
  margin-right: -20px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.el-card .el-col>.el-button {
  width:70px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .el-button--info {
  background-color: rgba(0,0,0,.8);
}
.el-card .el-button--info:hover {
  background-color: rgba(0,0,0,.7);
}
.el-card .el-button--info:active {
  background-color: rgba(0,0,0,.9);
}
.el-card /deep/ .el-input__inner:focus {
  border: 1px solid #000;
}
.el-card .examine_btn {
  margin: 22px 10px 0 0;
  padding: 12px 0;
  width: 80px;
  height: 34px;
  background: rgba(255,255,255,1);
  border-radius: 3px;
  color: #666;
  line-height: 0
}
.el-card .add_btn {
  float: right;
}
.el-card .cancelExamine_btn {
  padding:12px 0;
  width:80px;
  height:34px;
  background:rgba(255,255,255,1);
  border-radius:3px;
  color: #666;
  line-height: 0;
}
.el-card .el-menu--horizontal {
    height: 60px;
    border-bottom: 1px solid #ccc!important;
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: 30px;
  right: 0;
}
.el-table .cell div {
  display: flex;
}
.el-table .cell p {
  margin: 8px 10px;
  width: 8px;
  height: 8px;
  background-color: #B7B7B7;
  border-radius: 50%;
  box-shadow: 1px 1px 1px black,-1px -1px 1px white;
}
.el-table .cell .el-button {
  border: 0;
}
.el-table .cell .passBtn {
  background-color: #009688;
}
.el-table .cell .passBtn:hover {
  background-color: rgba(0,150,136,.7)
}
.el-table .cell .passBtn:active {
  background-color: rgba(0,150,136,.9)
}
.el-table .cell a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.el-table .cell img {
  margin: 0 auto;
  display: block;
  width: 33px;
  height: 33px;
}
.el-menu-demo {
  margin: -20px 0 20px 0;
  font-weight: 400;
}
// 弹出查看窗口样式
.el-dialog__body li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.el-dialog__body li .company_name {
  font-size: 20px;
  color: #01519B;
}
.el-dialog__body p {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.el-dialog__body .float_l {
  display: inline-block;
  width: 265px;
}
.el-dialog__body .float_r {
  display: inline-block;
  width: 265px;
}
// 详情页样式
.detailsBox p{
    text-align: left;
}
// 首页操作按钮样式
.el-button--mini,el-button--mini.is-round {
    padding: 7px 4px;
}
</style>
