// 模板管理 模板绑定分类
<template>
  <div class='uploadBaseTemplate'>
    <!-- 卡片视图区 -->
    <el-card :style="'height:' + tableHeight2 + 'px'">
      <h5>基础信息</h5>
      <main>
        <p>
          <span>合同模板名称 : </span>
          <el-input v-model="addTemplateData.fileName" placeholder="请输入合同模板名称" type="text" size="small"></el-input>
        </p>
        <p>
          <span>合同类别 : </span>
          <el-select style="width: 230px;" v-model="addTemplateData.type" placeholder="请选择推送方式" size="mini" clearable>
            <el-option label="购销合同" value="0"></el-option>
            <el-option label="委托合同" value="1"></el-option>
            <el-option label="其他合同" value="2"></el-option>
          </el-select>
        </p>
        <p>
          <span>模板用途 : </span>
          <el-input style="width: calc(100% - 200px)" v-model="addTemplateData.purpose" placeholder="请输入模板用途(可不填)" type="textarea" size="small"></el-input>
        </p>
      </main>
      <h5>合同附件</h5>
      <main>
        <p>
          <span>合同模板 : </span>
          <el-select style="width: 230px;" v-model="addTemplateData.sysEsignTemplateOriginalId" placeholder="请选择推送方式" size="mini" clearable @change="getFileMd5AndUrl()">
            <el-option v-for="(item, index) in baseTemplateList" :key="index" :label="item.fileName" :value="item.id"></el-option>
          </el-select>
        </p>
      </main>
      <footer>
        <button @click="getControlData()">新建模板</button>
      </footer>
    </el-card>
  </div>
</template>
<script>
import { baseTemplateListApi, delBaseTemplateApi, getFileMd5AndUrlApi, getControlDataApi, keepTemplateApi, addWidgetApi, createPDFApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      // 新增模板参数
      addTemplateData: {
        fileId: '',
        fileName: '',
        downloadUrl: '',
        enabled: true,
        type: '',
        purpose: '',
        fileSize: '',
        sysEsignTemplateOriginalId: ''
      },
      // 基础模板列表
      baseTemplateList: []
    }
  },
  created() {
    this.getBaseTemplateList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取基础模板列表
    async getBaseTemplateList() {
      const { data: res } = await baseTemplateListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.baseTemplateList = res.data.data
    },
    // 删除基础模板
    async delBaseTemplate(id) {
      const { data: res } = await delBaseTemplateApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.getBaseTemplateList()
    },
    // 1.获取文件上传的md5和url并上传文件
    async getFileMd5AndUrl() {
      const { data: res } = await getFileMd5AndUrlApi(this.addTemplateData.sysEsignTemplateOriginalId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addTemplateData.fileId = res.data.data.fileId
      this.baseTemplateList.forEach((item, index) => {
        if (item.id === this.addTemplateData.sysEsignTemplateOriginalId) {
          this.addTemplateData.fileSize = item.fileSize
          return false
        }
      })
    },
    // 2.获取控件参数
    async getControlData() {
      const { data: res } = await getControlDataApi(this.addTemplateData.sysEsignTemplateOriginalId)
      if (res.code !== 0) return this.$message.error(res.msg)
      let fileContent = res.data.data[0]
      this.addWidget(fileContent)
    },
    // 3.添加填写控件
    async addWidget(value) {
      const { data: res } = await addWidgetApi({ ...value, templateId: this.addTemplateData.fileId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.createPDF(value, res.data.data.data[0])
    },
    // 4.添加内容生成PDF
    async createPDF(value, widgets) {
      const { data: res } = await createPDFApi({ templateId: this.addTemplateData.fileId, widgets: widgets, widgetsMsg: value.label })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addTemplateData.downloadUrl = res.data.data.data.downloadUrl
      this.addTemplateData.fileId = res.data.data.data.fileId
      this.keepTemplate()
    },
    // 5.保存模板
    async keepTemplate() {
      const { data: res } = await keepTemplateApi(this.addTemplateData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新建模板成功!')
    }
  }
}
</script>
<style scoped lang='less'>
.uploadBaseTemplate {
  width: 100%;
  .el-card {
    padding: 26px;
    background:rgba(255,255,255,1);
    border-radius:3px;
    overflow: auto;
    h5 {
      font-size: 16px;
      font-weight: 500;
    }
    main {
      p {
        margin-bottom: 20px;
        font-size: 14px;
        color: #000000;
        span {
          margin-right: 5px;
          display: inline-block;
          width: 120px;
          height: 32px;
          line-height: 32px;
          text-align: right;
          vertical-align: top;
        }
        .el-input {
          width: 230px;
        }
      }
    }
    footer {
      width: 100%;
      height: 32px;
      button {
        margin-left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 32px;
        line-height: 32px;
        background-color: rgba(51, 158, 191, 1);
        border: 0;
        border-radius: 4px;
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
}
</style>
