<template>
  <div class='frameWork'>
    <div class="frameWorkLeft">
      <div class="leftHeader">角色权限管理<i @click="addFrame()">新增权限</i></div>
      <el-tree :data="roleList" :props="props"  @node-click="handleNodeClick">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          {{ data.roleName }}
          <i @click.stop="editRole(data)" style="font-size: 16px; margin-left: 50px;" class="iconfont icon-set"></i>
          <i @click.stop="delRole(data.roleId)" style="font-size: 16px; margin-left: 5px; color: red;" class="el-icon-delete"></i>
        </span>
      </el-tree>
    </div>
    <div class="frameWorkRight">
      <el-button size="mini" @click="selectAll()">全选</el-button>
      <el-button size="mini" @click="selectNotAll()">全不选</el-button>
      <el-button size="mini" @click="editRoleInfo()">保存</el-button>
      <ul>
        <!-- 第二层 -->
        <li v-for="item in allMenuData" :key="item.menuId">
          <p>{{item.name}}</p>
          <!-- 第三层 -->
          <div v-for="subItem in item.children" :key="subItem.menuId">
            <span>{{subItem.name}}</span>
            <div v-if="subItem.orderNum === true" class="switchBox2" @click="changeStatus(subItem)"><div class="circle"></div></div>
            <div v-else class="switchBox1" @click="changeStatus(subItem)"><div class="circle"></div></div>
          </div>
        </li>
      </ul>
    </div>
    <template>
      <el-dialog :title="controlAdd === true ? '新增权限名称' : '修改权限名称' " :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog">
        <el-form :model="addRole" :rules="rules" ref="ruleForm" :default-sort="{prop: 'orderNum', order: 'ascending'}">
          <el-form-item prop="roleName">
            <p class="p_name">权限名称: </p><el-input v-model="addRole.roleName" placeholder="请输入" type="text" clearable autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="controlAdd === true" class="keepBtn" @click="changeRole()">新 建</el-button>
          <el-button v-else class="keepBtn" @click="changeRole()">修 改</el-button>
          <el-button class="defaultStyle" @click="createDialog = false">取 消</el-button>
        </div>
      </el-dialog>
      </template>
  </div>
</template>
<script>
import { roleListApi, addRoleApi, editRoleApi, deleteRoleApi, getAllBackMenuApi, roleDetailApi } from '@/api'
export default {
  data() {
    return {
      allMenuData: [],
      createDialog: false,
      controlAdd: true,
      addRole: {
        roleId: '',
        roleName: '',
        remark: '',
        menuIdList: []
      },
      rules: {
        roleName: [
          { required: true, message: '请输入权限名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      roleList: [],
      props: {
        children: 'bakDeptEntityList',
        label: 'name'
      }
    }
  },
  created() {
    this.getRoleList()
    this.getAllMenu()
  },
  mounted() {
  },
  methods: {
    // 保存角色权限
    async editRoleInfo() {
      // 将第一层id全部存起来
      this.allMenuData.forEach(item => {
        this.addRole.menuIdList.push(item.menuId)
      })
      this.addRole.menuIdList = [...new Set(this.addRole.menuIdList)]
      const { data: res } = await editRoleApi(this.addRole)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('保存成功!')
    },
    // 对初始开启按钮权限操作
    changeStatus(result) {
      if (result.orderNum === true) {
        this.addRole.menuIdList = this.addRole.menuIdList.filter(item => { return item !== result.menuId })
      } else {
        this.addRole.menuIdList.push(result.menuId)
      }
      result.orderNum = !result.orderNum
    },
    // 点击角色触发
    async handleNodeClick(data) {
      // 获取当前菜单
      this.addRole = data
      // 根据角色id获取权限列表
      const { data: res } = await roleDetailApi(data.roleId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addRole.menuIdList = res.data.role.menuIdList
      if (data.menuIdList === null) {
        this.addRole.menuIdList = []
      }
      this.changeRoleName()
    },
    // 获取所有菜单
    async getAllMenu() {
      const { data: res } = await getAllBackMenuApi()
      let data1 = res.data.menuList
      // 左侧菜单数据修改为树形结构
      function setTreeData(arr) {
        let map = {}
        arr.forEach(i => {
          map[i.menuId] = i
        })
        let treeData = []
        arr.forEach(child => {
          const mapItem = map[child.parentId]
          if (mapItem) {
            (mapItem.children || (mapItem.children = [])).push(child)
          } else {
            treeData.push(child)
          }
        })
        return treeData
      }
      this.allMenuData = setTreeData(data1)
      this.changeRoleName()
    },
    // 根据权限菜单改变roleName的状态
    changeRoleName() {
      this.allMenuData.forEach(item => {
        // 第二层菜单层 start
        if (item.children !== undefined) {
          item.children.forEach(items => {
            items.orderNum = false
            if (this.addRole.menuIdList !== []) {
              this.addRole.menuIdList.forEach(res => {
                if (res === items.menuId) {
                  items.orderNum = true
                }
              })
            }
          })
        }
        // 第二层菜单层 end
      })
    },
    // 全选
    selectAll() {
      let allMenu = []
      this.allMenuData.forEach(item => {
        allMenu.push(item.menuId)
        if (item.children !== undefined) {
          item.children.forEach(subItem => {
            allMenu.push(subItem.menuId)
          })
        }
      })
      this.addRole.menuIdList = allMenu
      setTimeout(() => {
        this.changeRoleName()
      }, 100)
    },
    // 全不选
    selectNotAll() {
      this.addRole.menuIdList = []
      this.changeRoleName()
    },
    // 删除角色
    delRole(id) {
      this.$confirm('是否确认删除当前角色权限?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { data: res } = await deleteRoleApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getRoleList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 新增角色
    addFrame() {
      this.createDialog = true
      this.addRole = {
        roleId: '',
        roleName: '',
        remark: '',
        menuIdList: []
      }
      this.controlAdd = true
    },
    // 修改角色
    editRole(value) {
      this.createDialog = true
      this.controlAdd = false
      this.addRole = {
        roleId: value.roleId,
        roleName: value.roleName,
        remark: value.remark,
        menuIdList: value.menuIdList
      }
    },
    // 获取角色列表
    async getRoleList() {
      const { data: res } = await roleListApi({ page: 1, size: 9999, search: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.roleList = res.data.page.list
    },
    // 新增修改角色
    async changeRole() {
      if (this.addRole.menuIdList === null) {
        this.addRole.menuIdList = []
      }
      if (this.controlAdd === true) {
        const { data: res } = await addRoleApi(this.addRole)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('新增成功!')
      } else {
        const { data: res } = await editRoleApi(this.addRole)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('修改成功!')
      }
      this.getRoleList()
      this.createDialog = false
      this.addRole = {
        roleId: '',
        roleName: '',
        remark: '',
        menuIdList: []
      }
    }
  }
}
</script>
<style scoped lang='less'>
.frameWork {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .frameWorkLeft {
    padding: 0 10px;
    width: 25%;
    height: calc(100vh - 210px);
    background:rgba(255,255,255,1);
    border-radius:3px;
    box-sizing: border-box;
    overflow: auto;
    .leftHeader {
      padding: 0 20px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;
      i {
        margin-left: 140px;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
  .frameWorkRight {
    position: relative;
    padding: 35px 20px 0;
    width: 74%;
    height: calc(100vh - 210px);
    background:rgba(255,255,255,1);
    border-radius:3px;
    box-sizing: border-box;
    overflow: auto;
    .el-button {
      background-color: rgba(254,179,0,1);
      border: 0;
      color: #fff;
      cursor: pointer;
      z-index: 1;
    }
    .el-button:hover {
      background-color: rgba(254,179,0,.7);
    }
    .el-button:nth-child(1) {
      position: absolute;
      top: 12px;
      right: 172px;
    }
    .el-button:nth-child(2) {
      position: absolute;
      top: 12px;
      right: 100px;
      background-color: rgb(204, 199, 135);
    }
    .el-button:nth-child(2):hover {
      background-color: rgba(204,199,135,.7);
    }
    .el-button:nth-child(3) {
      position: absolute;
      top: 12px;
      right: 40px;
      background-color: rgb(245,108,108);
    }
    .el-button:nth-child(3):hover {
      background-color: rgba(245,108,108,.7);
    }
    li {
      margin: 15px 30px;
      border: 1px solid #ccc;
      background-color: #F5F5F5;
      p {
        margin: 10px 0 0 10px;
      }
      >div {
        margin: 5px 20px;
        font-size: 14px;
        font-weight: 400;
        span {
          display: inline-block;
          min-width: 200px;
          .el-checkbox-group {
            display: inline-block;
            margin-left: 20px;
          }
        }
        .switchBox1, .switchBox2 {
          margin-left: 10px;
          display: inline-block;
          width: 50px;
          height: 20px;
          border-radius: 20px;
          vertical-align: middle;
          background-color: #dcdfe6;
          cursor: pointer;
          .circle {
            margin: 2px;
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-radius: 50%;
            transition: all .3s;
          }
        }
        .switchBox2 {
          background-color: rgb(27, 199, 181);
          .circle {
            margin: 2px 2px 2px 30px;
          }
        }
      }
    }
  }
}
/deep/ .createDialog .el-dialog {
  margin-top: 180px!important;
  width: 472px;
  height: 184px;
  line-height: 16px;
  .el-dialog__header {
    background-color: #fff;
    span{
      color: #000;
    }
    .el-icon-close:before {
      color: #000;
    }
  }
  .el-dialog__body {
    position: relative;
    padding: 0 20px;
    height: 70px;
    width: 972px;
    .el-select {
      height: 40px;
      .el-input__inner {
        height: 40px;
      }
    }
  }
  .dialog-footer {
    text-align: center;
    .keepBtn {
      background-color: rgba(0, 199, 199, 1);
      border: 1px solid rgba(0, 199, 199, 1);
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0, 199, 199, .5);
      border: 1px solid rgba(102,221,221, .5);
    }
  }
}
</style>
