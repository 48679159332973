// 订单管理 订单列表
<template>
    <div class="orderList">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <p>
            <span>订单编号/客户名称 : </span>
            <el-input v-model="orderData.search" placeholder="请输入合同名称" type="text" size="small" maxlength="40"></el-input>
            <span>订单状态 : </span>
            <el-select style="width: 160px;" v-model="orderData.status" placeholder="请选择" size="mini" clearable>
              <el-option label="待审核" value="0"></el-option>
              <el-option label="审核通过" value="1"></el-option>
              <el-option label="作废" value="2"></el-option>
              <el-option label="7天内未付保证金失效" value="3"></el-option>
              <el-option label="全部付款成功正常结束" value="4"></el-option>
            </el-select>
            <span>付款状态 : </span>
            <el-select style="width: 160px;" v-model="orderData.payStatus" placeholder="请选择" size="mini" clearable>
              <el-option label="未付款" value="0"></el-option>
              <el-option label="部分付款" value="1"></el-option>
              <el-option label="已付款" value="2"></el-option>
            </el-select>
            <span>订单日期 : </span>
            <el-date-picker
              v-model="orderData.startTime"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span style="width: 30px; margin-right: 0">至</span>
            <el-date-picker
              v-model="orderData.endTime"
              type="date"
              placeholder="请选择"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </p>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getOrderList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="createTime" label="订单日期" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="orderNum" label="订单编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="companyName" label="客户名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="amount" label="货物金额(元)" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="pay" label="已收金额(元)" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="订单状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.status === 0">待审核</span>
                    <span v-else-if="scope.row.status === 1">审核通过</span>
                    <span v-else-if="scope.row.status === 2">作废</span>
                    <span v-else-if="scope.row.status === 3">7天内未付保证金失效</span>
                    <span v-else>全部付款成功正常结束</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="付款状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.payStatus === 0">未付款</span>
                    <span v-else-if="scope.row.payStatus === 1">部分付款</span>
                    <span v-else>已付款</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="signTime" label="采购日期" width="148px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <el-date-picker v-if="chooseItem.id === scope.row.id"
                      v-model="chooseItem.buyTime"
                      type="date"
                      placeholder="请选择"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <span v-else>{{scope.row.buyTime}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="入库状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <div v-if="chooseItem.id === scope.row.id">
                      <el-select style="width: 160px;" v-model="scope.row.wareinStatus" placeholder="请选择" size="mini" clearable>
                        <el-option label="未入库" :value="0"></el-option>
                        <el-option label="已入库" :value="2"></el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <div v-if="scope.row.wareinStatus === 0">未入库</div>
                      <div v-else-if="scope.row.wareinStatus === 2">已入库</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="出库状态" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <div v-if="chooseItem.id === scope.row.id">
                      <el-select style="width: 160px;" v-model="scope.row.wareoutStatus" placeholder="请选择" size="mini" clearable>
                        <el-option label="未出库" :value="0"></el-option>
                        <el-option label="部分出库" :value="1"></el-option>
                        <el-option label="已出库" :value="2"></el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <div v-if="scope.row.wareoutStatus === 0">未出库</div>
                      <div v-else-if="scope.row.wareoutStatus === 1">部分出库</div>
                      <div v-else-if="scope.row.wareoutStatus === 2">已出库</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="使用第三方仓库" min-width="80px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <div v-if="chooseItem.id === scope.row.id">
                      <el-select style="width: 160px;" v-model="scope.row.isThird" placeholder="请选择" size="mini" clearable>
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <div v-if="scope.row.isThird === 0">否</div>
                      <div v-else>是</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-around">
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click.stop="routerJump(scope.row)">详情</span>
                    <span v-if="chooseItem.id !== scope.row.id" style="cursor: pointer; color: rgba(250, 173, 20, 1)" size="mini" @click.stop="editOrder(scope.row)">设置</span>
                    <span v-else style="cursor: pointer; color: rgba(250, 173, 20, 1)" size="mini" @click.stop="editOrder(0)">确认设置</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { getOrderListApi, editOrderApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderData: {
        status: '',
        payStatus: '',
        search: '',
        startTime: '',
        endTime: '',
        page: 1,
        size: 10
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      orderList: [],
      // 选中的项目
      chooseItem: ''
    }
  },
  created() {
    this.getOrderList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 340
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 340
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单列表
    async getOrderList() {
      if (this.orderData.startTime === null) this.orderData.startTime = ''
      if (this.orderData.endTime === null) this.orderData.endTime = ''
      const { data: res } = await getOrderListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.getOrderList()
    },
    // 重置搜索条件
    rejectSearch() {
      this.orderData = {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      }
      this.getOrderList()
    },
    // 确认修改订单详情
    async editOrder(result) {
      if (result === 0) {
        const { data: res } = await editOrderApi({ id: this.chooseItem.id, wareinStatus: this.chooseItem.wareinStatus, wareoutStatus: this.chooseItem.wareoutStatus, buyTime: this.chooseItem.buyTime, isThird: this.chooseItem.isThird, orderNum: this.chooseItem.orderNum })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('保存成功!')
        this.chooseItem = ''
      } else {
        this.chooseItem = result
      }
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'orderDetail', query: { id: result.id } })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  >p {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    span {
      margin-right: 5px;
      display: inline-block;
      line-height: 28px;
    }
    .el-input {
      margin-right: 10px;
      width: 160px;
    }
    .el-select {
      margin-right: 5px;
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
</style>
