// 财务管理 收款管理
<template>
    <div class="collectManage">
      <!-- 卡片视图区 -->
      <el-card :style="'height:' + tableHeight2 + 'px'">
        <el-row class="layout_row">
          <div class="searchBox">
            <p>
              <span>订单编号 : </span>
              <el-input v-model="orderData.orderNum" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <!-- <p>
              <span>合同编号 : </span>
              <el-input v-model="orderData.contractNum" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>合同名称 : </span>
              <el-input v-model="orderData.fileName" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p> -->
            <p>
              <span>客户名称 : </span>
              <el-input v-model="orderData.companyName" placeholder="请输入" type="text" size="small" maxlength="40"></el-input>
            </p>
            <p>
              <span>收款状态 : </span>
              <el-select style="width: 160px;" v-model="orderData.payStatus" placeholder="请选择" size="mini" clearable>
                <el-option label="未付款" value="0"></el-option>
                <el-option label="部分付款" value="1"></el-option>
                <el-option label="已付款" value="2"></el-option>
              </el-select>
            </p>
          </div>
          <div>
            <el-button class="lightDeepStyle query" size="mini" @click="getOrderList()">查询</el-button>
          </div>
        </el-row>
        <!-- 菜单管理表格区 -->
        <el-row>
            <el-table ref="tableHeight" :height="tableHeight" :data="orderList" border style="width: 100%;">
              <el-table-column prop="orderNum" label="订单编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <!-- <el-table-column prop="contractNum" label="合同编号" min-width="80px" show-overflow-tooltip align="center"></el-table-column> -->
              <el-table-column prop="auditCount" label="待审核数量" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <!-- <el-table-column prop="fileName" label="合同名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column> -->
              <el-table-column prop="companyName" label="客户名称" min-width="80px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="totalAmount" label="应收总金额(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="payAmount" label="已收金额(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="payLixi" label="已收利息(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="remainAmount" label="未收金额(元)" min-width="60px" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="payStatus" label="收款状态" min-width="60px" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.payStatus === 0">未付款</span>
                    <span v-else-if="scope.row.payStatus === 1">部分付款</span>
                    <span v-else-if="scope.row.payStatus === 2">已付款</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160px" align="center">
                <template slot-scope="scope">
                  <div>
                    <span style="cursor: pointer; color: rgba(51, 158, 191, 1)" size="mini" @click.stop="routerJump(scope.row)">详情</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="orderData.size"
            :total="total"
            >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>
<script>
import { payMentList } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableHeight2: '',
      orderData: {
        orderNum: '',
        contractNum: '',
        fileName: '',
        companyName: '',
        payStatus: '',
        page: 1,
        size: 10
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 所有菜单目录
      orderList: [],
      // 选中的项目
      chooseItem: ''
    }
  },
  created() {
    this.getOrderList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 350
    this.tableHeight2 = window.innerHeight - 220
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 350
        that.tableHeight2 = window.innerHeight - 220
      })()
    }
  },
  methods: {
    // 获取订单列表
    async getOrderList() {
      const { data: res } = await payMentList(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.orderData.page = val
      this.currentPage = val
      this.getOrderList()
    },
    // 重置搜索条件
    rejectSearch() {
      this.orderData = {
        fileName: '',
        ifSign: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      }
      this.getOrderList()
    },
    // 路由跳转
    routerJump(result) {
      this.$router.push({ path: 'collectDetail', query: { orderNum: result.orderNum } })
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  width: 100%;
  background:rgba(255,255,255,1);
  border-radius:3px;
  overflow: auto;
}
.el-card>.el-button {
  width:70px;
  height:40px;
  border-radius: 3px;
  color: #fff;
}
.el-card .examine_btn {
  width:80px;
  height:34px;
  border-radius:3px;
  line-height: 0;
}
.layout_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .searchBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    p {
      margin-bottom: 10px;
      span {
        margin-right: 5px;
        display: inline-block;
        line-height: 28px;
      }
      .el-input {
        margin-right: 10px;
        width: 160px;
      }
      .el-select {
        margin-right: 5px;
      }
    }
  }
}
.el-pagination {
  position: absolute;
  top: 10px;
  right: 0;
}
.el-table div  {
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  .el-button {
    display: inline-block;
  }
  .el-switch {
    margin-left: 50%;
    transform: translateX(-16%);
  }
  .el-input {
    width: 130px;
  }
  .el-select {
    width: 100px!important;
  }
  img {
    width: 60px;
    height: 42px;
  }
}
</style>
